import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    userList: []
  },
  actions: {
    /** This method returns a list of users in the database */
    getUserList ({ commit }) {
      Vue.prototype.$http.get('/admin/user').then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('USER_LIST', response.data.data)
        }
      })
    }
  },
  mutations: {
    USER_LIST (state, payload) {
      state.userList = payload
    }
  }
}
