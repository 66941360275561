import Vue from 'vue'
import Axios from 'axios'
import Snackbar from '@/components/Snackbar'

let naicsToken
let secondaryNaicsToken
let naceToken
let secondaryNaceToken
let sicToken
let germanNaceToken

function formattedResObj(list) {
  const formattedObjList = list.map((item) => {
    // Assign new key
    item.value = item.id
    item.text = `${item.id} - ${item.name.trim()}`

    // Delete old key
    delete item.id
    delete item.name

    return item
  })

  return formattedObjList
}

export default {
  namespaced: true,
  state: {
    industryDetail: {},
    industryDetailCopy: {},
    naicsList: [],
    secondaryNaicsList: [],
    naceList: [],
    secondaryNaceList: [],
    sicList: [],
    germanExtNaceList: [],
    dynamicNaicsList: [],
    dynamicSecondaryNaicsList: [],
    dynamicNaceList: [],
    dynamicSecondaryNaceList: [],
    dynamicSicList: [],
    dynamicGermanExtNaceList: [],
    bvdData: {},
    // variables for industry to load data properly
    naicsUpdateOnlyOnce: true,
    secondaryNaicsUpdateOnlyOnce: true,
    naceUpdateOnlyOnce: true,
    isNotHoldingCompanyDisabled: false,
    isBVDReady: false, // used for load bvd data,
    businessNature: null // used to populate manual update before update form from dynamic form
  }, // end state

  getters: {}, // end getters

  actions: {
    async getNaics({ commit }, { entitySubTypeId, isDynamic = false }) {
      // Check if there are any previous pending requests
      if (typeof naicsToken !== typeof undefined) {
        naicsToken.cancel('Request canceled due to new request.')
      }
      // Save the cancel token for the current request
      naicsToken = Axios.CancelToken.source()
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/industry-associations/naics?entitySubTypeId=${entitySubTypeId}`,
          { cancelToken: naicsToken.token } // Pass the cancel token to the current request
        )
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_NAICS_LIST', {
              naicsList: response.data.data,
              isDynamic: isDynamic
            })
          }
        }
        if (response.data.status === 204) {
          commit('SET_NAICS_LIST', { naicsList: [], isDynamic: isDynamic })
        }
      } catch (err) {
        if (err.message === 'Request canceled due to new request.') {
          commit('SET_NAICS_LIST', { naicsList: [], isDynamic: isDynamic })
        } else {
          throw new Error(err)
        }
      }
    },

    async getSecondaryNaics(
      { commit },
      { entityTypeId, entitySubTypeId, isAllNaics, isDynamic = false }
    ) {
      // Check if there are any previous pending requests
      if (typeof secondaryNaicsToken !== typeof undefined) {
        secondaryNaicsToken.cancel('Request canceled due to new request.')
      }
      // Save the cancel token for the current request
      secondaryNaicsToken = Axios.CancelToken.source()
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/industry-associations/naics?entityTypeId=${entityTypeId}&entitySubTypeId=${entitySubTypeId}&isAllNaics=${isAllNaics}`,
          { cancelToken: secondaryNaicsToken.token } // Pass the cancel token to the current request
        )
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_SECONDARY_NAICS_LIST', {
              secondaryNaicsList: response.data.data,
              isDynamic: isDynamic
            })
          }
        }
        if (response.data.status === 204) {
          commit('SET_SECONDARY_NAICS_LIST', {
            secondaryNaicsList: [],
            isDynamic: isDynamic
          })
        }
      } catch (err) {
        if (err.message === 'Request canceled due to new request.') {
          commit('SET_SECONDARY_NAICS_LIST', {
            secondaryNaicsList: [],
            isDynamic: isDynamic
          })
        } else {
          throw new Error(err)
        }
      }
    },

    async getNaceList({ commit }, { naicsId, isDynamic = false }) {
      // Check if there are any previous pending requests
      if (typeof naceToken !== typeof undefined) {
        naceToken.cancel('Request canceled due to new request.')
      }
      // Save the cancel token for the current request
      naceToken = Axios.CancelToken.source()
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/industry-associations/nace?naicsId=${naicsId}`,
          { cancelToken: naceToken.token } // Pass the cancel token to the current request
        )
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_NACE_LIST', {
              naceList: response.data.data,
              isDynamic: isDynamic
            })
          }
        }
        if (response.data.status === 204) {
          commit('SET_NACE_LIST', { naceList: [], isDynamic: isDynamic })
        }
      } catch (err) {
        if (err.message === 'Request canceled due to new request.') {
          commit('SET_NACE_LIST', { naceList: [], isDynamic: isDynamic })
        } else {
          throw new Error(err)
        }
      }
    },

    async getGermanNaceList({ commit }, { naceId, isDynamic = false }) {
      // Check if there are any previous pending requests
      if (typeof germanNaceToken !== typeof undefined) {
        germanNaceToken.cancel('Request canceled due to new request.')
      }
      // Save the cancel token for the current request
      germanNaceToken = Axios.CancelToken.source()
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/industry-associations/german-nace?naceId=${naceId}`,
          { cancelToken: germanNaceToken.token } // Pass the cancel token to the current request
        )
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_GERMAN_NACE_LIST', {
              germanNaceList: response.data.data,
              isDynamic: isDynamic
            })
          }
        }
        if (response.data.status === 204) {
          commit('SET_GERMAN_NACE_LIST', {
            germanNaceList: [],
            isDynamic: isDynamic
          })
        }
      } catch (err) {
        if (err.message === 'Request canceled due to new request.') {
          commit('SET_GERMAN_NACE_LIST', {
            germanNaceList: [],
            isDynamic: isDynamic
          })
        } else {
          throw new Error(err)
        }
      }
    },

    async getSecondaryNaceList({ commit }, { naicsId, isDynamic = false }) {
      try {
        // Check if there are any previous pending requests
        if (typeof secondaryNaceToken !== typeof undefined) {
          secondaryNaceToken.cancel('Request canceled due to new request.')
        }
        // Save the cancel token for the current request
        secondaryNaceToken = Axios.CancelToken.source()
        const response = await Vue.prototype.$http.get(
          `/dmp/industry-associations/nace?naicsId=${naicsId}`,
          { cancelToken: secondaryNaceToken.token } // Pass the cancel token to the current request
        )
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_SECONDARY_NACE_LIST', {
              secondaryNaceList: response.data.data,
              isDynamic: isDynamic
            })
          }
        }
        if (response.data.status === 204) {
          commit('SET_SECONDARY_NACE_LIST', {
            secondaryNaceList: [],
            isDynamic: isDynamic
          })
        }
      } catch (err) {
        if (err.message === 'Request canceled due to new request.') {
          commit('SET_SECONDARY_NACE_LIST', {
            secondaryNaceList: [],
            isDynamic: isDynamic
          })
        } else {
          throw new Error(err)
        }
      }
    },

    async getSicList({ commit }, { naicsId, isDynamic = false }) {
      try {
        // Check if there are any previous pending requests
        if (typeof sicToken !== typeof undefined) {
          sicToken.cancel('Request canceled due to new request.')
        }
        // Save the cancel token for the current request
        sicToken = Axios.CancelToken.source()
        const response = await Vue.prototype.$http.get(
          `/dmp/industry-associations/sic?naicsId=${naicsId}`,
          { cancelToken: sicToken.token } // Pass the cancel token to the current request
        )
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_SIC_LIST', {
              sicList: response.data.data,
              isDynamic: isDynamic
            })
          }
        }
      } catch (err) {
        if (err.message === 'Request canceled due to new request.') {
          commit('SET_SIC_LIST', { sicList: [], isDynamic: isDynamic })
        } else {
          throw new Error(err)
        }
      }
    },

    async getIndustryDetail({ commit }, sgaId) {
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/industry?sgaId=${sgaId}`
        )
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_INDUSTRY_DETAIL_AND_COPY', response.data.data)
          }
        } else {
          // Reset store
          commit('SET_INDUSTRY_DETAIL_AND_COPY', {})
        }
      } catch (err) {
        throw new Error(err)
      }
    },

    async updateIndustryDetail({ commit }, { sgaId, updatedIndustryDetail }) {
      try {
        const response = await Vue.prototype.$http.put(
          `/dmp/industry?sgaId=${sgaId}`,
          updatedIndustryDetail
        )
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_INDUSTRY_DETAIL_AND_COPY', response.data.data)
            Snackbar({
              message: 'Industry details updated successfully.',
              type: 'is-success',
              duration: 3000
            })
          }
        }
      } catch (err) {
        Snackbar({
          message: 'Industry details not updated.',
          type: 'is-success',
          duration: 3000
        })
        throw new Error(err)
      }
    },

    async deleteIndustryDetail({ commit }, sgaId) {
      try {
        const response = await Vue.prototype.$http.delete(
          `/dmp/industry?sgaId=${sgaId}`
        )
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            Snackbar({
              message: 'Industry details deleted successfully.',
              type: 'is-success',
              duration: 3000
            })
          }
        }
      } catch (err) {
        Snackbar({
          message: 'Industry details not deleted.',
          type: 'is-success',
          duration: 3000
        })
        throw new Error(err)
      }
    },

    updateNaceList({ commit }, { naceList, isDynamic = false }) {
      commit('SET_NACE_LIST', { naceList, isDynamic })
    },

    updateGermanNaceList({ commit }, { naceList, isDynamic = false }) {
      commit('SET_GERMAN_NACE_LIST', { germanNaceList: naceList, isDynamic })
    },

    updateSecondaryNaceList(
      { commit },
      { secondaryNaceList, isDynamic = false }
    ) {
      commit('SET_SECONDARY_NACE_LIST', { secondaryNaceList, isDynamic })
    },

    updateSicList({ commit }, { sicList, isDynamic = false }) {
      commit('SET_SIC_LIST', { sicList, isDynamic })
    },

    resetIndustryDetails({ commit }) {
      commit('SET_INDUSTRY_DETAIL_AND_COPY', {})
      commit('SET_NAICS_LIST', { naicsList: [], isDynamic: false })
      commit('SET_SECONDARY_NAICS_LIST', {
        secondaryNaicsList: [],
        isDynamic: false
      })
      commit('SET_NACE_LIST', { naceList: [], isDynamic: false })
      commit('SET_GERMAN_NACE_LIST', {
        germanNaceList: [],
        isDynamic: false
      })
      commit('SET_SECONDARY_NACE_LIST', {
        secondaryNaceList: [],
        isDynamic: false
      })
      commit('SET_SIC_LIST', { sicList: [], isDynamic: false })
    },

    updateBVDData({ commit }, bvdData) {
      commit('SET_BVD_DATA', bvdData)
    },

    updateIndustryDetailState({ commit }, industryDetail) {
      commit('SET_INDUSTRY_DETAIL', industryDetail)
    },

    resetIndustryVariables({ commit }) {
      commit('SET_INDUSTRY_VARIABLES')
    },

    updateNaicsUpdateOnlyOnce({ commit }, isFlag) {
      commit('SET_NAICS_UPDATE_ONLY_ONCE', isFlag)
    },

    updateSecondaryNaicsUpdateOnlyOnce({ commit }, isFlag) {
      commit('SET_SECONDARY_NAICS_UPDATE_ONLY_ONCE', isFlag)
    },

    updateNaceUpdateOnlyOnce({ commit }, isFlag) {
      commit('SET_NACE_UPDATE_ONLY_ONCE', isFlag)
    },

    updateNotHoldingCompanyFlag({ commit }, isFlag) {
      commit('SET_NOT_HOLDING_COMPANY_FLAG', isFlag)
    },

    updateIsBvdReady({ commit }, isBVDReady) {
      commit('SET_BVD_READY_FLAG', isBVDReady)
    },

    copyDropdownListToDynamicForm({ commit }) {
      commit('SET_DYNAMIC_DROPDOWN_LIST')
    },

    updateBusinessNatureState({ commit }, businessNature) {
      commit('SET_BUSINESS_NATURE', businessNature)
    }
  }, // end actions

  mutations: {
    SET_NAICS_LIST(state, { naicsList, isDynamic }) {
      if (naicsList.length > 0) {
        if (isDynamic) {
          state.dynamicNaicsList = formattedResObj(naicsList)
        } else {
          state.naicsList = formattedResObj(naicsList)
        }
      } else {
        if (!isDynamic) {
          state.naicsList = naicsList
        }
      }
    },

    SET_SECONDARY_NAICS_LIST(state, { secondaryNaicsList, isDynamic }) {
      if (secondaryNaicsList.length > 0) {
        const formattedList = formattedResObj(secondaryNaicsList)
        if (isDynamic) {
          state.dynamicSecondaryNaicsList = formattedList
        } else {
          state.secondaryNaicsList = formattedList
        }
      } else {
        if (!isDynamic) {
          state.secondaryNaicsList = secondaryNaicsList
        }
      }
    },

    SET_NACE_LIST(state, { naceList, isDynamic }) {
      if (naceList.length > 0) {
        const formattedList = formattedResObj(naceList)
        if (isDynamic) {
          state.dynamicNaceList = formattedList
        } else {
          state.naceList = formattedList
        }
      } else {
        if (!isDynamic) {
          state.naceList = naceList
        }
      }
    },

    SET_GERMAN_NACE_LIST(state, { germanNaceList, isDynamic }) {
      if (germanNaceList.length > 0) {
        const formattedList = formattedResObj(germanNaceList)
        if (isDynamic) {
          state.dynamicGermanExtNaceList = formattedList
        } else {
          state.germanExtNaceList = formattedList
        }
      } else {
        if (!isDynamic) {
          state.germanExtNaceList = germanNaceList
        }
      }
    },

    SET_SECONDARY_NACE_LIST(state, { secondaryNaceList, isDynamic }) {
      if (secondaryNaceList.length > 0) {
        const formattedList = formattedResObj(secondaryNaceList)
        if (isDynamic) {
          state.dynamicSecondaryNaceList = formattedList
        } else {
          state.secondaryNaceList = formattedList
        }
      } else {
        if (!isDynamic) {
          state.secondaryNaceList = secondaryNaceList
        }
      }
    },

    SET_SIC_LIST(state, { sicList, isDynamic }) {
      if (sicList.length > 0) {
        const formattedList = formattedResObj(sicList)
        if (isDynamic) {
          state.dynamicSicList = formattedList
        } else {
          state.sicList = formattedList
        }
      } else {
        if (!isDynamic) {
          state.sicList = sicList
        }
      }
    },

    SET_INDUSTRY_DETAIL_AND_COPY(state, industryDetail) {
      const isEmpty =
        industryDetail &&
        Object.keys(industryDetail).length === 0 &&
        industryDetail.constructor === Object

      if (!isEmpty) {
        const PROPERTY_KEY = Object.freeze([
          'sic',
          'naics',
          'secondaryNaics',
          'nace',
          'secondaryNace',
          'germanExtNace'
        ])

        for (const key of PROPERTY_KEY) {
          // for null case
          if (!industryDetail[`${key}`]) {
            continue
          }

          const value = industryDetail[`${key}`].code
          const text = `${industryDetail[`${key}`].name}`

          // Assign new key
          industryDetail[`${key}`].value = value
          industryDetail[`${key}`].text = `${value} - ${text}`

          // Delete old key
          delete industryDetail[`${key}`].code
          delete industryDetail[`${key}`].name
        }

        state.industryDetail = industryDetail
        state.industryDetailCopy = industryDetail
      } else {
        state.industryDetail = industryDetail
        state.industryDetailCopy = industryDetail
      }
    },

    SET_INDUSTRY_DETAIL(state, industryDetail) {
      state.industryDetail = industryDetail
    },

    SET_BVD_DATA(state, bvdData) {
      state.bvdData = bvdData
    },

    SET_INDUSTRY_VARIABLES(state) {
      state.naicsUpdateOnlyOnce = true
      state.secondaryNaicsUpdateOnlyOnce = true
      state.naceUpdateOnlyOnce = true
    },

    SET_NAICS_UPDATE_ONLY_ONCE(state, isFlag) {
      state.naicsUpdateOnlyOnce = isFlag
    },

    SET_SECONDARY_NAICS_UPDATE_ONLY_ONCE(state, isFlag) {
      state.secondaryNaicsUpdateOnlyOnce = isFlag
    },

    SET_NACE_UPDATE_ONLY_ONCE(state, isFlag) {
      state.naceUpdateOnlyOnce = isFlag
    },

    SET_NOT_HOLDING_COMPANY_FLAG(state, isFlag) {
      state.isNotHoldingCompanyDisabled = isFlag
    },

    SET_BVD_READY_FLAG(state, isFlag) {
      state.isBVDReady = isFlag
    },

    SET_DYNAMIC_DROPDOWN_LIST(state) {
      state.dynamicNaicsList = state.naicsList
      state.dynamicSecondaryNaicsList = state.secondaryNaicsList
      state.dynamicNaceList = state.naceList
      state.dynamicSecondaryNaceList = state.secondaryNaceList
      state.dynamicSicList = state.sicList
      state.dynamicGermanExtNaceList = state.germanExtNaceList
    },

    SET_BUSINESS_NATURE(state, businessNature) {
      state.businessNature = businessNature
    }
  } // end mutations
}
