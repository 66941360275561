import Vue from 'vue'
import store from '..'
import Snackbar from '../../components/Snackbar'
import { vm } from '../../main'

export default {
  namespaced: true,
  state: {
    moduleList: []
  },
  actions: {
    getModuleList ({ commit }) {
      Vue.prototype.$http.get('/admin/module').then(response => {
        if (response.data.status === 200 && response.data.data) {
          commit('MODULE_LIST', response.data.data)
        }
      })
    },
  },
  mutations: {
    MODULE_LIST (state, payload) {
      state.moduleList = payload
    }
  }
}
