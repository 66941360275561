import Vue from 'vue'
import Snackbar from '../../components/Snackbar'

export default {
  namespaced: true,
  state: {
    categories: [],
    newsCategoryDetails: [],
    parentNewsCategories: [],
    totalCount: '',
    totalLength: '',
    checkIsInUse: {}
  },
  actions: {
    /** This method returns a list of all news categories once */
    getNewsCategoryList ({ commit }, value) {
      Vue.prototype.$http.get('/admin/news-categories', { params: value })
        .then((response) => {
          if (response.data.status === 200 && response.data.data) {
            return response.data
          }
        }).then(async (data) => {
          let allData = data.data
          const length = data.data.length
          const totalRowsCount = data.totalRows / value.limit
          if (length < data.totalRows) {
            for (let i = 1; i < totalRowsCount; i++) {
              const payload = {
                moduleId: value.moduleId,
                limit: value.limit,
                offset: value.limit * (i)
              }
              const res = await Vue.prototype.$http.get('/admin/news-categories', { params: payload })
              allData = allData.concat(res.data.data)
            }
          }
          return allData
        }).then((data) => {
          commit('PARENT_NEWS_CATEGORIES', data)
        }
        )
    },

    getNewsCategoryListPagination ({ commit }, value) {
      Vue.prototype.$http.get('/admin/news-categories', { params: value })
        .then((response) => {
          if (response.data.status === 200 && response.data.data) {
            commit('COUNT', response.data.totalRows)
            commit('CATEGORIES', response.data.data)
          } else {
            commit('COUNT', 0)
            commit('CATEGORIES', [])
          }
        })
    },

    checkNewsCategoryInUse ({ commit }, categoryId) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.get(`/admin/news-categories/checkIsInUse/${categoryId}`)
          .then((response) => {
            if (response.data.status === 200 && response.data.data) {
              commit('CHECK_IS_IN_USE', response.data.data)
              resolve(response.data.status)
            }
          })
      })
    },

    getNewsCategoryById ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.get(`/admin/news-categories/${payload.categoryId}`)
          .then((response) => {
            if (response.data.status === 200 && response.data.data) {
              commit('NEWS_CATEGORY_DETAILS', response.data.data)
              resolve(response.data.status)
            }
          })
      })
    },
    addNewsCategory ({ commit }, payload) {
      Vue.prototype.$http.post('/admin/news-categories', payload).then((response) => {
        if (response.data.status === 201) {
          Snackbar({ message: 'Successfully created', type: 'is-success' })
        } else {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        }
      })
    },

    updateNewsCategory ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.put(`/admin/news-categories/${payload.categoryId}`, payload.updateNewsCategory).then((response) => {
          if (response.data.status === 200) {
            Snackbar({ message: 'Successfully updated', type: 'is-success' })
            resolve(response.data.status)
          } else {
            Snackbar({ message: response.data.info, type: 'is-danger' })
          }
        })
      })
    },
    deleteNewsCategory ({ commit }, categoryId) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.delete(`/admin/news-categories/${categoryId}`).then((response) => {
          if (response.data.status === 200) {
            Snackbar({ message: 'New category deleted successfull', type: 'is-success' })
            resolve(response.data.status)
          } else {
            Snackbar({ message: 'Could not delete the News category', type: 'is-danger' })
          }
        })
      })
    }
  },

  mutations: {
    CATEGORIES (state, payload) {
      state.categories = payload
    },

    PARENT_NEWS_CATEGORIES (state, payload) {
      let totalCategoryList = payload.map(i => {
        return {
          id: i.id,
          label: i.label,
          children: i.children,
          parent: i.parent,
          status: i.status
        }
      })
      // Create hierarchy using all (active and inactive) categories
      for (const category of totalCategoryList) {
        // Iterate through each news category
        if (category.parent) {
          // If the news category has a parent, set the currentNewsCategory as this one
          const currentNewsCategory = totalCategoryList.find(i => i.id === category.id)
          // Set parentId as the parent id for the current iteration
          const parentId = category.parent.id
          // Search totalCategoryList for the category that has an id equal to parentId
          const parentNewsCategory = totalCategoryList.find(i => i.id === parentId)
          // If this parentNewsCategory has a children array, push the currentNewsCategory to that array
          if (parentNewsCategory && parentNewsCategory.children) {
            parentNewsCategory.children.push(currentNewsCategory)
            // If this parentNewsCategory does not have a children array, create the array and push the currentNewsCategory to that array
          } else if (parentNewsCategory && !parentNewsCategory.children) {
            parentNewsCategory.children = [currentNewsCategory]
          }
        }
      }
      totalCategoryList = totalCategoryList.filter(i => !i.parent)
      totalCategoryList.sort(function (a, b) {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      state.parentNewsCategories = totalCategoryList
    },
    NEWS_CATEGORY_DETAILS (state, payload) {
      state.newsCategoryDetails = payload
    },
    CHECK_IS_IN_USE (state, payload) {
      state.checkIsInUse = payload
    },
    COUNT (state, payload) {
      state.totalCount = payload
    },
    LENGTH (state, payload) {
      state.totalLength = payload
    }

  }
}
