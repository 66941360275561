// import groupBy from 'lodash/groupBy'
import Vue from 'vue'
import store from '../store/index'

const getDefaultState = () => {
  return {
    vocStatictics: {},
    reviewVolume: 0,
    maxReviewVolume: 0,
    sortedProducts: [],
    sort: {
      type: 'Default',
      orderBy: null
    },
    sortOptions: [{ name: 'Default', alias: 'Default', value: null },
      { name: 'Review volume', alias: 'review_id', value: 'desc' },
      { name: 'Rating', alias: 'c_sat_rating', value: 'desc' }],
    activeVoCView: 'grid',
    showText: true
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    /** This method fetches the data required for the Voc summary pages from the database */
    getVocStatistics ({ commit, state }, value) {
      commit('LOADING', true)
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post(`/product/${value.product.id}/vocStatistic`, value).then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              const payload = response.data.data
              payload.product = value.product
              // commit('VOC_STATISTICS', payload)
              resolve(payload)
            }
          }
          commit('LOADING', false)
        }).catch(e => {
          commit('LOADING', false)
        })
      })
    },
    /** This method fetches the NPS (Net Promoter Score) information from the database */
    getNPSData ({ commit }, value) {
      commit('LOADING', true)
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post(`/product/${value.product.id}/npsData`, value).then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              const payload = response.data.data
              payload.product = value.product
              resolve(payload)
            }
          }
          commit('LOADING', false)
        }).catch(e => {
          commit('LOADING', false)
        })
      })
    },

    /** This method returns the review volume information as calculated from the database. */
    getVocVolume ({ commit, state }, value) {
      commit('LOADING', true)
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post('/review/getReviewVolume', value).then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              const payload1 = response.data.data.totalReviewVolume
              const payload2 = response.data.data.maxReviewVolume
              commit('REVIEW_VOLUME', payload1)
              commit('MAX_REVIEW_VOLUME', payload2)
              resolve(payload1)
            }
          }
        }).catch(e => {
          commit('LOADING', false)
        })
      })
    },

    // getMaxVocVolume ({ commit, state }, value) {
    //   commit('LOADING', true)
    //   return new Promise((resolve, reject) => {
    //     Vue.prototype.$http.post('/review/getMaxReviewVolume', value).then((response) => {
    //       if (response.data.status === 200) {
    //         if (response.data && response.data.data) {
    //           const payload = response.data.data.reviewVolume
    //           commit('MAX_REVIEW_VOLUME', payload)
    //           resolve(payload)
    //         }
    //       }
    //     }).catch(e => {
    //       commit('LOADING', false)
    //     })
    //   })
    // },

    getSortedProducts ({ commit }, value) {
      commit('LOADING', true)
      Vue.prototype.$http.post('/product/sorted', value).then((response) => {
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SORTED_PRODUCTS', response.data.data)
          }
        }
        commit('LOADING', false)
      })
    },

    updateSortedProduct ({ commit }, value) {
      commit('SORTED_PRODUCTS', value)
    },
    /** This method updates the criteria required for sorting on the VoC page */
    updateSort ({ commit }, value) {
      commit('UPDATE_SORT', value)
    },
    /** This method changes the voc view and saves the new value in the vuex store. */
    updateActiveVoCView ({ commit }, value) {
      commit('ACTIVE_VOC_VIEW', value)
    },
    /** This method controls whether or not values should be rendered over the voc summary barchart. */
    updateShowText ({ commit }, value) {
      commit('SHOW_BARCHART_TEXT', value)
    }
  },
  mutations: {
    ACTIVE_VOC_VIEW (state, payload) {
      state.activeVoCView = payload
      localStorage.setItem('VOC_VIEW_TYPE', payload)
    },
    SHOW_BARCHART_TEXT (state, payload) {
      state.showText = payload
    },
    LOADING (state, payload) {
      store.state.filters.loading = payload
    },
    REVIEW_VOLUME (state, payload) {
      state.reviewVolume = payload
    },
    MAX_REVIEW_VOLUME (state, payload) {
      state.maxReviewVolume = payload
    },
    VOC_STATISTICS (state, payload) {
      state.vocStatictics[payload.product.id] = payload
      state.loading = false
    },
    SORTED_PRODUCTS (state, payload) {
      state.sortedProducts = payload
    },
    UPDATE_SORT (state, payload) {
      state.sort = payload
      const options = state.sortOptions
      for (const option of options) {
        if (option.alias === payload.type) {
          option.value = payload.orderBy
        }
      }
      state.sortOptions = options
    }
  }
}
