import Vue from 'vue'
import store from '../store/index'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    csatDriverSummary: [],
    comprehensiveCsatDriverSummary: {},
    compCsatDriverControlSignal: null,
    vendorCsatLoader: false,
    csatLoader: false,
    kanoLoader: false,
    csatData: [],
    sort: {
      type: 'total_count',
      orderBy: 'desc'
    },
    sortOptions: [
      { name: 'Review volume', alias: 'total_count', value: 'desc' },
      { name: 'Features', alias: 'topic_name', value: 'desc' },
      { name: 'Rating', alias: 'c_sat_avg', value: 'desc' }
    ],
    driverView: 0,
    csatVendorDriverView: 0,
    showTotal: false,
    showVendorCsatTotal: false,
    localSelectedProduct: {},
    localSelectedCompany: {},
    sentimentScoreDataTotal: [],
    sentimentScoreDataNMinusOne: [],
    sentimentScoreDataSingle: [],
    expandedVendors: {}
  },
  actions: {
    /** This method gets the data for the CSAT drivers page. It also extracts (from session storage) the value of the last selected view and saves that to the store. This helps in persisting that view througout page changes. */
    getCsatDriverSummary ({ commit, state }, value) {
      commit('CSAT_LOADER', true)
      commit('LOADING', true)
      const view = sessionStorage.getItem('driverView')
      if (view) {
        state.driverView = JSON.parse(view)
      }
      Vue.prototype.$http.post('/report/getCsatByTopic', value).then((response) => {
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('CSAT_DRIVER_SUMMARY', response.data.data)
          }
        }
        commit('LOADING', false)
      }).catch(e => {
        commit('CSAT_LOADER', false)
        commit('LOADING', false)
      })
    },
    /** This method gets the data for the CSAT drivers page. It also extracts (from session storage) the value of the last selected view and saves that to the store. This helps in persisting that view througout page changes. */
    getCsatDriverSummaryByVendor ({ commit, state }, value) {
      commit('VENDOR_CSAT_LOADER', true)
      commit('LOADING', true)
      const view = sessionStorage.getItem('csatVendorDriverView')
      if (view) {
        state.csatVendorDriverView = JSON.parse(view)
      }
      Vue.prototype.$http.post('/report/getCsatByVendor', value).then((response) => {
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('COMP_CSAT_DRIVER_SUMMARY', response.data.data)
          }
        }
        commit('LOADING', false)
        commit('VENDOR_CSAT_LOADER', false)
      }).catch(e => {
        commit('LOADING', false)
        commit('VENDOR_CSAT_LOADER', false)
      })
    },
    /** This method gets the correlation data for all products for the Kano Chart. The y-axis value for all bubbles are set using the correlationCoeff returned in this method. If there is no selected product (no comparison is being made) the x-axis values for the bubbles are also obtained from the values returned in this method. (avg Satisfaction) */
    getSentimentRatingCorrelationTotal ({ commit }, value) {
      commit('KANO_LOADER', true)
      Vue.prototype.$http.post('/topic/sentimentRatingCorrelation', value).then((response) => {
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('UPDATE_SENTIMENT_SCORE_TOTAL', response.data.data)
          }
        }
        commit('LOADING', false)
      }).catch(e => {
        commit('LOADING', false)
      })
    },
    /** This method returns the correlation data for only the selected product on the Kano Chart view. The x-axis bubbles for the solid bubbles (when a comparison is being made) are set using the avgSatisfaction values returned through this method. */
    getSentimentRatingCorrelationSingle ({ commit }, value) {
      commit('KANO_LOADER', true)
      if (value === null) {
        commit('UPDATE_SENTIMENT_SCORE_SINGLE', [])
      } else {
        Vue.prototype.$http.post('/topic/sentimentRatingCorrelation', value).then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('UPDATE_SENTIMENT_SCORE_SINGLE', response.data.data)
            }
          }
        }).catch(e => {
          commit('LOADING', false)
        })
      }
    },
    /** This method returns the correlation data for all products excluding the selected product, on the Kano Chart view. The x-axis bubbles for the hollow bubbles (when a comparison is being made) are set using the avgSatisfaction values returned through this method. */
    getSentimentRatingCorrelationNMinusOne ({ commit }, value) {
      commit('KANO_LOADER', true)
      Vue.prototype.$http.post('/topic/sentimentRatingCorrelation', value).then((response) => {
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('UPDATE_SENTIMENT_SCORE_N_MINUS_ONE', response.data.data)
          }
        }
      }).catch(e => {
        commit('LOADING', false)
      })
    },
    updateCsatDriverData ({ commit }, payload) {
      commit('CSAT_DATA', payload)
    },
    updateCsatLoader ({ commit }, value) {
      commit('CSAT_LOADER', value)
    },
    updateKanoLoader ({ commit }, value) {
      commit('KANO_LOADER', value)
    },
    updateVendorCsatLoader ({ commit }, value) {
      commit('VENDOR_CSAT_LOADER', value)
    },
    /** This method sets the criteria by which drivers must be sorted */
    updateSort ({ commit }, value) {
      commit('UPDATE_SORT', value)
    },
    /** This method updates the view to be displayed on the CSAT Drivers page, to whatever was selected in the dropdown menu on the page. */
    updateDriverDisplayMode ({ commit, state }, { page, value }) {
      if (page !== 'vendor-csat') {
        commit('UPDATE_DRIVER_DISPLAY_MODE', value)
      } else {
        commit('UPDATE_CSAT_VENDOR_DRIVER_DISPLAY_MODE', value)
      }
      for (const option of state.sortOptions) {
        if (option.name === 'Rating' && value === 1) {
          option.name = '% of Likes'
          option.alias = 'like'
          option.value = 'asc'
        } else if (option.name === '% of Likes' && value === 0) {
          option.name = 'Rating'
          option.alias = 'c_sat_avg'
          option.value = 'asc'
        }
      }
      commit('UPDATE_SORT', {
        type: 'total_count',
        orderBy: 'desc'
      })
      commit('UPDATE_SORT_OPTIONS', state.sortOptions)
    },
    /** This method selects a product whose data needs to be compared against the avg of the rest of the products on the Kano chart */
    updateSelectedProduct ({ commit }, value) {
      commit('UPDATE_SELECTED_PRODUCT', value)
    },
    /** This method selects a company whose data needs to be compared against the avg of the rest of the companies on the Kano chart */
    updateSelectedCompany ({ commit }, value) {
      commit('UPDATE_SELECTED_COMPANY', value)
    },
    /**
     * This method hides/displays the *All Products/Company Total* column in the CSAT/ESAT Drivers table
     */
    toggleShowTotal ({ commit }) {
      commit('TOGGLE_SHOW_TOTAL')
    },
    /**
     * This method hides/displays the *All Products/Company Total* column in the CSAT/ESAT Drivers table on the Vendor Csat page
     */
    toggleVendorCsatShowTotal ({ commit }) {
      commit('TOGGLE_SHOW_VENDOR_CSAT_TOTAL')
    },
    /**
     * This method controls which vendor columns are expanded/hidden on the vendor csat page
     */
    updateExpandedVendors ({ commit }, value) {
      commit('UPDATE_EXPANDED_VENDORS', value)
    },
    resetExpandedVendors ({ commit }) {
      commit('RESET_EXPANDED_VENDORS')
    }
  },
  mutations: {
    CSAT_DRIVER_SUMMARY (state, payload) {
      state.csatDriverSummary = payload
    },
    COMP_CSAT_DRIVER_SUMMARY (state, payload) {
      state.comprehensiveCsatDriverSummary = payload
    },
    LOADING (state, payload) {
      store.state.filters.loading = payload
    },
    CSAT_DATA (state, payload) {
      state.csatData = payload
    },
    UPDATE_SORT (state, payload) {
      state.sort = payload
      const options = state.sortOptions
      for (const option of options) {
        if (option.alias === payload.type) {
          option.value = payload.orderBy
        }
      }
      state.sortOptions = options
    },
    UPDATE_SORT_OPTIONS (state, payload) {
      state.sortOptions = payload
    },
    UPDATE_DRIVER_DISPLAY_MODE (state, payload) {
      sessionStorage.setItem('driverView', payload)
      state.driverView = payload
    },
    UPDATE_CSAT_VENDOR_DRIVER_DISPLAY_MODE (state, payload) {
      sessionStorage.setItem('csatVendorDriverView', payload)
      state.csatVendorDriverView = payload
    },
    UPDATE_SELECTED_PRODUCT (state, payload) {
      state.localSelectedProduct = payload
    },
    UPDATE_SELECTED_COMPANY (state, payload) {
      state.localSelectedCompany = payload
    },
    UPDATE_SENTIMENT_SCORE_TOTAL (state, payload) {
      state.sentimentScoreDataTotal = payload
    },
    UPDATE_SENTIMENT_SCORE_SINGLE (state, payload) {
      state.sentimentScoreDataSingle = payload
    },
    UPDATE_SENTIMENT_SCORE_N_MINUS_ONE (state, payload) {
      state.sentimentScoreDataNMinusOne = payload
    },
    TOGGLE_SHOW_TOTAL (state) {
      state.showTotal = !state.showTotal
    },
    TOGGLE_SHOW_VENDOR_CSAT_TOTAL (state) {
      state.showVendorCsatTotal = !state.showVendorCsatTotal
    },
    UPDATE_EXPANDED_VENDORS (state, { name, count, type }) {
      if (type === 'hide') {
        delete state.expandedVendors[name]
      } else {
        state.expandedVendors[name] = count
      }
    },
    RESET_EXPANDED_VENDORS (state) {
      state.expandedVendors = {}
    },
    CSAT_LOADER (state, payload) {
      state.csatLoader = payload
    },
    KANO_LOADER (state, payload) {
      state.kanoLoader = payload
    },
    VENDOR_CSAT_LOADER (state, payload) {
      state.vendorCsatLoader = payload
    }
  }
}
