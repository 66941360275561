import Vue from 'vue'
import Snackbar from '../../components/Snackbar'

export default {
  namespaced: true,
  state: {
    featureList: [],
    features: [],
    featureDetails: null,
    totalCount: null
  },
  actions: {
    /** This method returns a list of features from the database. It is being used on the Features page in the admin panel */
    getFeatureList ({ commit }, value) {
      Vue.prototype.$http.post('/admin/feature', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('FEATURE_LIST', response.data.data.data)
          commit('COUNT', response.data.data.count)
        }
      })
    },
    /** This method returns a list of features available in the database. It is used to populate the features list in the 'Edit/Update Roles and permissions' page for a user role */
    getFeatures ({ commit }, value) {
      Vue.prototype.$http.get('/admin/feature').then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('FEATURES', response.data.data)
        }
      })
    },
    /** This method adds a new feature to the database */
    addFeature ({ commit }, value) {
      Vue.prototype.$http.post('/admin/feature/add', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully created', type: 'is-success' })
        } else if (response.data.status === 201) {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        }
      })
    },
    /** This method updates the value/data of an existing feature in the database */
    updateFeature ({ commit }, payload) {
      Vue.prototype.$http.put(`/admin/feature/${payload.featureId}`, payload).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully updated', type: 'is-success' })
          commit('SINGLE_FEATURE', response.data.data)
        } else if (response.data.status === 201) {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        }
      })
    },
    /** This method fetches the data of a single feature from the database */
    getSingleFeature ({ commit }, featureId) {
      Vue.prototype.$http.get(`/admin/feature/${featureId}`).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('SINGLE_FEATURE', response.data.data)
        }
      })
    }
  },
  mutations: {
    FEATURE_LIST (state, payload) {
      state.featureList = payload
    },
    FEATURES (state, payload) {
      state.features = payload
    },
    SINGLE_FEATURE (state, payload) {
      state.featureDetails = payload.length ? payload[0] : null
    },
    COUNT (state, payload) {
      state.totalCount = payload
    }
  }
}
