import Vue from 'vue'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    lastUpdatedDateVoc: '',
    lastUpdatedDateVoe: ''
  },
  actions: {
    /** This method returns the date of the last time the database was refreshed */
    getLastUpdatedDateVoc ({ commit }, value) {
      let date = ''
      Vue.prototype.$http.get('/review/getLatestReviewDate').then((response) => {
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            date = response.data.data.latestDate
            commit('LAST_UPDATED_DATE_VOC', moment(date).format('MM-DD-YYYY'))
          }
        }
      }).catch(e => {
      })
    },
    /** This method returns the date of the last time the database was refreshed for the Voe module */
    getLastUpdatedDateVoe ({ commit }, value) {
      let date = ''
      Vue.prototype.$http.get('/review/getLatestReviewDateVoe').then((response) => {
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            date = response.data.data.latestDate
            commit('LAST_UPDATED_DATE_VOE', moment(date).format('MM-DD-YYYY'))
          }
        }
      }).catch(e => {
      })
    }
  },
  mutations: {
    LAST_UPDATED_DATE_VOC (state, payload) {
      state.lastUpdatedDateVoc = payload
    },
    LAST_UPDATED_DATE_VOE (state, payload) {
      state.lastUpdatedDateVoe = payload
    }
  }
}
