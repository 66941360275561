import Vue from 'vue'
import Snackbar from '../../components/Snackbar'

export default {
  namespaced: true,
  state: {
    companyList: [],
    companySizeDetails: null,
    totalCount: null
  },
  actions: {
    /** This method returns a list of all company sizes available in the database */
    getCompaniesList ({ commit }, value) {
      Vue.prototype.$http.post('/admin/company_size', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('COMPANY_LIST', response.data.data.data)
          commit('COUNT', response.data.data.count)
        }
      })
    },
    /** This method adds a new company size to the database */
    addCompany ({ commit }, value) {
      Vue.prototype.$http.post('/admin/company_size/add', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully created', type: 'is-success' })
        } else if (response.data.status === 201) {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        }
      })
    },
    /** This method updates the details of a particular company size */
    updateCompany ({ commit }, payload) {
      Vue.prototype.$http.put(`/admin/company_size/${payload.companySizeId}`, payload).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully updated', type: 'is-success' })
          commit('SINGLE_COMPANY_SIZE', response.data.data)
        } else if (response.data.status === 201) {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        }
      })
    },
    /** Gets the data (company size name and id) of a single company size. */
    getSingleCompanySize ({ commit }, companySizeId) {
      Vue.prototype.$http.get(`/admin/company_size/${companySizeId}`).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('SINGLE_COMPANY_SIZE', response.data.data)
        }
      })
    }
  },
  mutations: {
    COMPANY_LIST (state, payload) {
      state.companyList = payload
    },
    SINGLE_COMPANY_SIZE (state, payload) {
      state.companySizeDetails = payload.length ? payload[0] : null
    },
    COUNT (state, payload) {
      state.totalCount = payload
    }
  }
}
