import Vue from 'vue'
import store from '../store/index'

const getDefaultReportState = () => {
  return {
    overall: [],
    stack: [],
    groupBy: { name: 'Product', id: 'product' },
    overallAvgCsat: 0,
    overallRecommendationScore: 0,
    compareRatingOverRecommendation: [],
    sortingArray: [],
    timePeriodGroupBy: 'quarter',
    lastViewedChart: {
      type: 'line',
      active: 'selectLine'
    }
  }
}

export default {
  namespaced: true,
  state: getDefaultReportState(),
  actions: {
    /** This method gets the data required to render the rating and recommendation % over time reports as well as the reviews over time reports */
    getReport ({ commit, state }, value) {
      Vue.prototype.$http.post('/report/getReviewsOverTime', value).then((response) => {
        if (response.data.status === 200 && response.data.data && value.module === 'voc') {
          commit('REPORT_STACK', response.data.data.stack)
          commit('OVERALL_AVG_CSAT', response.data.data.overallAverageCsat)
          commit('OVERALL_AVG_RECOMMENDATION_SCORE', response.data.data.overallRecommendationScore)
          commit('COMPARE_RATING_OVER_RECOMMENDATION', response.data.data.compareRatingOverRecommendation
            .map(d => {
              d.x = d.csat_rating
              d.y = d.recommendation_score
              d.volume = d.review_volume
              return d
            }))
        } else if (response.data.status === 200 && response.data.data && value.module === 'voe') {
          commit('REPORT_STACK', response.data.data.stack)
          commit('OVERALL_AVG_CSAT', response.data.data.overallAverageCsat)
        }
      }).catch(e => {
        commit('LOADING', false)
      })
    },
    /** This method gets the data required to render the horizontal bar chart */
    getGroupedReport ({ commit }, value) {
      Vue.prototype.$http.post('/report/getReviewSplit', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('REPORT_OVERALL', response.data.data)
          if (value.groupBy === 'product') {
            commit('REPORT_SORTING_ARRAY', response.data.data)
          }
        }
        commit('LOADING', false)
      }).catch(e => {
        commit('LOADING', false)
      })
    },
    /** This method updates the grouping criteria for the Horizontal bar chart on the Reports page */
    updateGroupBy ({ commit }, value) {
      commit('GROUP_BY', value)
    },
    resetReportState ({ commit }, value) {
      commit('RESET_REPORT_STATE')
    },
    /** This method saves the last viewed Reviews over time chart */
    updateLastViewedChart ({ commit }, value) {
      commit('UPDATE_LAST_VIEWED_CHART', value)
    }
  },
  mutations: {
    REPORT_OVERALL (state, payload) {
      state.overall = payload.sort((a, b) => {
        if ((a.product_name === 'Others') !== (b.product_name === 'Others')) {
          return a.product_name === 'Others' ? 1 : -1
        }
        if ((a.company_name === 'Others') !== (b.company_name === 'Others')) {
          return a.company_name === 'Others' ? 1 : -1
        }
        return Number(b.review_volume) - Number(a.review_volume)
      })
    },
    LOADING (state, payload) {
      store.state.filters.loading = payload
    },
    REPORT_STACK (state, payload) {
      state.stack = payload
    },
    COMPARE_RATING_OVER_RECOMMENDATION (state, payload) {
      state.compareRatingOverRecommendation = payload
    },
    GROUP_BY (state, payload) {
      state.groupBy = payload
    },
    OVERALL_AVG_CSAT (state, payload) {
      state.overallAvgCsat = payload
    },
    OVERALL_AVG_RECOMMENDATION_SCORE (state, payload) {
      state.overallRecommendationScore = payload
    },
    REPORT_SORTING_ARRAY (state, payload) {
      state.sortingArray = payload.map(e => e.company_name)
    },
    RESET_REPORT_STATE (state, payload) {
      state = {} // getDefaultReportState()
    },
    UPDATE_LAST_VIEWED_CHART (state, payload) {
      state.lastViewedChart.type = payload
      if (payload === 'line') { state.lastViewedChart.active = 'selectLine' } else { state.lastViewedChart.active = 'selectStack' }
    }
  }
}
