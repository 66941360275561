import Vue from 'vue'
import Snackbar from '../../components/Snackbar'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    companyList: [],
    companyFilteredList: [],
    companyDetails: null,
    totalCount: null,
    countriesList: null,
    citiesList: null,
    stateList: null,
    websitesLink: [],
    exchangeList: null,
    loading: false
  },
  actions: {
    /** This method returns a list of companies present in the database by applying the filter options */
    getCompanyFilteredList ({ commit }, value) {
      commit('LOADING', true)
      Vue.prototype.$http.post('/admin/company', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('LOADING', false)
          commit('COMPANY_FILTERED_LIST', response.data.data.data)
          commit('COUNT', response.data.data.count)
        } else {
          commit('LOADING', false)
        }
      })
    },
    /** This method returns a list of companies present in the database */
    getCompanyList ({ commit }, value) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/admin/company?searchText=${value}`)
          .then((response) => {
            if (response.data.status === 200 && response.data.data) {
              commit('COMPANY_LIST', response.data.data.data)
              resolve(response.data.data.data)
            }
          })
      })
    },
    /** This method adds a new company to the database */
    addCompany ({ commit }, value) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/admin/company/add', value)
          .then((response) => {
            if (response.data.status === 200 && response.data.data) {
              Snackbar({ message: 'Successfully created', type: 'is-success' })
              resolve(true)
            } else {
              let message = ''
              if (response.data.info) {
                message = response.data.info
              } else {
                message = 'Something went wrong.'
              }
              Snackbar({ message: message, type: 'is-danger' })
            }
          })
      })
    },
    /** This method fetches the data of a single product (name, id etc) */
    getSingleCompany ({ commit }, companyId) {
      Vue.prototype.$http
        .get(`/admin/company/${companyId}`)
        .then((response) => {
          if (response.data.status === 200 && response.data.data) {
            commit('COMPANY_DETAILS', response.data.data.companyDetails)
          }
        })
    },
    /** This method updates an existing company */
    updateCompany ({ commit }, value) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/admin/company/${value.companyId}`, value)
          .then((response) => {
            if (response.data.status === 200 && response.data.data) {
              Snackbar({ message: 'Successfully updated', type: 'is-success' })
              this.dispatch('company/getSingleCompany', value.companyId)
              resolve(true)
            } else {
              let message = ''
              if (response.data.info) {
                message = response.data.info
              } else {
                message = 'Something went wrong.'
              }
              Snackbar({ message: message, type: 'is-danger' })
            }
          })
      })
    },

    getAllCountries ({ commit }) {
      Vue.prototype.$http
        .get('/admin/company/staticCountry')
        .then((response) => {
          if (response.data.status === 200 && response.data.data) {
            commit('COUNTRIES', response.data.data)
          }
        })
    },

    /** This method fetches the data of states of the selected country */
    getStatesOfCountry ({ commit }, value) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/admin/company/staticState/${value}`)
          .then((response) => {
            if (response.data.status === 200 && response.data.data) {
              commit('STATE', response.data.data)
              resolve(true)
            }
          })
      })
    },
    /** This method fetches the data of social sites */
    getSocialInfo ({ commit }, value) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.get('/admin/company/websites').then((response) => {
          if (response.data.status === 200 && response.data.data) {
            commit('WEBSITES', response.data.data)
            resolve(true)
          }
        })
      })
    },
    /** This method fetches the data of social sites */
    getExchangeList ({ commit }, value) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.get('/admin/company/exchanges').then((response) => {
          if (response.data.status === 200 && response.data.data) {
            commit('EXCHANGES', response.data.data)
            resolve(true)
          }
        })
      })
    }
  },
  mutations: {
    COMPANY_FILTERED_LIST (state, payload) {
      state.companyFilteredList = payload.map((c) => {
        const foundedInparts = c.founded_in ? c.founded_in.split('/') : []
        if (foundedInparts.length === 3) {
          return {
            ...c,
            founded_in: moment(c.founded_in, 'YYYY/MM/DD').format('MM/DD/YYYY')
          }
        } else {
          return { ...c, founded_in: c.founded_in }
        }
      })
    },
    LOADING (state, loading) {
      state.loading = loading
    },
    COMPANY_LIST (state, payload) {
      state.companyList = payload
    },
    COMPANY_DETAILS (state, payload) {
      state.companyDetails = payload
    },
    COUNT (state, payload) {
      state.totalCount = payload
    },
    COUNTRIES (state, payload) {
      state.countriesList = payload
    },
    STATE (state, payload) {
      state.stateList = payload
    },
    CITIES (state, payload) {
      state.citiesList = payload
    },
    WEBSITES (state, payload) {
      state.websitesLink = payload
    },
    EXCHANGES (state, payload) {
      state.exchangeList = payload
    }
  }
}
