import Vue from 'vue'
import Snackbar from '../../components/Snackbar'

export default {
  namespaced: true,
  state: {
    ssoDetails: null
  },
  actions: {
    addSAML ({ commit }, object) {
      Vue.prototype.$http.post(`/client/${object.clientId}/saml`, object.saml).then((res) => {
        if (res.status === 200) {
          commit('ADD_SSO_DETAILS', res.data.data)
          Snackbar({ message: 'Successfully created.', type: 'is-success' })
        }
      })
    },
    updateSAML ({ commit }, object) {
      Vue.prototype.$http.put(`/client/${object.clientId}/saml`, object.saml).then((res) => {
        if (res.status === 200) {
          commit('ADD_SSO_DETAILS', res.data.data)
          Snackbar({ message: 'Successfully updated.', type: 'is-success' })
        }
      })
    },
    getSamlDetails ({ commit }, clientId) {
      Vue.prototype.$http.get(`/client/${clientId}/saml`).then((res) => {
        if (res.data.status === 200) {
          commit('ADD_SSO_DETAILS', res.data.data)
        }
      })
    },
    removeSSO ({ commit }, object) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post(`/client/remove/${object.clientId}/saml`, object).then((res) => {
          if (res.data.status === 200) {
            Snackbar({ message: 'Successfully removed.', type: 'is-success' })
            resolve(res.data.status)
            commit('ADD_SSO_DETAILS', [])
          }
        }).catch(e => {
          resolve(500)
        })
      })
    }
  },
  mutations: {
    ADD_SSO_DETAILS (state, payload) {
      state.ssoDetails = payload.length ? payload[0] : null
    }
  }
}
