import Vue from 'vue'
import store from '../store/index'

const getDefaultWinLossState = () => {
  return {
    cagrGrowthData: [],
    totalCagr: null,
    serviceAddressableData: [],
    partServiceAddressableData: [],
    serviceAddrCAGREstimate: null,
    partServiceAddrCAGREstimate: null,
    marketSegmentInsightData: [],
    vendorLandscapeData: {},
    tableData: [],
    years: [],
    geographicalData: [],
    mapLoader: false,
    dataSetLoader: false,
    forecastLoader: { 'loader1': false, 'loader2': false },
    cagrLoader: false,
    vendorLoader: false,
    regionView: 1,
    geoData: {}
  }
}

export default {
  namespaced: true,
  state: getDefaultWinLossState(),
  actions: {
    getYearlyCagrGrowthData ({ commit }, params) {
      params.groupBy = 'year'
      commit('LOADING', true)
      commit('CAGR_LOADING', true)
      commit('FORECAST_LOADING', { loader: true, id: 1 })
      commit('FORECAST_LOADING', { loader: true, id: 2 })
      // commit('VENDOR_LOADING', true)
      Vue.prototype.$http
        .post('/tam/yearlyCagrGrowth', params)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('CAGR_GROWTH_DATA', response.data.data.overallCagrData.tam)
              commit('TOTAL_CAGR', response.data.data.overallCagrData.cagr)
              commit('SERVICE_ADDRESSABLE_DATA', response.data.data.overallServiceAddressableData)
              commit('SERVICE_ADDR_CAGR_ESTIMATE', response.data.data.serviceAddrCAGREstimate)
              commit('PART_SERVICE_ADDRESSABLE_DATA', response.data.data.partServiceAddressableData)
              commit('PART_SERVICE_ADDR_CAGR_ESTIMATE', response.data.data.partServiceAddrCAGREstimate)
              // commit('VENDOR_LANDSCAPE_DATA', response.data.data.vendorData)
            }
          }
          commit('LOADING', false)
          commit('CAGR_LOADING', false)
          // commit('VENDOR_LOADING', false)
          commit('FORECAST_LOADING', { loader: false, id: 1 })
          commit('FORECAST_LOADING', { loader: false, id: 2 })
        })
        .catch((e) => {
          commit('LOADING', false)
          commit('CAGR_LOADING', false)
          commit('FORECAST_LOADING', { loader: true, id: 1 })
          commit('FORECAST_LOADING', { loader: true, id: 2 })
          // commit('VENDOR_LOADING', false)
        })
    },
    getYears ({ commit }, categories) {
      commit('LOADING', true)
      Vue.prototype.$http
        .post('/tam/years', categories)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('YEARS', response.data.data)
            }
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          commit('LOADING', false)
        })
    },
    getCagrGrowthData ({ commit }, params) {
      params.groupBy = 'year'
      commit('LOADING', true)
      commit('CAGR_LOADING', true)
      Vue.prototype.$http
        .post('/tam/cagrGrowth', params)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('CAGR_GROWTH_DATA', response.data.data.tam)
              commit('TOTAL_CAGR', response.data.data.cagr)
            }
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          commit('LOADING', false)
          commit('CAGR_LOADING', false)
        })
    },
    getVendorLandscapeData ({ commit }, params) {
      commit('LOADING', true)
      commit('VENDOR_LOADING', true)
      Vue.prototype.$http
        .post('/tam/vendorLandscape', params)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('VENDOR_LANDSCAPE_DATA', response.data.data)
            }
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          commit('LOADING', false)
          commit('VENDOR_LOADING', false)
        })
    },
    getServiceAddressableData ({ commit }, params) {
      commit('LOADING', true)
      commit('FORECAST_LOADING', true)
      if (params.part) {
        commit('FORECAST_LOADING', { loader: true, id: 2 })
      } else {
        commit('FORECAST_LOADING', { loader: true, id: 1 })
      }
      Vue.prototype.$http
        .post('/tam/serviceAddressableData', params)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              if (params.part) {
                commit('PART_SERVICE_ADDRESSABLE_DATA', response.data.data.growthData)
                commit('PART_SERVICE_ADDR_CAGR_ESTIMATE', response.data.data.partServiceAddrCAGREstimate)
              } else {
                commit('SERVICE_ADDRESSABLE_DATA', response.data.data.growthData)
                commit('SERVICE_ADDR_CAGR_ESTIMATE', response.data.data.serviceAddrCAGREstimate)
              }
            }
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          commit('LOADING', false)
        })
    },
    getMarketSegmentInsightData ({ commit }, params) {
      commit('LOADING', true)
      commit('MAP_LOADING', true)
      Vue.prototype.$http
        .post('/tam/marketSegmentInsightData', params)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('MARKET_SEGMENT_INSIGHT_DATA', response.data.data)
            }
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          commit('MAP_LOADING', false)
          commit('LOADING', false)
        })
    },
    getDataSet ({ commit }, params) {
      commit('LOADING', true)
      commit('DATASET_LOADING', true)
      Vue.prototype.$http
        .post('/tam/dataSet', params)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('TABLE_DATA', response.data.data)
            }
          }
          commit('LOADING', false)
          commit('DATASET_LOADING', false)
        })
        .catch((e) => {
          commit('LOADING', false)
          commit('DATASET_LOADING', false)
        })
    },
    updateDataSetSort ({ commit }, params) {
      commit('UPDATE_DATASET_SORT', params)
    },
    getGeographicalData ({ commit }, params) {
      params.groupBy = 'year'
      commit('LOADING', true)
      commit('MAP_LOADING', true)
      Vue.prototype.$http
        .post('/tam/getGeographicalData', params)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('GEOGRAPHICAL_DATA', response.data.data)
            }
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          commit('LOADING', false)
          commit('MAP_LOADING', false)
        })
    },
    getGeoData ({ commit }) {
      Vue.prototype.$axiosS3Client.get('/assets/world.json')
        .then((response) => {
          if (response.status === 200) {
            commit('GEOJSON', response.data)
          }
        })
    },
    updateMapLoader ({ commit }, value) {
      commit('MAP_LOADING', value)
    },
    updateDataSetLoader ({ commit }, value) {
      commit('DATASET_LOADING', value)
    },
    updateForecastLoader ({ commit }, value, loaderNumber) {
      commit('FORECAST_LOADING', value, loaderNumber)
    },
    updateVendorLoader ({ commit }, value) {
      commit('VENDOR_LOADING', value)
    },
    updateCagrLoader ({ commit }, value) {
      commit('CAGR_LOADING', value)
    },
    updateRegionView ({ commit }, value) {
      commit('UPDATE_REGION_VIEW', value)
    }
  },
  mutations: {
    LOADING (state, payload) {
      store.state.filters.loading = payload
    },
    MAP_LOADING (state, payload) {
      state.mapLoader = payload
    },
    DATASET_LOADING (state, payload) {
      state.dataSetLoader = payload
    },
    FORECAST_LOADING (state, { loader, id }) {
      if (id) {
        state.forecastLoader[`loader${id}`] = loader
      }
    },
    CAGR_LOADING (state, payload) {
      state.cagrLoader = payload
    },
    VENDOR_LOADING (state, payload) {
      state.vendorLoader = payload
    },
    YEARS (state, payload) {
      state.years = payload
    },
    CAGR_GROWTH_DATA (state, payload) {
      state.cagrGrowthData = payload
    },
    TOTAL_CAGR (state, payload) {
      state.totalCagr = payload
    },
    VENDOR_LANDSCAPE_DATA (state, payload) {
      state.vendorLandscapeData = payload
    },
    SERVICE_ADDRESSABLE_DATA (state, payload) {
      state.serviceAddressableData = payload
    },
    PART_SERVICE_ADDRESSABLE_DATA (state, payload) {
      state.partServiceAddressableData = payload
    },
    SERVICE_ADDR_CAGR_ESTIMATE (state, payload) {
      state.serviceAddrCAGREstimate = payload
    },
    PART_SERVICE_ADDR_CAGR_ESTIMATE (state, payload) {
      state.partServiceAddrCAGREstimate = payload
    },
    MARKET_SEGMENT_INSIGHT_DATA (state, payload) {
      state.marketSegmentInsightData = payload
    },
    TABLE_DATA (state, payload) {
      state.tableData = payload
    },
    UPDATE_DATASET_SORT (state, payload) {
      const array = state.tableData
      if (payload.type === 'asc') {
        if (payload.by === 'cagr' || !isNaN(parseInt(payload.by))) {
          array.sort((a, b) => (Number(a[payload.by]) > Number(b[payload.by])) ? 1 : ((Number(b[payload.by]) > Number(a[payload.by])) ? -1 : 0))
        } else {
          array.sort((a, b) => (a[payload.by] > b[payload.by]) ? 1 : ((b[payload.by] > a[payload.by]) ? -1 : 0))
        }
      } else if (payload.type === 'desc') {
        if (payload.by === 'cagr' || !isNaN(parseInt(payload.by))) {
          array.sort((a, b) => (Number(b[payload.by]) > Number(a[payload.by])) ? 1 : ((Number(a[payload.by]) > Number(b[payload.by])) ? -1 : 0))
        } else {
          array.sort((a, b) => (b[payload.by] > a[payload.by]) ? 1 : ((a[payload.by] > b[payload.by]) ? -1 : 0))
        }
      }
      state.tableData = array
    },
    GEOGRAPHICAL_DATA (state, payload) {
      state.geographicalData = payload
    },
    GEOJSON (state, payload) {
      state.geoData = payload
    },
    UPDATE_REGION_VIEW (state, payload) {
      state.regionView = payload
    }
  }
}
