import Vue from 'vue'
import Axios from 'axios'
import store from '../store/index'
import { SnackbarProgrammatic as snackbar } from 'buefy'

const getDefaultReportRepositoryState = () => {
  return {
    reportData: [],
    totalCount: 0,
    selectedUploadFileType: {},
    languages: []
  }
}

// defined outside to keep the global scope
let cancelToken

export default {
  namespaced: true,
  state: getDefaultReportRepositoryState(),
  actions: {
    async getReports ({ commit, state }, reqData) {
      // Check if there are any previous pending requests
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel('Request canceled due to new request.')
      }
      // Save the cancel token for the current request
      cancelToken = Axios.CancelToken.source()
      try {
        commit('LOADING', true)
        const response = await Vue.prototype.$http.post(
          '/reports-repository',
          reqData,
          { cancelToken: cancelToken.token }
        )
        if (response.status === 200 || response.status === 204) {
          if (response.data && response.data.data) {
            commit('REPORTS_DATA', response.data.data)
            commit('SET_TOTAL_COUNT', response.data.totalCount)
          } else {
            commit('REPORTS_DATA', [])
            commit('SET_TOTAL_COUNT', response.data.totalCount)
          }
        }
        commit('LOADING', false)
      } catch (error) {
        if (error.message === 'Request canceled due to new request.') {
          commit('LOADING', true)
        } else {
          commit('LOADING', false)
        }
      }
    },
    async getLanguageList ({ commit, state }, reqData) {
      try {
        const response = await Vue.prototype.$http.get(
          '/reports-repository/language-list'
        )
        if (response.status === 200 || response.status === 204) {
          if (response.data && response.data.data) {
            commit('LANGUAGES', response.data.data)
          }
        }
      } catch (error) {
      }
    },
    async uploadReports({ commit, state }, fileInfo) {
      try {
        const file = fileInfo.file;
        const reportToken = localStorage.getItem('reportToken');
    
        const fileReader = new FileReader();
        const base64DataPromise = new Promise((resolve, reject) => {
          fileReader.onload = () => {
            resolve(fileReader.result); // Extract the base64 content after the comma
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
    
        fileReader.readAsDataURL(file);
        const base64Data = await base64DataPromise;
    
        const requestData = {
          file: {
            filename: file.name,
            data: base64Data,
          },
        };
    
        if (fileInfo.metaInfo) {
          requestData.metaInfo = fileInfo.metaInfo;
        }
    
        if (reportToken) {
          requestData.reportToken = reportToken;
        }
        requestData.reportTypeParent = fileInfo.reportTypeParent
    
        const response = await Vue.prototype.$http.post('/reports-repository/upload', requestData);
    
        if (response.data && response.data.status === 200) {
          if (response.data.data) {
            if (response.data.data.reportToken) {
              localStorage.setItem('reportToken', response.data.data.reportToken);
            }
          }
          return response.data.data;
        } else if (response.data && response.data.status === 400) {
          return { info: response.data.info }
        } else {
          return { info: 'Something went wrong' }
        }
      } catch (error) {
        return null;
      }
    },
    
    async downloadAttachments({ commit, state }, reportInfo) {
      const allFiles = []
      let message = 'Downloaded the report attachments successfully'
      try {
        let fileList = reportInfo.attachments
        const downloadPromises = []

        if (fileList.length) {
          for (let i = 0; i < fileList.length; i++) {
            let file = fileList[i]
            const requestData = {
              reportId: parseInt(reportInfo.reportId),
              attachmentId: file.attachmentId,
              key: file.name
            };
            const reportToken = localStorage.getItem('reportToken')
            if (reportToken) {
              requestData.reportToken = reportToken
            }
            const key = file.name
      
            const downloadPromise = new Promise(async (resolve) => {
              try {
                const response = await Vue.prototype.$http.post(`/reports-repository/download`, requestData, {
                  responseType: 'blob', // Receive a binary response
                })
      
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', key) // Set the file name
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
      
                allFiles.push({ key, status: 'downloaded' })
                resolve(key)
              } catch (error) {
                allFiles.push({ key, status: 'failed', error })
                message = error.message
                resolve(null)
              }
            });
      
            downloadPromises.push(downloadPromise)
          }
      
          await Promise.all(downloadPromises)
        } else {
          message = 'Report not found!'
        }
    
      } catch (error) {
        message = 'Something went wrong!'
      }

      // Create a message string for the snackbar for future if required
    // const message = allFiles.map(file => {
    //   if (file.status === 'downloaded') {
    //     return `${file.key}`
    //   } else {
    //     return `${file.key}`
    //   }
    // }).join('\n')

    snackbar.open({
      duration: 10000,
      message: message,
      type: 'is-success',
      position: 'is-bottom-right',
    })
    },

    async getReportInfoById ({ commit, state }, reportId) {
      try {
        const response = await Vue.prototype.$http.get(
          `reports-repository/get-report-by-id/${reportId}`
        )
        if (response.status === 200 || response.status === 204) {
          if (response.data && response.data.data) {
            return response.data.data
          } else {
            return null
          }
        }
      } catch (error) {
        return null
      }
    },

    async deleteReportById ({ commit, state }, report) {
      let message = ''
      let returnValue = null
      try {
        const reportToken = localStorage.getItem('reportToken')
        let reqData = {
          attachments: report.attachments,
          reportToken: reportToken
        }
        const response = await Vue.prototype.$http.post(
          `reports-repository/${report.reportsId}`,
          reqData
        )
        if (response.data && response.data.status === 200) {
          if (response.data.data) {
            if (response.data.data.reportToken) {
              localStorage.setItem('reportToken', response.data.data.reportToken);
            }
            returnValue =  response.data
          } else {
            returnValue = null
          }
          message = 'Report deleted successfully!'
        }
      } catch (error) {
        message = 'Something went wrong!'
        returnValue = null
      }

      snackbar.open({
        duration: 5000,
        message: message,
        type: 'is-success',
        position: 'is-bottom-right',
      })
      return returnValue
    },
    
    async getReportToken ({ commit, state }, file) {
      try {
        let reportToken = localStorage.getItem('reportToken')
        if (!reportToken) {
          const response = await Vue.prototype.$http.get(
            '/get-report-token'
          )
          if (response.status === 200 || response.status === 204) {
            if (response.data && response.data.data) {
              localStorage.setItem('reportToken', response.data.data)
            } else {
            }
          }
        }
      } catch (error) {
      }
    },
    updateTotalCount ({ commit }, totalCount) {
      commit('SET_TOTAL_COUNT', totalCount)
    },
    updateSelectedUploadFileType ({ commit }, payload) {
      commit('SET_SELECTED_UPLOAD_FILE_TYPE', payload)
    },

    async getSignedURL ({ commit, state }, file) {
      try {
        const reportToken = localStorage.getItem('reportToken')
        let requestData = {
          key: file.name
        }
        if (reportToken) {
          requestData.reportToken = reportToken
        }
          const response = await Vue.prototype.$http.post(
            '/reports-repository/link',
            requestData
          )
          if (response.status === 200 || response.status === 204) {
            if (response.data && response.data.data) {
              window.open(response.data.data.url, '_blank')
            }
        }
      } catch (error) {
      }
    },
  },
  mutations: {
    LOADING (state, payload) {
      store.state.filters.loading = payload
    },
    REPORTS_DATA (state, payload) {
      state.reportData = payload
    },
    SET_TOTAL_COUNT (state, payload) {
      state.totalCount = payload
    },
    SET_SELECTED_UPLOAD_FILE_TYPE (state, fileType) {
      state.selectedUploadFileType = fileType
    },
    LANGUAGES (state, languageList) {
      state.languages = languageList
    }
  }
}
