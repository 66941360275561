import Vue from 'vue'
import store from '../store/index'
import { esgSentimentList } from '../constant/data'
import moment from 'moment'
import Axios from 'axios'
import router from '../router'
import Snackbar from '../components/Snackbar'

const getDefaultState = () => {
  return {
    publishedNewsData: [],
    totalCount: 0,
    skip: 0,
    limit: 10,
    currentPage: 1,
    ratingData: {},
    sort: {
      orderBy: 'createdAt',
      order: 'desc'
    },
    sortOptions: [],
    articleData: null,
    sentimentList: esgSentimentList,
    selectedSentiment: esgSentimentList,
    portfolioList: [],
    selectedPortfolios: [],
    allEsgCompaniesList: [],
    info: null,
    activeEsgTimePeriod: null,
    publisherList: [],
    infiniteScroll: false,
    publisherOffset: 0,
    companyOffset: 0,
    selectedPublishers: [], // contains id of publishers and its used to make call in backend
    selectedPublishersList: [], // contains the array of publisher object
    esgCompaniesList: [],
    selectedEsgCompaniesList: [],
    selectedEsgCompanies: [],
    dualListLoader: true,
    searchText: null,
    searchPublisherOffset: 0,
    searchCompanyOffset: 0,
    companyLoading: false
  }
}

// defined outside to keep the global scope
let companyCancelToken
let publisherCancelToken
let newsCancelToken
let daasCompanyCancelToken
const messageDuration = 4000

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async downloadPublishedNews ({ commit }, reqData) {
      commit('COMMON_FULL_PAGE_LOADER', true)
      let errorMessage = 'Error occurred. Please try after some time!'
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post('/news/download', reqData)
          .then(response => {
            if (response.data.status && response.data.status !== 200) {
              Snackbar({message: response.data.info, type: 'is-success'})
              resolve({})
            } else {
              Snackbar({
                message:
                  'Download request submitted successfully. You will soon receive an email with the download link.',
                type: 'is-success',
                duration: messageDuration
              })
            }
            commit('COMMON_FULL_PAGE_LOADER', false)
            resolve(response)
          })
          .catch(error => {
            commit('COMMON_FULL_PAGE_LOADER', false)
          Snackbar({message: errorMessage, type: 'is-success'})
            reject(error)
          })
      })
    },

    /**  This method fetches a list of published news and their related properties and stores all of it in the store. */
    getPublishedNewsList ({ commit }, reqBody) {
      commit('SET_PUBLISHED_NEWS_DATA', [])
      commit('LOADING', true)
      commit('SET_INFO', null)
      // Check if there are any previous pending requests
      if (typeof newsCancelToken !== typeof undefined) {
        newsCancelToken.cancel('Request canceled due to new request.')
      }
      // Save the cancel token for the current request
      newsCancelToken = Axios.CancelToken.source()
      Vue.prototype.$http
        .post(
          '/news',
          reqBody,
          { cancelToken: newsCancelToken.token } // Pass the cancel token to the current request
        )
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('SET_PUBLISHED_NEWS_DATA', response.data.data)
              commit('SET_ROW_COUNT', response.data.totalRows)
            }
          }
          if (response.data.status === 204) {
            commit('SET_INFO', response.data.info)
            commit('SET_ROW_COUNT', response.data.totalRows)
            commit('SET_PUBLISHED_NEWS_DATA', null)
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          if (e.message === 'Request canceled due to new request.') {
            commit('LOADING', true)
          } else {
            commit('LOADING', false)
          }
        })
    },
    getPublishedNewsById ({ commit }, articleId) {
      commit('LOADING', true)
      Vue.prototype.$http
        .get(`/news/${articleId}`)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('SET_SELECTED_ARTICLE', response.data.data)
            }
          } else {
            router.push({ name: 'esg-controversies' })
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          commit('LOADING', false)
        })
    },
    updateTotalCount ({ commit }, totalCount) {
      commit('SET_ROW_COUNT', totalCount)
    },
    updateArticleData ({ commit }, articleData) {
      commit('SET_SELECTED_ARTICLE', articleData)
    },
    /** This method updates the limit of viewable reviews per page */
    updateLimit ({ commit }, limit) {
      commit('SET_LIMIT', limit)
    },
    updateCurrentPage ({ commit }, pageNo) {
      commit('SET_CURRENT_PAGE', pageNo)
    },
    updateSelectedSentiments ({ commit }, sentiments) {
      localStorage.setItem('ESG_SENTIMENT_OPTIONS', JSON.stringify(sentiments))
      commit('SET_SELECTED_SENTIMENTS', sentiments)
    },
    updateSelectedPublishers ({ commit }, publishers) {
      localStorage.setItem(
        'ESG_SELECTED_PUBLISHER_LIST',
        JSON.stringify(publishers)
      )
      commit('SET_SELECTED_PUBLISHER_LIST', publishers)
      const newPublishers = publishers.map((publisher) => Number(publisher.id))
      commit('SET_SELECTED_PUBLISHER', newPublishers)
    },
    updatePublisherList ({ commit }, publisherList) {
      commit('SET_PUBLISHED_LIST', publisherList)
    },
    updateEsgCompaniesList ({ commit }, companiesList) {
      commit('ESG_COMPANY_LIST', companiesList)
    },
    updateEsgCompayOffset ({ commit }, offset) {
      commit('SET_COMPANY_OFFSET', offset)
    },
    updatePublisherOffset ({ commit }, offset) {
      commit('SET_PUBLISHER_OFFSET', offset)
    },
    updateSearchPublisherOffset ({ commit }, offset) {
      commit('SET_SEARCH_PUBLISHER_OFFSET', offset)
    },
    updateSearchCompanyOffset ({ commit }, offset) {
      commit('SET_SEARCH_COMPANY_OFFSET', offset)
    },
    resetESGFilters ({ commit, dispatch, state }) {
      const defaultSelectedSentiment = esgSentimentList
      const defaultTimeRange = {
        range: [
          moment().startOf('month').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ]
      }
      dispatch('updateSelectedSentiments', defaultSelectedSentiment)
      dispatch('updateActiveEsgTimePeriod', defaultTimeRange)
      dispatch('updateSelectedPublishers', [])
      dispatch('updateSelectedEsgCompanies', [])
    },
    updateInfo ({ commit }, info) {
      commit('SET_INFO', info)
    },
    updateActiveEsgTimePeriod ({ commit }, timePeriod) {
      localStorage.setItem('ESG_TIME_PERIOD', JSON.stringify(timePeriod))
      commit('SET_ACTIVE_ESG_TIME_PERIOD', timePeriod)
    },
    getNewsPublishers ({ dispatch, commit, state }, searchText) {
      // Check if there are any previous pending requests
      if (typeof publisherCancelToken !== typeof undefined) {
        publisherCancelToken.cancel('Request canceled due to new request.')
      }
      // Save the cancel token for the current request
      publisherCancelToken = Axios.CancelToken.source()
      let url = ''
      const limit = 25 // default limit
      if (searchText) {
        searchText = encodeURIComponent(searchText)
        url =
          '/news-publishers?searchQuery=' + searchText +
          '&offset=' + state.searchPublisherOffset + '&limit=' + limit + '&orderBy=name&order=asc'
      } else {
        url =
          '/news-publishers?offset=' +
          state.publisherOffset +
          '&limit=' + limit + '&orderBy=name&order=asc'
      }
      Vue.prototype.$http
        .get(url,
          { cancelToken: publisherCancelToken.token } // Pass the cancel token to the current request
        )
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('SET_PUBLISHED_LIST', response.data.data)
              commit('SET_INFINITE_SCROLL', true)
              if (searchText) {
                commit(
                  'SET_SEARCH_PUBLISHER_OFFSET', state.searchPublisherOffset + limit
                )
              } else {
                commit(
                  'SET_PUBLISHER_OFFSET', state.publisherOffset + limit
                )
              }
            }
          }
          if (response.data.data.length < 25 || response.data.status === 204) {
            commit('DUAL_LIST_LOADER', false)
          }
        })
        .catch((e) => {
          if (e.message === 'Request canceled due to new request.') {
            commit('DUAL_LIST_LOADER', true)
          } else {
            commit('DUAL_LIST_LOADER', false)
          }
        })
    },
    getEsgCompanyList ({ commit, state }, searchText) {
      // Check if there are any previous pending requests
      if (typeof companyCancelToken !== typeof undefined) {
        companyCancelToken.cancel('Request canceled due to new request.')
      }
      // Save the cancel token for the current request
      companyCancelToken = Axios.CancelToken.source()
      let url = ''
      const limit = 25 // default limit
      if (searchText) {
        searchText = encodeURIComponent(searchText)
        url =
          '/company-tenant?searchQuery=' +
          searchText + '&offset=' + state.searchCompanyOffset +
          '&limit=' + limit + '&orderBy=name&order=asc'
      } else {
        url =
          '/company-tenant?offset=' +
          state.companyOffset +
          '&limit=' + limit + '&orderBy=name&order=asc'
      }
      Vue.prototype.$http
        .get(url,
          { cancelToken: companyCancelToken.token } // Pass the cancel token to the current request
        )
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('ESG_COMPANY_LIST', response.data.data)
              commit('SET_INFINITE_SCROLL', true)
              if (searchText) {
                commit(
                  'SET_SEARCH_COMPANY_OFFSET', state.searchCompanyOffset + limit
                )
              } else {
                commit(
                  'SET_COMPANY_OFFSET', state.companyOffset + limit
                )
              }
            }
          }
          if (response.data.data.length < 25 || response.data.status === 204) {
            commit('DUAL_LIST_LOADER', false)
          }
        })
        .catch((e) => {
          if (e.message === 'Request canceled due to new request.') {
            commit('DUAL_LIST_LOADER', true)
          } else {
            commit('DUAL_LIST_LOADER', false)
          }
        })
    },
    // get Esg company list without infinite scroll
    getEsgCompany ({ commit, state }, searchText) {
      // Check if there are any previous pending requests
      if (typeof daasCompanyCancelToken !== typeof undefined) {
        daasCompanyCancelToken.cancel('Request canceled due to new request.')
      }
      // Save the cancel token for the current request
      daasCompanyCancelToken = Axios.CancelToken.source()
      commit('COMPANY_LOADING', true)
      let url = ''
      if (searchText) {
        searchText = encodeURIComponent(searchText)
        url =
          '/company-tenant?searchQuery=' +
          searchText + '&orderBy=name&order=asc'
      }
      Vue.prototype.$http
        .get(url,
          { cancelToken: daasCompanyCancelToken.token } // Pass the cancel token to the current request
        )
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('ESG_ALL_COMPANY_LIST', response.data.data)
            }
          }
          commit('COMPANY_LOADING', false)
        })
        .catch((e) => {
          if (e.message === 'Request canceled due to new request.') {
            commit('COMPANY_LOADING', true)
          } else {
            commit('COMPANY_LOADING', false)
          }
        })
    },
    updateSelectedEsgCompanies ({ commit }, companies) {
      localStorage.setItem(
        'ESG_SELECTED_COMPANIES_LIST',
        JSON.stringify(companies)
      )
      commit('ESG_COMPANY_LIST', companies)
      commit('SET_SELECTED_COMPANIES_LIST', companies)
      const newCompanies = companies.map((company) => company.id)
      commit('SET_SELECTED_COMPANIES', newCompanies)
    },
    updateSearchText ({ commit }, keyword) {
      sessionStorage.setItem('ESG_SEARCH_TEXT', JSON.stringify(keyword))
      commit('SET_SEARCH_TEXT', keyword)
    },
    updateDualListLoader ({ commit }, isLoading) {
      commit('DUAL_LIST_LOADER', isLoading)
    },

    async getPortfolioList ({commit, state}) {
      try {
        let response = await Vue.prototype.$http.get('portfolios')
        let newList = []
        if (response.data.status === 200) {
          newList = response.data.data
          commit('PORTFOLIO_LIST', newList)

          let persistedSelection = localStorage.getItem('PORTFOLIOS')
          if (persistedSelection) {
            let persistedPortfolio = JSON.parse(persistedSelection)
            commit('SELECTED_PORTFOLIOS', persistedPortfolio)
          } else {
            commit('SELECTED_PORTFOLIOS', newList)
            localStorage.setItem('PORTFOLIOS', JSON.stringify(newList))
          }
        } else {
          commit('PORTFOLIO_LIST', [])
          commit('SELECTED_PORTFOLIOS', [])

        }
      } catch (error) {
        
      }
    },

    updateSelectedPortfolios ({commit}, portfolioValue) {
      commit('SELECTED_PORTFOLIOS', portfolioValue)
    }
  },
  mutations: {
    PORTFOLIO_LIST (state, portfolios) {
      state.portfolioList = portfolios
    },
    SELECTED_PORTFOLIOS (state, portfolios) {
      state.selectedPortfolios = portfolios
      localStorage.setItem('PORTFOLIOS', JSON.stringify(portfolios))
    },
    LOADING (state, payload) {
      store.state.filters.loading = payload
    },
    COMMON_FULL_PAGE_LOADER (state, value) {
      store.state.common.fullPageLoader = value
    },
    COMPANY_LOADING (state, isLoading) {
      state.companyLoading = isLoading
    },
    DUAL_LIST_LOADER (state, payload) {
      state.dualListLoader = payload
    },
    SET_LIMIT (state, payload) {
      state.limit = payload
    },
    SET_ROW_COUNT (state, totalRows) {
      state.totalCount = totalRows
    },
    SET_PUBLISHED_NEWS_DATA (state, publishedNewsList) {
      // sorting based on confidence and selecting top 3
      const publishedNewsData = publishedNewsList.map((publishedNews) => {
        publishedNews.categories.sort(
          (cat1, cat2) => cat2.confidence - cat1.confidence
        )
        return publishedNews
      })
      state.publishedNewsData = publishedNewsData
    },
    SET_CURRENT_PAGE (state, pageNo) {
      state.currentPage = pageNo
    },
    SET_SELECTED_ARTICLE (state, article) {
      if (article.sentiments) { article.categories.sort((cat1, cat2) => cat2.confidence - cat1.confidence) }
      if (article.categories) {
        article.categories.sort(
          (cat1, cat2) => cat2.confidence - cat1.confidence
        )
      }
      state.articleData = article
    },
    SET_SELECTED_SENTIMENTS (state, sentiments) {
      state.selectedSentiment = sentiments
    },
    SET_SELECTED_PUBLISHER (state, publishers) {
      state.selectedPublishers = publishers
    },
    SET_SELECTED_PUBLISHER_LIST (state, publishersList) {
      state.selectedPublishersList = publishersList
    },
    SET_INFO (state, info) {
      state.info = info
    },
    SET_ACTIVE_ESG_TIME_PERIOD (state, timePeriod) {
      state.activeEsgTimePeriod = timePeriod
    },
    SET_PUBLISHED_LIST (state, publisherList) {
      const newPublisherList = [...state.publisherList, ...publisherList]
      localStorage.setItem(
        'ESG_PUBLISHER_LIST',
        JSON.stringify(newPublisherList)
      )
      const uniquePublisherList = [...new Map(newPublisherList.map((publisher) => [publisher.id, publisher])).values()]
      state.publisherList = uniquePublisherList
    },
    SET_INFINITE_SCROLL (state, infiniteScroll) {
      state.infiniteScroll = infiniteScroll
    },
    SET_PUBLISHER_OFFSET (state, offset) {
      state.publisherOffset = offset
    },
    SET_SEARCH_PUBLISHER_OFFSET (state, offset) {
      state.searchPublisherOffset = offset
    },
    SET_COMPANY_OFFSET (state, offset) {
      state.companyOffset = offset
    },
    SET_SEARCH_COMPANY_OFFSET (state, offset) {
      state.searchCompanyOffset = offset
    },
    ESG_COMPANY_LIST (state, payload) {
      const newCompanyList = [...state.esgCompaniesList, ...payload]
      localStorage.setItem(
        'ESG_COMPANIES_LIST',
        JSON.stringify(newCompanyList)
      )
      const uniqueCompaniesList = [...new Map(newCompanyList.map((publisher) => [publisher.id, publisher])).values()]
      state.esgCompaniesList = uniqueCompaniesList
    },
    ESG_ALL_COMPANY_LIST (state, companiesList) {
      const newCompanyList = companiesList
      const uniqueCompaniesList = [...new Map(newCompanyList.map((publisher) => [publisher.id, publisher])).values()]
      state.allEsgCompaniesList = uniqueCompaniesList
    },
    SET_SELECTED_COMPANIES_LIST (state, companyList) {
      state.selectedEsgCompaniesList = companyList
    },
    SET_SELECTED_COMPANIES (state, companyList) {
      state.selectedEsgCompanies = companyList
    },
    SET_SEARCH_TEXT (state, keyword) {
      state.searchText = keyword
    }
  }
}
