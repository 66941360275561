import Vue from 'vue'
import Snackbar from '../../components/Snackbar'

export default {
  namespaced: true,
  state: {
    roleList: [],
    roles: [],
    allowedRoles: [],
    roleDetails: null,
    totalCount: null
  },
  actions: {
    /** This method returns a paginated list of roles present in the database */
    getRoleList ({ commit }, value) {
      Vue.prototype.$http.post('/admin/roles-and-permissions', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('ROLE_LIST', response.data.data.data)
          commit('COUNT', response.data.data.count)
        }
      })
    },
    /** This method returns a complete list of all the roles present in the database */
    getRoles ({ commit }, value) {
      Vue.prototype.$http.get('/admin/roles-and-permissions').then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('ROLES', response.data.data)
        }
      })
    },

    /** This method returns a limited list of roles which can be assigned to a user by an admin */
    getAllowedRoles ({ commit }, value) {
      Vue.prototype.$http.get('/admin/roles-and-permissions/allow/role').then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('ALLOW_ROLES', response.data.data)
        }
      })
    },
    /** This method adds a new role to the database */
    addRole ({ commit }, value) {
      Vue.prototype.$http.post('/admin/roles-and-permissions/add', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully created', type: 'is-success' })
        } else if (response.data.status === 201) {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        } else if (response.data.status === 500) {
          Snackbar({ message: 'Some error occured.', type: 'is-danger' })
        }
      })
    },
    /** This method updates the permsisions of an existing role in the database */
    updateRole ({ commit }, payload) {
      Vue.prototype.$http.put(`/admin/roles-and-permissions/${payload.roleId}`, payload).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully updated', type: 'is-success' })
          commit('SINGLE_ROLE', response.data.data)
        } else if (response.data.status === 201) {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        } else if (response.data.status === 500) {
          Snackbar({ message: 'Some error occured.', type: 'is-danger' })
        }
      })
    },
    /** This method fetches the permissions of a single role  */
    getRoleDetails ({ commit }, roleId) {
      Vue.prototype.$http.get(`/admin/roles-and-permissions/${roleId}`).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('SINGLE_ROLE', response.data.data)
        }
      })
    }
  },
  mutations: {
    ROLE_LIST (state, payload) {
      state.roleList = payload
    },
    ALLOW_ROLES (state, payload) {
      state.allowedRoles = payload
    },
    ROLES (state, payload) {
      state.roles = payload
    },
    SINGLE_ROLE (state, payload) {
      state.roleDetails = payload
    },
    COUNT (state, payload) {
      state.totalCount = payload
    }
  }
}
