import Vue from 'vue'
import store from '../store/index'
import Axios from 'axios'

const getDefaultState = () => {
  return {
    esgDisclosureData: [],
    disclosureFilter: {
      totalCount: 0,
      searchContent: null,
      offset: 0,
      limit: 10,
      filterOptions: null
    },
    companyFilter: [],
    searchCompanyDisclosure: [],
    filteredColumnList: []
  }
}

// defined outside to keep the global scope
let cancelToken

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    downloadDisclosuresData ({ commit }, reqData) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post('/esg-disclosures/download-csv', reqData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /**  This method fetches a list of esg disclosure and
     * their related properties and stores all of it in the store. */
    getEsgDisclosureList ({ commit }, reqData) {
      commit('LOADING', true)
      // Check if there are any previous pending requests
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel('Request canceled due to new request.')
      }
      // Save the cancel token for the current request
      cancelToken = Axios.CancelToken.source()
      Vue.prototype.$http
        .post(
          '/esg-disclosures',
          reqData,
          { cancelToken: cancelToken.token } // Pass the cancel token to the current request
        )
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('SET_ESG_DISCLOSURE_DATA', response.data.data)
              commit('SET_ROW_COUNT', response.data.totalRows)
            }
          }
          if (response.data.status === 204) {
            commit('SET_ROW_COUNT', response.data.totalRows)
            commit('SET_ESG_DISCLOSURE_DATA', [])
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          if (e.message === 'Request canceled due to new request.') {
            commit('LOADING', true)
          } else {
            commit('LOADING', false)
          }
        })
    },
    updateDisclosureFilter ({ commit, state }, disclosureFilter) {
      localStorage.setItem('DISCLOSURE_FILTERS', JSON.stringify(disclosureFilter))
      commit('SET_DISCLOSURE_SEARCH_CONTENT', disclosureFilter.searchContent)
      commit('SET_DISCLOSURE_SKIP', disclosureFilter.offset)
      commit('SET_DISCLOSURE_TAKE', disclosureFilter.limit)
      commit('SET_FILTER_OPTIONS', disclosureFilter.filterOptions)
    },
    updateCompanyFilter ({ commit, state }, companyFilter) {
      localStorage.setItem('COMPANY_FILTER', JSON.stringify(companyFilter))
      commit('SET_COMPANY_FILTER', companyFilter)
    },

    updateSearchCompanyDisclosure ({ commit }, searchCompanyDisclosure) {
      commit('SEARCH_COMPANY_DISCLOSURE', searchCompanyDisclosure)
    },
    addFilteredColumnList ({ commit, state }, columnObj) {
      const { columnName, isFilter } = columnObj
      let columnList = [...state.filteredColumnList]
      if (isFilter) {
        columnList = [...columnList, columnName]
      } else {
        const index = columnList.indexOf(columnName)
        if (index > -1) {
          columnList.splice(index, 1)
        }
      }
      columnList = [...new Set(columnList)]
      localStorage.setItem('DISCLOSURE_FILTERED_COLUMNS', JSON.stringify(columnList))
      commit('SET_FILTERED_COLUMN_LIST', columnList)
    },
    updateFilteredColumnList ({ commit }, filteredColumnList) {
      commit('SET_FILTERED_COLUMN_LIST', filteredColumnList)
    }

  },
  mutations: {
    LOADING (state, payload) {
      store.state.filters.loading = payload
    },
    SET_ROW_COUNT (state, totalRows) {
      state.disclosureFilter.totalCount = totalRows
    },
    SET_ESG_DISCLOSURE_DATA (state, esgDisclosureData) {
      const { calcAmt, calcuatedCurrencyName, origAmt, originalCurrencyName } = esgDisclosureData
      const newEsgDisclosuresData = esgDisclosureData.map(element => {
        return { ...element, calcAmtCombined: calcAmt + calcuatedCurrencyName, origAmtCombined: origAmt + originalCurrencyName }
      })
      state.esgDisclosureData = newEsgDisclosuresData
    },
    SET_DISCLOSURE_SEARCH_CONTENT (state, searchContent) {
      state.disclosureFilter.searchContent = searchContent
    },
    SET_DISCLOSURE_SKIP (state, offset) {
      state.disclosureFilter.offset = offset
    },
    SET_COMPANY_FILTER (state, companyFilter) {
      state.companyFilter = companyFilter
    },
    SET_DISCLOSURE_TAKE (state, limit) {
      state.disclosureFilter.limit = limit
    },
    SET_FILTER_OPTIONS (state, filterOptions) {
      state.disclosureFilter.filterOptions = filterOptions
    },
    SET_FILTERED_COLUMN_LIST (state, filteredColumnList) {
      state.filteredColumnList = filteredColumnList
    },
    SEARCH_COMPANY_DISCLOSURE (state, companyDisclosure) {
      state.searchCompanyDisclosure = companyDisclosure
    }
  }
}
