import Vue from 'vue'
import Snackbar from '../../components/Snackbar'

export default {
  namespaced: true,
  state: {
    subscriptionList: [],
    subscriptionDetails: null,
    totalCount: null

  },
  actions: {
    /** This method returns a list of subscriptions present in the database */
    getSubscriptionList ({ commit }, value) {
      Vue.prototype.$http.post('/admin/client/subscription', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('SUBSCRIPTION_LIST', response.data.data.data)
          commit('COUNT', response.data.data.count)
        }
      })
    },
    /** This method adds a subscription to the database */
    addSubscription ({ commit }, value) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post('/admin/client/subscription/add', value).then((response) => {
          if (response.data.status === 200 && response.data.data) {
            resolve()
            Snackbar({ message: 'Successfully created', type: 'is-success' })
          } else if (response.data.status === 201) {
            Snackbar({ message: response.data.info, type: 'is-danger' })
          }
        })
      })
    },
    /** This method fetches the details of a single subscription from the database */
    getSingleSubscription ({ commit }, subId) {
      Vue.prototype.$http.get(`/admin/client/subscription/${subId}`).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('SUBSCRIPTION_DETAILS', response.data.data)
        }
      })
    },
    /** This method updates the details of an existing subscription */
    updateSubscription ({ commit }, value) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.put(`/admin/client/subscription/${value.subId}`, value).then((response) => {
          if (response.data.status === 200 && response.data.data) {
            resolve()
            Snackbar({ message: 'Successfully updated', type: 'is-success' })
            commit('SUBSCRIPTION_DETAILS', response.data.data)
          } else if (response.data.status === 201) {
            Snackbar({ message: response.data.info, type: 'is-danger' })
          }
        })
      })
    },
    /** This method deletes a subscription from the database */
    deleteSubscription ({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.delete(`/admin/client/subscription/${id}`).then((response) => {
          if (response.data.status === 200 && response.data.data) {
            resolve(200)
            Snackbar({ message: 'Successfully deleted', type: 'is-success' })
          } else {
            Snackbar({ message: 'Some Error Occured.', type: 'is-danger' })
          }
        })
      })
    }
  },
  mutations: {
    SUBSCRIPTION_LIST (state, payload) {
      state.subscriptionList = payload
    },
    SUBSCRIPTION_DETAILS (state, payload) {
      state.subscriptionDetails = payload.length ? payload[0] : null
    },
    COUNT (state, payload) {
      state.totalCount = payload
    }
  }
}
