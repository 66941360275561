import Vue from 'vue'
import Snackbar from '@/components/Snackbar'
import { entityStatus } from '@/constant/data.js'

export default {
  namespaced: true,
  state: {
    entityDetails: [],
    entitiesOverview: null,
    isSubmittedEntity: false
  },
  actions: {
    async getEntityDetailsById({ commit }, { sgaId }) {
      const user = JSON.parse(localStorage.getItem('user'))
      const roleId = user.role
      // Reset entity details
      commit('SET_ENTITYDETAILS', [])
      try {
        const response = await Vue.prototype.$http.post(
          `/dmp/entity-detail?sgaId=${sgaId}`,
          { roleId }
        )
        if (response.data.status === 200 && response.data.data) {
          commit('SET_ENTITYDETAILS', response.data.data.entityDetails)
          const { currentStatus } = response.data.data.entityDetails[0]
          // check if entity is submitted
          if (currentStatus) {
            commit(
              'SET_IS_ENTITY_SUBMITTED',
              currentStatus === entityStatus.SUBMIT
            )
          }
        } else if (response.data.status === 204) {
          // TODO: Decide what action to take for status 204
        }
      } catch (error) {
        // Handle any errors here
      }
    },
    async getEntityDetailOverView({ commit }, isPMRole = false) {
      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/entities-overview',
          {
            params: { isPMRole }
          }
        )
        if (response.data.status === 200 && response.data.data) {
          commit('SET_ENTITY_DETAIL_OVERVIEW', response.data.data)
        } else if (response.data.status === 204) {
          // TODO: Decide what action to take for status 204
        }
      } catch (error) {
        // Handle any errors here
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async updateEntityStatus({}, payload) {
      try {
        const response = await Vue.prototype.$http.post(
          '/dmp/entity-review',
          payload
        )
        if (response.data && response.data.status === 200) {
          return true
        }
      } catch (error) {
        Snackbar({
          message: 'Unable to update entity status',
          type: 'is-danger'
        })
        throw error
      }
    },
    async checkAndUpdateTodoStatus({ state, dispatch, rootState }) {
      if (state.entityDetails && state.entityDetails.length > 0) {
        const { currentStatus } = state.entityDetails[0]
        const isTodoStatus = currentStatus === 'Todo'

        if (isTodoStatus) {
          const { user, dmp } = rootState
          const userId = user.userDetails.userId
          const { sgaId, assignmentId } = dmp.entityDetails
          const IN_PROGRESS = 'In Progress'
          const payload = {
            assignmentId,
            userId,
            statusName: IN_PROGRESS
          }
          await dispatch('updateEntityStatus', payload)
          await dispatch('getEntityDetailsById', { sgaId })
        }
      }
    }
  },
  mutations: {
    SET_ENTITYDETAILS(state, entityDetails) {
      state.entityDetails = entityDetails
    },
    SET_ENTITY_DETAIL_OVERVIEW(state, entitiesOverview) {
      state.entitiesOverview = entitiesOverview
    },
    SET_IS_ENTITY_SUBMITTED(state, isSubmittedEntity) {
      state.isSubmittedEntity = isSubmittedEntity
    }
  }
}
