import Vue from 'vue'
import Snackbar from '../../components/Snackbar'

export default {
  namespaced: true,
  state: {
    industryList: [],
    allIndustryList: [],
    industryDetails: null,
    totalCount: null,
    allIndustryIds: []
  },
  actions: {
    /** This method returns a list of industries present in the database */
    getHeirarchicalIndustryList ({ commit }, value) {
      Vue.prototype.$http.get('/admin/industry/heirarchy', value).then(response => {
        if (response.data.status === 200 && response.data.data) {
          commit('ALL_INDUSTRY_LIST', response.data.data.data)
          commit('ALL_INDUSTRY_IDS', response.data.data.allIndustryIds)
        }
      })
    },

    /** This method returns a list of industries present in the database with search and limit ability */
    getIndustryList ({ commit }, value) {
      Vue.prototype.$http.post('/admin/industry', value).then(response => {
        if (response.data.status === 200 && response.data.data) {
          commit('INDUSTRY_LIST', response.data.data.data)
          commit('COUNT', response.data.data.count)
        }
      })
    },
    /** This method adds a new industry to the database */
    addIndustry ({ commit }, value) {
      Vue.prototype.$http.post('/admin/industry/add', value).then(response => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully created', type: 'is-success' })
        } else if (response.data.status === 500) {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        }
      })
    },
    /** This method updates the data/details of an existing industry in the database */
    updateIndustry ({ commit }, payload) {
      Vue.prototype.$http
        .put(`/admin/industry/${payload.industryId}`, payload)
        .then(response => {
          if (response.data.status === 200 && response.data.data) {
            Snackbar({ message: 'Successfully updated', type: 'is-success' })
            commit('SINGLE_INDUSTRY', response.data.data)
          } else if (response.data.status === 500) {
            Snackbar({ message: response.data.info, type: 'is-danger' })
          }
        })
    },
    /** This method fetches the data of a single industry from the database */
    getSingleIndustry ({ commit }, industryId) {
      Vue.prototype.$http
        .get(`/admin/industry/${industryId}`)
        .then(response => {
          if (response.data.status === 200 && response.data.data) {
            commit('SINGLE_INDUSTRY', response.data.data)
          }
        })
    }
  },
  mutations: {
    INDUSTRY_LIST (state, payload) {
      state.industryList = payload
    },
    ALL_INDUSTRY_LIST (state, payload) {
      state.allIndustryList = payload
    },
    SINGLE_INDUSTRY (state, payload) {
      state.industryDetails = payload.length ? payload[0] : null
    },
    COUNT (state, payload) {
      state.totalCount = payload
    },
    ALL_INDUSTRY_IDS (state, payload) {
      state.allIndustryIds = payload
    }
  }
}
