<template>
  <div id="app">
    <div class="loader-wrapper" v-if="loader || fullPageLoader">
      <div class="inava-loader"></div>
    </div>
    <div class="custom-tooltip bottom-arrow" id="common-tooltip"></div>
    <div class="mltooltip bottom-arrow"></div>
    <router-view />
    <cookies />
  </div>
</template>

<script>
import Cookies from './components/Cookies/Cookies.vue'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'App',
  components: { Cookies },
  computed: {
    ...mapState('adminCommon', ['loader']),
    ...mapState('common', ['currentModule', 'fullPageLoader'])
  },
  watch: {
    $route (to, from) {
      this.updateCurrentModule(to.meta.module)
      this.updateActivePage(to.name)
    },
    currentModule () {
      const htmlElement = document.documentElement
      htmlElement.setAttribute('module', this.currentModule)

      if (this.currentModule === 'voc' || this.currentModule === 'tam' || this.currentModule === 'settings' || this.currentModule === 'admin' || this.currentModule === 'esg-controversies' || this.currentModule === 'esg-daas' || this.currentModule === 'reports-repository' || this.currentModule === 'playbook' || this.currentModule === 'investment-insight' || this.currentModule === 'data-management-platform') {
        import('./assets/css/voc.scss')
      } else if (this.currentModule === 'voe') {
        import('./assets/css/voe.scss')
      }
    }
  },
  mounted () {
    this.updateCurrentModule(this.$route.meta.module)
    this.updateActivePage(this.$route.name)
  },
  methods: {
    ...mapActions('common', ['updateCurrentModule', 'updateActivePage'])
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

#app {
  font-family: 'Quicksand' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
