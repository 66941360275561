export default {
  namespaced: true,
  state: {
    tableSelectionState: {
      organization: {
        limit: 10,
        currentPage: 1
      },
      user: {
        limit: 10,
        currentPage: 1
      },
      subscription: {
        limit: 10,
        currentPage: 1
      },
      category: {
        limit: 10,
        currentPage: 1
      },
      products: {
        limit: 10,
        currentPage: 1
      },
      dataSource: {
        limit: 10,
        currentPage: 1
      },
      topic: {
        limit: 10,
        currentPage: 1
      },
      companySize: {
        limit: 10,
        currentPage: 1
      },
      industries: {
        limit: 10,
        currentPage: 1
      },
      jobs: {
        limit: 10,
        currentPage: 1
      },
      role: {
        limit: 10,
        currentPage: 1
      },
      feature: {
        limit: 10,
        currentPage: 1
      },
      permission: {
        limit: 10,
        currentPage: 1
      },
      company: {
        limit: 10,
        currentPage: 1
      }
    },
    loader: false
  },
  actions: {
    updateTableSelectionState ({ commit }, value) {
      commit('TABLE_SELECTION_STATE', value)
    },
    toggleLoaderState({ commit }) {
      commit('TOGGLE_LOADER_STATE')
    }
  },
  mutations: {
    TABLE_SELECTION_STATE (state, payload) {
      state.tableSelectionState = payload
    },
    TOGGLE_LOADER_STATE (state) {
      state.loader = !state.loader
    }
  }
}
