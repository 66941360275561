import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    exceptionHandlingList: [],
    exceptionHandlingDuplicates: []
  },
  actions: {
    async getExceptionHandling ({ commit }) {
      try {
        const response = await Vue.prototype.$http.get('dmp/exception-handling')
        if (response.data.status === 200 && response.data.data) {
          commit('SET_EXCEPTION_HANDLING', response.data.data)
        } else if (response.data.status === 204) {
          // TODO: Decide what action to take for status 204
        }
      } catch (error) {
        // Handle any errors here
      }
    },
    async getExceptionHandlingDuplicates ({ commit }, sgaId) {
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/exception-handling/duplicates?sgaId=${sgaId}`
        )
        if (response.data.status === 200 && response.data.data) {
          commit('SET_EXCEPTION_HANDLING_DUPLICATES', response.data.data)
        } else {
          // If API data is null or the status is not 200, set an empty array
          commit('SET_EXCEPTION_HANDLING_DUPLICATES', [])
        }
      } catch (error) {
        // Handle any errors here
      }
    },
    async updateMasterEntity ({ commit }, updateMasterPayload) {
      try {
        const response = await Vue.prototype.$http.put(
          '/dmp/exception-handling/master-selection',
          updateMasterPayload
        )
        return response.data.status === 200 && response.data.data > 0
      } catch (error) {
        throw new Error(error)
      }
    },
    async updateDuplicateEntity ({ commit }, updateDuplicatePayload) {
      try {
        const response = await Vue.prototype.$http.put(
          '/dmp/exception-handling/duplicate-selection',
          updateDuplicatePayload
        )
        return response.data.status === 200 && response.data.data > 0
      } catch (error) {
        throw new Error(error)
      }
    },
    async processAllDuplicates ({ commit }, duplicateBaseSGAID) {
      try {
        const response = await Vue.prototype.$http.put(
          `/dmp/exception-handling/duplicate-processed?duplicateBaseSGAID=${duplicateBaseSGAID}`
        )
        return response.data.status === 200 && response.data.data > 0
      } catch (error) {
        throw new Error(error)
      }
    },
    async resetProcessedEntity ({ commit }, resetProcessedEntityPayload) {
      try {
        const response = await Vue.prototype.$http.put(
          '/dmp/exception-handling/clear-processed', resetProcessedEntityPayload
        )
        return response.data.status === 200 && response.data.data > 0
      } catch (error) {
        throw new Error(error)
      }
    },
    async updateInvalidEntity ({ commit }, { updateInvalidPayload, sgaId }) {
      try {
        const response = await Vue.prototype.$http.put(
          `/dmp/exception-handling/update-invalid-entity?sgaId=${sgaId}`,
          updateInvalidPayload
        )
        return response.data.status === 200 && response.data.data > 0
      } catch (error) {
        throw new Error(error)
      }
    }
  },

  mutations: {
    SET_EXCEPTION_HANDLING (state, exceptionHandling) {
      state.exceptionHandlingList = exceptionHandling
    },
    SET_EXCEPTION_HANDLING_DUPLICATES (state, exceptionHandling) {
      const exceptionHandlingWithSelected = exceptionHandling?.map(
        (item, index) => ({
          ...item,
          selected: false,
          isMaster: item?.masterEntity === item.entityId,
          isDuplicate: item?.isEntityProcessed === 'true'
        })
      )

      state.exceptionHandlingDuplicates = exceptionHandlingWithSelected
    }
  }
}
