import Vue from 'vue'

export default {
  namespaced: true,
  actions: {
    /** This method submits the contents of the feedback form */
    postFeedback ({ commit }, value) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post('/feedback/', value).then((response) => {
          if (response.status === 200) {
            resolve(response.status)
          }
        })
      })
    }
  }
}
