import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { http, http2, axiosS3Client } from './store/axios'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import Meta from 'vue-meta'
import Vuelidate from 'vuelidate'
import './App.scss'
import { interceptor } from './util/interceptor'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
Vue.component('v-select', vSelect)
Vue.use(Vuelidate)
Vue.use(Meta)
Vue.use(Buefy)
Vue.config.productionTip = false
Vue.prototype.$http = http
Vue.prototype.$http2 = http2
Vue.prototype.$axiosS3Client = axiosS3Client
interceptor(http)
interceptor(http2)

const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export { vm }
