import Vue from 'vue'
import Snackbar from '../components/Snackbar'
import { vm } from '../main'
import store from '../store/index'
import { clearStorage } from '../util/util'

export default {
  namespaced: true,
  actions: {
    /** This method verifies the authorization token. If valid, it sets an access-token and a refresh token as well (if a refresh token is present). It also saves this info along with the info of the logged in user to localStorage. If the user has set their preferences for the site, those are imported into the vuex store. The user is then redirected to the main VoC Summary page.
     *
     * If verification fails due to a forbidden request (error 403), localStorage is cleared and the user is redirected to the login page.
     */
    verifyAuthToken ({ commit }, value) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post('/token/auth', value).then((res) => {
          if (res.status === 200) {
            window.localStorage.setItem('access-token', 'Bearer ' + res.data.data.token)
            if (res.data.status === 200) {
              window.localStorage.setItem('refresh-token', res.data.data.refreshToken)
              localStorage.setItem('user', JSON.stringify(res.data.data.user))
              if (res.data.data.user.preferences) {
                commit('UPDATE_USER_PREFERENCE', res.data.data.user.preferences.preference_mode)
              }
              vm.$router.replace(
                res.data.data.user.modules.find(
                  (m) => m.module_name !== 'settings'
                ).module_name
              )
            } else if (res.data.status === 201) {
              vm.$router.replace('/profile-setup')
            } else if (res.data.status === 403) {
              clearStorage(false)
              vm.$router.replace('/')
              setTimeout(() => {
                Snackbar({ message: 'Your subscription has expired. Please contact the Administrator.', type: 'is-danger' })
              }, 1000)
            }
          }
        })
      })
    }
  },
  mutations: {
    UPDATE_USER_PREFERENCE (state, payload) {
      store.state.common.user_preference_mode = payload
    }
  }
}
