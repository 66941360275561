import Vue from 'vue'
import Snackbar from '../../components/Snackbar'
import { vm } from '../../main'
import store from '../../store/index'
import { clearStorage } from '../../util/util'

export default {
  namespaced: true,
  state: {
    userList: []
  },
  actions: {
    sendInvitation ({ commit }, object) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post('/invitation', object).then((res) => {
          if (res.status === 200) {
            if (res.data.data.notAlowedEmail.length) {
              Snackbar({
                message:
                  'Email: ' +
                  res.data.data.notAlowedEmail +
                  ' domain not allowed by Admin.',
                type: 'is-danger'
              })
              resolve(false)
            }
            if (res.data.data.existEmails.length) {
              if (res.data.data.existEmails.length === 1) {
                Snackbar({
                  message: `Email: ${res.data.data.existEmails[0]} already exists.`,
                  type: 'is-danger'
                })
              } else {
                Snackbar({
                  message: `Email: ${res.data.data.existEmails[0]} and ${
                    res.data.data.existEmails.length - 1
                  } ${
                    res.data.data.existEmails.length === 2 ? 'other' : 'others'
                  } already exist.`,
                  type: 'is-danger'
                })
              }
              resolve(false)
            }
            if (res.data.data.sentEmails.length) {
              Snackbar({
                message:
                  res.data.data.sentEmails.length +
                  (res.data.data.sentEmails.length > 1
                    ? ' Invitations'
                    : ' Invitation') +
                  ' sent successfully.',
                type: 'is-success'
              })
              resolve(true)
            }
          }
        })
      })
    },
    verifyInvitationToken ({ commit }, object) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/invitation/verify/token', object)
          .then((res) => {
            if (res.status === 200) {
              resolve(res.data.status)
            }
          })
      })
    },
    getInvitedUsers ({ commit }, object) {
      Vue.prototype.$http.post('/invitation/user', object).then((res) => {
        if (res.status === 200) {
          commit('USER_LIST', res.data.data)
        }
      })
    },
    reInviteUser ({ commit }, userId) {
      Vue.prototype.$http.get(`/invitation/re-invite/${userId}`).then((res) => {
        if (res.status === 200) {
          if (res.data.status === 200) {
            Snackbar({
              message: 'Invitation sent successfully.',
              type: 'is-success'
            })
          }
        }
      })
    },
    removeUser ({ commit }, userId) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.get(`/invitation/remove/${userId}`).then((res) => {
          if (res.status === 200) {
            if (res.data.status === 200) {
              Snackbar({ message: 'Removed successfully.', type: 'is-success' })
              resolve(true)
            }
          }
        })
      })
    },
    updateUserRole ({ commit }, object) {
      Vue.prototype.$http.put('/invitation/user/role', object).then((res) => {
        if (res.status === 200) {
          if (res.data.status === 200) {
            Snackbar({ message: 'Role updated.', type: 'is-success' })
          }
        }
      })
    },
    addInvitedUserDetails ({ commit }, object) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.put('/invitation', object).then((res) => {
          if (res.status === 200) {
            if (res.data.status === 200) {
              window.localStorage.setItem(
                'access-token',
                'Bearer ' + res.data.data.token
              )
              window.localStorage.setItem(
                'refresh-token',
                res.data.data.refreshToken
              )
              localStorage.setItem('user', JSON.stringify(res.data.data.user))
              if (res.data.data.user.preferences) {
                commit(
                  'UPDATE_USER_PREFERENCE',
                  res.data.data.user.preferences.preference_mode
                )
              }
              vm.$router.replace(
                res.data.data.user.modules.find(
                  (m) => m.module_name !== 'settings'
                ).module_name
              )
            } else if (res.data.status === 403) {
              clearStorage(false)
              vm.$router.replace('/')
              setTimeout(() => {
                Snackbar({
                  message:
                    'Your subscription has expired. Please contact the Administrator.',
                  type: 'is-danger'
                })
              }, 1000)
            }
          }
        })
      })
    }
  },
  mutations: {
    USER_LIST (state, payload) {
      state.userList = []
      state.userList = payload
    },
    UPDATE_USER_PREFERENCE (state, payload) {
      store.state.common.user_preference_mode = payload
    }
  }
}
