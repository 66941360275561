import Vue from 'vue'

function fixBinary (bin) {
  const length = bin.length
  const buf = new ArrayBuffer(length)
  const arr = new Uint8Array(buf)
  for (let i = 0; i < length; i++) {
    arr[i] = bin.charCodeAt(i)
  }
  return buf
}

export default {
  namespaced: true,
  state: {
    storeEvidences: new Map(), // list of evidences
    evidenceIdList: [], // track which evidence form is already filled
    isFileSizeValid: true, // flag for each file size
    isMaxNoOfFileValid: true, // flag for each file length
    selectedUploadFiles: [], // store upload files
    sourceUrl: '', // for individual evidence
    docRefUrl: '', // for individual evidence
    isEvidenceValid: false, // for individual evidence
    tempS3data: [], // store temporary s3 evidence form data
    btnLoading: {}, // object contains status for individual btn
    selectedUploadFilesId: [], // store upload files id (only which come from view evidence api)
    filesIdToBeDeleted: [], // id that needs to be deleted from db
    isChangesMadeInForm: false,
    isBVD: false
  },

  getters: {
    /* returns true if form is filled */
    getEvidenceFormStatus: (state) => (evidenceId) => {
      return state.evidenceIdList.includes(evidenceId)
    },

    /* returns copy of selectedUploadedFiles */
    getSelectedUploadedFiles: (state) => {
      const currentSelectedFiles = [...state.selectedUploadFiles] // copy using spread operator
      return currentSelectedFiles
    },

    getBtnLoadingStatus: (state) => (evidenceId) => {
      return state.btnLoading[evidenceId]
    },

    // check whether evidence details is already present
    isEvidencePresent: (state) => (evidenceId) => {
      return !!state.storeEvidences.get(evidenceId)
    }
  }, // end getters

  actions: {
    addEvidence ({ commit, state }, { evidence, fromView = false }) {
      commit('SET_STORE_EVIDENCES', evidence)
      commit('SET_EVIDENCE_ID_LIST', evidence.evidenceId)
      commit('SET_BTN_LOADING', {
        evidenceId: evidence.evidenceId,
        isLoading: true
      })

      const { evidenceId, sourceUrl, docRefUrl, files } = evidence
      let data = {}

      if (!fromView) {
        // convert all the images to base64 images then
        // make an api call to server
        Promise.all(
          files.map(
            (image) =>
              new Promise((resolve, reject) => {
                const fileReader = new FileReader()

                fileReader.onload = (file) => {
                  resolve({
                    imageName: image.name,
                    imageData: file.target.result
                  })
                }

                fileReader.onerror = (error) => reject(error)

                fileReader.readAsDataURL(image)
              })
          )
        ).then((base64Images) => {
          data = {
            attributeName: evidenceId,
            sourceUrl,
            docRefUrl,
            files: base64Images
          }

          // temporary upload to S3 bucket and
          // it return temporary S3 file links with evidence form data
          Vue.prototype.$http
            .post('/dmp/evidence/upload', data)
            .then((response) => {
              if (response.data.status === 200) {
                if (response.data && response.data.data) {
                  commit('SET_TEMP_S3_DATA', response.data.data)
                  commit('SET_BTN_LOADING', {
                    evidenceId: evidence.evidenceId,
                    isLoading: false
                  })
                }
              }
            })
            .catch((e) => {
              commit('SET_BTN_LOADING', {
                evidenceId: evidence.evidenceId,
                isLoading: false
              })
            })
        }) // end of then
      }
    },

    getEvidenceById ({ commit, state }, evidenceId) {
      const evidence = state.storeEvidences.get(evidenceId)
      if (evidence) {
        commit('SET_SOURCE_URL', evidence.sourceUrl)
        commit('SET_DOC_REF_URL', evidence.docRefUrl)
        commit('SET_SELECTED_UPLOAD_FILES', evidence.files)
        commit('SET_IS_FILE_SIZE_VALID', true)
        commit('SET_IS_MAX_NO_OF_FILE_VALID', true)
        commit('SET_IS_EVIDENCE_VALID', true)
      }
    },

    updateIsFileSizeValid ({ commit }, isValidSize) {
      commit('SET_IS_FILE_SIZE_VALID', isValidSize)
    },

    updateIsMaxNoOfFileValid ({ commit }, isValidLength) {
      commit('SET_IS_MAX_NO_OF_FILE_VALID', isValidLength)
    },

    updateSelectedUploadFiles ({ commit, state }, { uploadedFiles, formName }) {
      // only relationship form can have upto 5 files
      const MAX_NO_FILES = formName === 'RelationshipsForm' ? 5 : 1

      // only checks for file validation
      if (uploadedFiles) {
        if (uploadedFiles.length <= MAX_NO_FILES) {
          // validating the size of the file should be less than or equal to 1Mb
          const FILESIZE_MAX = 1 * 1024 * 1024

          uploadedFiles.forEach((file) => {
            if (file.size <= FILESIZE_MAX) {
              commit('SET_IS_FILE_SIZE_VALID', true)
            } else {
              commit('SET_IS_FILE_SIZE_VALID', false)
              // show error that file is greater than 1Mb for 3sec
              setTimeout(() => {
                commit('SET_IS_FILE_SIZE_VALID', true)
              }, 3000)
              commit('SET_IS_MAX_NO_OF_FILE_VALID', true)
              commit('SET_SELECTED_UPLOAD_FILES', state.selectedUploadFiles) // setting to previous list of uploaded files
            }
          })
        } else {
          commit('SET_IS_FILE_SIZE_VALID', true)
          commit('SET_IS_MAX_NO_OF_FILE_VALID', false)
          // file length error is only visible for 3sec
          setTimeout(() => {
            commit('SET_IS_MAX_NO_OF_FILE_VALID', true)
          }, 3000)
          commit('SET_SELECTED_UPLOAD_FILES', state.selectedUploadFiles) // setting to previous list of uploaded files
        }
      }

      // filtering unique file by name
      const uniqueUploadedFiles = [
        ...new Map(uploadedFiles.map((file) => [file.name, file])).values()
      ]

      if (state.isMaxNoOfFileValid && state.isFileSizeValid) {
        commit('SET_SELECTED_UPLOAD_FILES', uniqueUploadedFiles)
        commit('SET_IS_EVIDENCE_VALID', true)
      }
    },

    deleteFileByIndex ({ commit, state }, fileIndex) {
      if (state.selectedUploadFilesId[fileIndex]) {
        commit(
          'SET_FILES_ID_TO_BE_DELETED',
          state.selectedUploadFilesId[fileIndex]
        )
      }
      commit('REMOVE_SELECTED_UPLOAD_FILES_BY_INDEX', fileIndex)
      commit('REMOVE_SELECTED_UPLOAD_FILES_ID', fileIndex)
    },

    updateSourceUrl ({ commit }, sourceUrl) {
      commit('SET_SOURCE_URL', sourceUrl)
      commit('SET_IS_EVIDENCE_VALID', true)
    },

    updateDocRefUrl ({ commit }, docRefUrl) {
      commit('SET_DOC_REF_URL', docRefUrl)
      commit('SET_IS_EVIDENCE_VALID', true)
    },

    updateIsEvidenceValid ({ commit }, isValid) {
      commit('SET_IS_EVIDENCE_VALID', isValid)
    },

    resetEvidenceForm ({ commit }) {
      commit('SET_SOURCE_URL', '')
      commit('SET_DOC_REF_URL', '')
      commit('SET_SELECTED_UPLOAD_FILES', [])
      commit('SET_IS_FILE_SIZE_VALID', true)
      commit('SET_IS_MAX_NO_OF_FILE_VALID', true)
      commit('SET_IS_EVIDENCE_VALID', false)
    },

    /**
     * It is to upload evidence data and
     * returns a promise that resolves with the evidenceId mapping
     * if the request is successful.
     * @returns a Promise.
     */
    uploadEvidence ({ commit, state }) {
      const data = {
        tempS3data: state.tempS3data
      }
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/dmp/evidence/final-upload', data)
          .then((response) => {
            if (response.data && response.data.status === 200) {
              if (response.data.data) {
                resolve(response.data.data)
              }
            } else if (response.data && response.data.status === 400) {
              resolve({ success: false, info: 'Please attach evidence' })
            } else {
              resolve({ success: false, info: 'Something went wrong' })
            }
          })
          .catch((e) => {
            reject(e)
          })
      })
    },

    // resetting every state except storeEvidences,evidenceIdList
    // these two maintain state over switching forms
    // btnLoading don't need resetting
    resetEvidenceStore ({ dispatch, commit, state }) {
      dispatch('resetEvidenceForm')
      commit('SET_TEMP_S3_DATA', [])
      commit('SET_FILES_ID_TO_BE_DELETED', [])
      commit('SET_EVIDENCE_ID_LIST', [])
      commit('SET_STORE_EVIDENCES', null)
    },

    removeEvidence ({ commit }, evidenceId) {
      commit('REMOVE_STORE_EVIDENCES', evidenceId)
      commit('SET_SOURCE_URL', '')
      commit('SET_DOC_REF_URL', '')
      commit('SET_SELECTED_UPLOAD_FILES', [])
      commit('REMOVE_EVIDENCE_ID_LIST', evidenceId)
      commit('REMOVE_TEMP_S3_DATA', evidenceId)
    },

    async viewEvidence ({ dispatch, commit }, { inputName, evidenceIds }) {
      const reqData = {
        evidenceIds: evidenceIds
      }

      commit('SET_BTN_LOADING', {
        evidenceId: inputName,
        isLoading: true
      })

      try {
        const response = await Vue.prototype.$http.post(
          '/dmp/evidence/view',
          reqData
        )
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            const { sourceUrl, docRefUrl, evidenceFiles } = response.data.data
            const fileArr = []
            const fileIdArr = []
            for (const evidenceFile of evidenceFiles) {
              const base64 = evidenceFile.file // base64 file
              const binary = fixBinary(atob(base64)) // convert to binary
              const blob = new Blob([binary], { type: 'image/jpeg' }) // make a blob
              const file = new File([blob], evidenceFile.fileName) // make a file
              fileArr.push(file)
              fileIdArr.push(evidenceFile.fileId)
            }
            const evidence = {
              sourceUrl,
              docRefUrl,
              files: fileArr,
              evidenceId: inputName
            }
            await dispatch('addEvidence', { evidence, fromView: true })
            commit('SET_BTN_LOADING', {
              evidenceId: inputName,
              isLoading: false
            })
            return true
          }
        }
      } catch (err) {
        commit('SET_BTN_LOADING', {
          evidenceId: inputName,
          isLoading: false
        })
        throw new Error(err)
      }
    },

    async deleteEvidence ({ commit, state }) {
      const reqData = {
        evidenceIds: state.filesIdToBeDeleted
      }

      try {
        const response = await Vue.prototype.$http.post(
          '/dmp/evidence/delete',
          reqData
        )
        if (response.data.status === 200) {
          return
        }
      } catch (err) {
        throw new Error(err)
      }
    },

    resetFilesIdToBeDeleted ({ commit }) {
      commit('SET_FILES_ID_TO_BE_DELETED', [])
    },

    updateEvidenceIdList ({ commit }, evidenceId) {
      commit('SET_EVIDENCE_ID_LIST', evidenceId)
    },

    updateSelectedUploadFilesId ({ commit }, fileIdArr) {
      commit('SET_SELECTED_UPLOAD_FILES_ID', fileIdArr)
    },

    updateChangesInEvidenceFormFlag({ commit }, value) {
      commit('SET_CHANGES_IN_EVIDENCE_FORM', value);
    },

    updateIsBVD({ commit }, isBvd) {
      commit('SET_IS_BVD', isBvd)  
    },

    async checkBVDEvidence({ dispatch, commit }, {inputName, bvdId, updatedAt}) {
      const bvdEvidenceName = `BVD_EVIDENCE_${bvdId}-${updatedAt}.png`
      commit('SET_BTN_LOADING', {
        evidenceId: inputName,
        isLoading: true
      })
      const reqData = {
        attributeName: inputName,
        evidenceName: bvdEvidenceName
      }
      try {
        const response = await Vue.prototype.$http.post(
          '/dmp/evidence/bvd-evidence',
          reqData
        )
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            const { sourceUrl, docRefUrl, evidenceFiles } = response.data.data
            const fileArr = []
            const fileIdArr = []
            for (const evidenceFile of evidenceFiles) {
              const base64 = evidenceFile.file // base64 file
              const binary = fixBinary(atob(base64)) // convert to binary
              const blob = new Blob([binary], { type: 'image/jpeg' }) // make a blob
              const file = new File([blob], evidenceFile.fileName) // make a file
              fileArr.push(file)
              fileIdArr.push(evidenceFile.fileId)
            }
            const evidence = {
              sourceUrl,
              docRefUrl,
              files: fileArr,
              evidenceId: inputName
            }
            await dispatch('addEvidence', { evidence, fromView: true })
            commit('SET_BTN_LOADING', {
              evidenceId: inputName,
              isLoading: false
            })
            return fileIdArr[0]
          }
        }
      } catch (err) {
        throw new Error(err)
      }
    }
  }, // end actions

  mutations: {
    SET_STORE_EVIDENCES (state, evidence) {
      if (evidence === null) {
        state.storeEvidences = new Map()
      } else {
        state.storeEvidences.set(evidence.evidenceId, evidence)
      }
    },

    REMOVE_STORE_EVIDENCES (state, evidenceId) {
      state.storeEvidences.delete(evidenceId)
    },

    SET_EVIDENCE_ID_LIST (state, evidenceId) {
      if (evidenceId?.length === 0) {
        state.evidenceIdList = []
      } else {
        const evidenceIdSet = new Set([...state.evidenceIdList, evidenceId])
        state.evidenceIdList = [...evidenceIdSet]
      }
    },

    SET_CHANGES_IN_EVIDENCE_FORM(state, value) {
      state.isChangesMadeInForm = value;
    },

    REMOVE_EVIDENCE_ID_LIST (state, evidenceId) {
      const index = state.evidenceIdList.indexOf(evidenceId)
      // only splice array when item is found
      if (index > -1) {
        state.evidenceIdList.splice(index, 1)
      }
    },

    SET_IS_FILE_SIZE_VALID (state, isValidSize) {
      state.isFileSizeValid = isValidSize
    },

    SET_IS_MAX_NO_OF_FILE_VALID (state, isValidFileNumber) {
      state.isMaxNoOfFileValid = isValidFileNumber
    },

    SET_SELECTED_UPLOAD_FILES (state, uploadedFiles) {
      state.selectedUploadFiles = [...uploadedFiles] // copy using spread operator
    },

    REMOVE_SELECTED_UPLOAD_FILES_BY_INDEX (state, fileIndex) {
      const copySelectedUploadFiles = [...state.selectedUploadFiles]
      copySelectedUploadFiles.splice(fileIndex, 1)
      state.selectedUploadFiles = copySelectedUploadFiles
    },

    SET_SOURCE_URL (state, sourceUrl) {
      state.sourceUrl = sourceUrl
    },

    SET_DOC_REF_URL (state, docRefUrl) {
      state.docRefUrl = docRefUrl
    },

    SET_IS_EVIDENCE_VALID (state, isEvidenceValid) {
      state.isEvidenceValid = isEvidenceValid
    },

    SET_TEMP_S3_DATA (state, tempS3data) {
      if (Array.isArray(tempS3data) && tempS3data.length === 0) {
        state.tempS3data.length = 0
      } else {
        state.tempS3data.push(tempS3data)
      }
    },

    REMOVE_TEMP_S3_DATA (state, evidenceId) {
      state.tempS3data = state.tempS3data.filter((s3data) => {
        return s3data.attributeName !== evidenceId
      })
    },

    SET_BTN_LOADING (state, { evidenceId, isLoading }) {
      Vue.set(state.btnLoading, evidenceId, isLoading) // using Vue.set to maintain the reactivity
    },

    SET_SELECTED_UPLOAD_FILES_ID (state, uploadedFilesId) {
      state.selectedUploadFilesId = [...uploadedFilesId] // copy using spread operator
    },

    REMOVE_SELECTED_UPLOAD_FILES_ID (state, fileIndex) {
      const copySelectedUploadFilesId = [...state.selectedUploadFilesId]
      copySelectedUploadFilesId.splice(fileIndex, 1)
      state.selectedUploadFilesId = copySelectedUploadFilesId
    },

    SET_FILES_ID_TO_BE_DELETED (state, fileId) {
      if (Array.isArray(fileId) && fileId.length === 0) {
        state.filesIdToBeDeleted.length = 0
      } else {
        const uniqueFilesId = new Set([...state.filesIdToBeDeleted, fileId])
        state.filesIdToBeDeleted = [...uniqueFilesId]
      }
    },

    SET_IS_BVD (state, isBVD) {
      state.isBVD = isBVD
    }
  } // end mutations
}
