<template>
  <div v-if="open" :class="['cookies-container']">
      <p>This website uses cookies to offer you the most relevant information. Please accept for optimal performance.
      </p>
      <b-button @click="closeCookies">Accept</b-button>
  </div>
</template>

<script>

/** This component displays the 'Accept Cookies' message on the sign in page */
export default {
  name: 'CookiesComponent',
  data () {
    return {
      open: false,
      window: window
    }
  },
  mounted () {
    if (JSON.parse(localStorage.getItem('cookies')) == null) {
      this.open = true
    }
  },
  methods: {
    /** This method closes the 'Accept Cookies' component */
    closeCookies () {
      this.open = false
      localStorage.setItem('cookies', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.cookies-container {
    position: absolute;
    box-shadow: 5px 10px 25px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    left: 50%;
    padding: 20px;
    width: 70%;
    transform: translateX(-50%);
    border-radius: 4px;
    transition: 3s all;
    animation-name: cookies;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        font-family: 'roboto';
        padding-right: 20px;
    }
    p:nth-child(1){
        font-size: 12px;
    }
    button {
        color: white;
        outline: none;
        box-shadow: none;
        background: #5aa4ee;
        font-family: 'roboto';
        padding: 0px;
        border: none;
        padding: 0px 5px;
        height: 30px;
        float: right;
    }
}
@keyframes cookies {
  from {bottom: -100px; opacity: 0px;}
  to {bottom: 20px; opacity: 1px;}
}
</style>
