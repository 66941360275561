import Vue from 'vue'

const getDefaultState = () => {
  return {
    identifiersDetails: {},
    identifiersTaxTypes: [],
    bvdIdentifiersDetails: {}
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async getIdentifiersDetails({ commit }, sgaId) {
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/identifiers?sgaId=${sgaId}`
        )

        if (response?.data && response.data?.status === 200) {
          if (response.data?.data) {
            commit('SET_IDENTIFIERS_DETAILS', response.data.data)
          }
        } else {
          // Reset store
          commit('SET_IDENTIFIERS_DETAILS', {})
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    async updateIdentifiersDetails({ commit }, updateIdentifiersDetails) {
      try {
        const { sgaId, identifiersFormFields } = updateIdentifiersDetails

        const response = await Vue.prototype.$http.put(
          `/dmp/identifiers?sgaId=${sgaId}`,
          identifiersFormFields
        )

        if (response?.data && response.data?.status === 200) {
          if (response.data?.data) {
            commit('SET_IDENTIFIERS_DETAILS', response.data.data)
          }
        }
        // Returned response for status of submission
        return response?.data?.status
      } catch (error) {
        throw new Error(error)
      }
    },
    async getIdentifiersTaxTypes({ commit }, countryIdList = []) {
      try {
        commit('SET_IDENTIFIER_TAX_TYPES', [])
        const response = await Vue.prototype.$http.get(
          `/dmp/identifiers/tax-type?countryIds=${countryIdList.join(
            '&countryIds='
          )}`
        )
        if (response?.data && response.data?.status === 200) {
          if (response.data?.data) {
            commit('SET_IDENTIFIER_TAX_TYPES', response.data.data)
          }
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    resetIdentifiersDetails({ commit }) {
      commit('SET_IDENTIFIERS_DETAILS', {})
    },

    patchIdentifiersDetails({ commit }, bvdIdentifiersDetails) {
      commit('SET_BVD_IDENTIFIERS_DETAILS', bvdIdentifiersDetails)
    }
  },
  mutations: {
    SET_IDENTIFIERS_DETAILS(state, identifiersDetails) {
      state.identifiersDetails = { ...identifiersDetails }
    },
    SET_IDENTIFIER_TAX_TYPES(state, taxTypes) {
      state.identifiersTaxTypes = taxTypes.map((option) => {
        const taxTypesObj = {}
        taxTypesObj.value = option.taxTypeId
        taxTypesObj.text = `${option.taxTypeCode} - ${option.taxType}`
        return taxTypesObj
      })
    },
    SET_BVD_IDENTIFIERS_DETAILS(state, bvdIdentifiersDetails) {
      state.bvdIdentifiersDetails = { ...bvdIdentifiersDetails }
    }
  }
}
