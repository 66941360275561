import Vue from 'vue'
import Snackbar from '../components/Snackbar'
import { clearStorage } from './util'

export function interceptor (http) {
  http.interceptors.request.use((config) => {
    config.headers.Authorization = window.localStorage.getItem('access-token')
    return config
  })
  http.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      if (error.message === 'Request canceled due to new request.') {
        return Promise.reject(error)
      }
      const {
        response: { status }
      } = error
      if (status === 401) {
        if (error.response.data.info === 'token expired') {
          return resetTokenAndReattemptRequest(error)
        } else {
          clearStorage(true)
        }
      }
      return Promise.reject(error)
    }
  )

  let isAlreadyFetchingAccessToken = false
  let subscribers = []

  async function resetTokenAndReattemptRequest (error) {
    try {
      const { response: errorResponse } = error
      const refreshToken = window.localStorage.getItem('refresh-token')
      if (!refreshToken) {
        clearStorage(true)
      }
      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((accessToken) => {
          errorResponse.config.headers.Authorization = 'Bearer ' + accessToken
          resolve(http(errorResponse.config))
        })
      })
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true
        try {
          const response = await Vue.prototype.$http.post('/token', {
            token: refreshToken
          })
          const newToken = response.data.token
          window.localStorage.setItem(
            'access-token',
            'Bearer ' + response.data.token
          )
          window.localStorage.setItem(
            'refresh-token',
            response.data.refreshToken
          )
          isAlreadyFetchingAccessToken = false
          onAccessTokenFetched(newToken)
        } catch (err) {
          clearStorage(true)
        }
      }
      return retryOriginalRequest
    } catch (err) {
      return Promise.reject(err)
    }
  }

  function onAccessTokenFetched (accessToken) {
    subscribers.forEach((callback) => callback(accessToken))
    subscribers = []
  }

  function addSubscriber (callback) {
    subscribers.push(callback)
  }
}
