import Vue from 'vue'
import store from '../store/index'

const getDefaultWinLossState = () => {
  return {
    wlLeftProductData: [],
    wlRightProductData: [],
    wlSelectedProductLeft: {},
    wlSelectedProductRight: {},
    attributeDataLeft: [],
    attributeDataRight: [],
    competitivePositioning: [],
    categoryAvg: [],
    wlLeftStrengths: [],
    wlLeftWeaknesses: [],
    wlRightStrengths: [],
    wlRightWeaknesses: []
  }
}

export default {
  namespaced: true,
  state: getDefaultWinLossState(),
  actions: {
    /** This method fetches the data of the first product on the winLoss page (Competitive positioning page) from the database */
    getLeftWinLossData({ commit }, params) {
      commit('LOADING', true)
      Vue.prototype.$http
        .post('/winloss', params)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('WL_LEFT_PRODUCT_DATA', response.data.data)
            }
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          commit('LOADING', false)
        })
    },
    /** This method fetches the data of the second product on the winLoss page (Competitive positioning page) from the databse */
    getRightWinLossData({ commit }, params) {
      commit('LOADING', true)
      Vue.prototype.$http
        .post('/winloss', params)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('WL_RIGHT_PRODUCT_DATA', response.data.data)
            }
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          commit('LOADING', false)
        })
    },
    /** This method fetches the wordcloud data for the first product */
    getAttributeDataLeft({ commit }, params) {
      commit('LOADING', true)
      Vue.prototype.$http
        .post('/winloss/attributeData', params)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              const flattenedKwData = response.data.data.map((d) => {
                d.keywords = [...new Set(d.keywords.flat())]
                d.wlPosition = 'left'
                return d
              })
              const sortedFeatureArr = flattenedKwData // .sort(function (x, y) { return parseInt(y.new_c) - parseInt(x.new_c) })
              commit('ATTRIBUTE_DATA_LEFT', sortedFeatureArr)
            }
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          commit('LOADING', false)
        })
    },
    /** This method fetches the wordcloud data for the second product */
    getAttributeDataRight({ commit }, params) {
      commit('LOADING', true)
      Vue.prototype.$http
        .post('/winloss/attributeData', params)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              const flattenedKwData = response.data.data.map((d) => {
                d.keywords = [...new Set(d.keywords.flat())]
                d.wlPosition = 'right'
                return d
              })
              const sortedFeatureArr = flattenedKwData.sort(function (x, y) {
                return parseInt(y.new_c) - parseInt(x.new_c)
              })
              commit('ATTRIBUTE_DATA_RIGHT', sortedFeatureArr)
            }
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          commit('LOADING', false)
        })
    },
    /** This method fetches the bubble chart data for the winloss page */
    getCompetitivePositioning({ commit }, params) {
      commit('LOADING', true)
      Vue.prototype.$http
        .post('/winloss/competitivePositioning', params)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              let data = response.data.data
              commit('COMPETITIVE_POSITIONING', data.competitiveData)
              commit('CATEGORY_AVG', data.categoryAvg)
            }
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          commit('LOADING', false)
        })
    },
    /** This method updates the selected product for the first column */
    updateWlSelectedLeft({ commit }, value) {
      commit('WL_SELECTED_PRODUCT_LEFT', value)
    },
    /** This method updates the selected product for the second column */
    updateWlSelectedRight({ commit }, value) {
      commit('WL_SELECTED_PRODUCT_RIGHT', value)
    },
    /** This method updates the wordcloud data for the first column */
    updateAttributeDataLeft({ commit }, value) {
      commit('ATTRIBUTE_DATA_LEFT', value)
    },
    /** This method updates the wordcloud data for the second column */
    updateAttributeDataLeft({ commit }, value) {
      commit('ATTRIBUTE_DATA_RIGHT', value)
    },
    /** This method updates the gauge chart data for the first column strengths */
    updateWlLeftStrengths({ commit }, value) {
      commit('WL_LEFT_STRENGTHS', value)
    },
    /** This method updates the gauge chart data for the first column weaknesses */
    updateWlLeftWeaknesses({ commit }, value) {
      commit('WL_LEFT_WEAKNESSES', value)
    },
    /** This method updates the gauge chart data for the second column strengths */
    updateWlRightStrengths({ commit }, value) {
      commit('WL_RIGHT_STRENGTHS', value)
    },
    /** This method updates the gauge chart data for the second column weaknesses */
    updateWlRightWeaknesses({ commit }, value) {
      commit('WL_RIGHT_WEAKNESSES', value)
    }
  },
  mutations: {
    LOADING(state, payload) {
      store.state.filters.loading = payload
    },
    WL_LEFT_STRENGTHS(state, payload) {
      state.wlLeftStrengths = payload
    },
    WL_LEFT_WEAKNESSES(state, payload) {
      state.wlLeftWeaknesses = payload
    },
    WL_RIGHT_STRENGTHS(state, payload) {
      state.wlRightStrengths = payload
    },
    WL_RIGHT_WEAKNESSES(state, payload) {
      state.wlRightWeaknesses = payload
    },
    CATEGORY_AVG(state, payload) {
      state.categoryAvg = payload
    },
    COMPETITIVE_POSITIONING(state, payload) {
      state.competitivePositioning = payload
    },
    ATTRIBUTE_DATA_LEFT(state, payload) {
      state.attributeDataLeft = payload
    },
    ATTRIBUTE_DATA_RIGHT(state, payload) {
      state.attributeDataRight = payload
    },
    WL_SELECTED_PRODUCT_LEFT(state, payload) {
      state.wlSelectedProductLeft = payload
    },
    WL_SELECTED_PRODUCT_RIGHT(state, payload) {
      state.wlSelectedProductRight = payload
    },
    WL_LEFT_PRODUCT_DATA(state, payload) {
      state.wlLeftProductData = payload
    },
    WL_RIGHT_PRODUCT_DATA(state, payload) {
      state.wlRightProductData = payload
    }
  }
}
