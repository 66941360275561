import Vue from 'vue'
import store from '../store/index'

const getDefaultSunriseSunsetState = () => {
  return {
    jsonData: [],
    sharePerProduct: [],
    voeSharePerCompany: [],
    featuredTopicsData: [],
    selectedSSTopic: null,
    is_SSTstandard: null,
    allTopics: [],
    selectedAttribute: [],
    customSortedAttributes: [],
    polarSentimentAttributesOnly: false,
    open: true
  }
}

export default {
  namespaced: true,
  state: getDefaultSunriseSunsetState(),
  actions: {
    /** This method gets the data required to render the Horizonal Dot Plot */
    getDotPlotData ({ commit, state }, params) {
      commit('LOADING', true)
      Vue.prototype.$http.post('/sunriseSunset', params).then((response) => {
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            const allTopics = response.data.data.map(o => { return { id: o.topic_id, is_standard: o.is_standard } })
            commit('SET_DOT_PLOT_DATA', response.data.data)
            commit('ALL_TOPICS', allTopics)
          }
        } else {
          commit('SET_DOT_PLOT_DATA', [])
        }
        commit('LOADING', false)
      }).catch(e => {
        commit('LOADING', false)
      })
    },
    /** This method gets the data required to render the Horizontal Grouped bar chart on the Trending and Declining (SunriseSunset) page */
    getSharePerProductData ({ commit, state }, params) {
      Vue.prototype.$http.post('/sunriseSunset/sharePerProduct', params).then((response) => {
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_SHARE_PER_PRODUCT_DATA', response.data.data)
          }
        }
      }).catch(e => {
        commit('LOADING', false)
      })
    },
    /** This method returns the list of words for the wordmap */
    getFeaturedTopicsData ({ commit, state }, params) {
      Vue.prototype.$http.post('/sunriseSunset/featuredTopics', params).then((response) => {
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            const flattenedKwData = response.data.data.map(d => {
              d.keywords = [...new Set(d.keywords.flat())]
              return d
            })
            const sortedFeatureArr = flattenedKwData.sort(function (x, y) { return parseInt(y.new_c) - parseInt(x.new_c) })
            commit('SET_FEATURED_TOPICS_DATA', sortedFeatureArr)
            localStorage.setItem('sortedAttributeList', JSON.stringify(sortedFeatureArr))
            commit('CUSTOM_SORTED_ATTRIBUTES', sortedFeatureArr)
            // localStorage.setItem('selectedAttribute', JSON.stringify([]))
            // commit('SELECTED_ATTRIBUTE', [])
          }
        }
      }).catch(e => {
        commit('LOADING', false)
      })
    },
    getCustomSortedAttributes ({ commit, state }, params) {
      Vue.prototype.$http.post('/sunriseSunset/overAllAttributes', params).then((response) => {
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            const flattenedKwData = response.data.data.map(d => {
              d.keywords = [...new Set(d.keywords.flat())]
              return d
            })
            localStorage.setItem('sortedAttributeList', JSON.stringify(flattenedKwData))
            commit('CUSTOM_SORTED_ATTRIBUTES', flattenedKwData)
            // localStorage.setItem('selectedAttribute', JSON.stringify([]))
            // commit('SELECTED_ATTRIBUTE', [])
          }
        }
      }).catch(e => {
      })
    },
    /** This method gets the data required to render the Horizontal Grouped bar chart on the Trending and Declining (SunriseSunset) page */
    getVoESharePerCompanyData ({ commit, state }, params) {
      Vue.prototype.$http.post('/sunriseSunset/voeSharePerCompany', params).then((response) => {
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_VOE_SHARE_PER_COMPANY_DATA', response.data.data)
          }
        }
      }).catch(e => {
        commit('LOADING', false)
      })
    },
    updateSelectedSSTopic ({ commit }, value) {
      commit('SELECTED_SS_TOPIC', value)
    },
    updateIsSSTStandard ({ commit }, value) {
      commit('IS_SST_STANDARD', value)
    },
    updatecustomSortedAttributes ({ commit }, value) {
      localStorage.setItem('sortedAttributeList', JSON.stringify(value))
      commit('CUSTOM_SORTED_ATTRIBUTES', value)
    },
    updateSelectedAttribute ({ commit }, value) {
      localStorage.setItem('selectedAttribute', JSON.stringify(value))
      commit('SELECTED_ATTRIBUTE', value)
    },
    updatePolarSentimentAttributesOnly ({ commit }, value) {
      commit('POLAR_SENTIMENT_ATTRIBUTES_ONLY', value)
    },
    updateOpenStatus ({ commit }, isOpen) {
      commit('OPEN', isOpen)
    }
  },
  mutations: {
    POLAR_SENTIMENT_ATTRIBUTES_ONLY (state, value) {
      state.polarSentimentAttributesOnly = value
    },
    SELECTED_ATTRIBUTE (state, value) {
      state.selectedAttribute = value
    },
    CUSTOM_SORTED_ATTRIBUTES (state, payload) {
      if (state.open) {
        state.customSortedAttributes = payload
      } else {
        state.customSortedAttributes = []
      }
    },
    ALL_TOPICS (state, payload) {
      state.allTopics = payload
    },
    IS_SST_STANDARD (state, payload) {
      state.is_SSTstandard = payload
    },
    SELECTED_SS_TOPIC (state, payload) {
      if (state.selectedSSTopic && payload && state.selectedSSTopic.topic_id === payload.topic_id) {
        state.selectedSSTopic = null
      } else {
        state.selectedSSTopic = payload
      }
    },
    LOADING (state, payload) {
      store.state.filters.loading = payload
    },
    SET_DOT_PLOT_DATA (state, payload) {
      state.jsonData = payload
    },
    SET_SHARE_PER_PRODUCT_DATA (state, payload) {
      state.sharePerProduct = payload
    },
    SET_FEATURED_TOPICS_DATA (state, payload) {
      state.featuredTopicsData = payload
    },
    SET_VOE_SHARE_PER_COMPANY_DATA (state, payload) {
      state.voeSharePerCompany = payload
    },
    OPEN (state, isOpen) {
      state.open = isOpen
    }
  }
}
