import Vue from 'vue'
import store from '@/store/index'
export default {
  namespaced: true,
  state: {
    newsRssFeed: [],
    totalCount: ''
  },

  actions: {
    /** This method returns a list of all news rss feed */
    getNewsRssFeed ({ commit }, value) {
      Vue.prototype.$http
        .get('/admin/news-rss-feeds', { params: value }
        )
        .then((response) => {
          if (response.data.status === 200 && response.data.data) {
            commit('COUNT', response.data.totalRows)
            commit('NEWS_RSS_FEED', response.data.data)
          } else {
            commit('COUNT', 0)
            commit('NEWS_RSS_FEED', [])
          }
        })
    },
    addRssFeedsFromFile ({ commit }, file) {
      commit('SET_LOADER', true)
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post('/admin/news-rss-feeds/upload-file', file, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          if (response.status === 200) {
            resolve(response.data)
          }
        }).finally(() => {
          commit('SET_LOADER', false)
        })
      })
    }
  },
  mutations: {
    NEWS_RSS_FEED (state, payload) {
      state.newsRssFeed = payload
    },
    COUNT (state, payload) {
      state.totalCount = payload
    },
    SET_LOADER (state, payload) {
      store.state.adminCommon.loader = payload
    }
  }
}
