import Vue from 'vue'
import store from '../store/index'

const getDefaultState = () => {
  return {
    reviewData: [],
    totalCount: 0,
    skip: 0,
    limit: 50,
    ratingData: {},
    sort: {
      type: 'published_at',
      orderBy: null
    },
    sortOptions: [],
    loading: false
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    /**  This method fetches the review data to for the Comments page, and stores all of it in the store. */
    getWordCloudData ({
      commit
    }, value) {
      commit('LOADING', true)
      Vue.prototype.$http.post('/review', value).then((response) => {
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_REVIEW_DATA', response.data.data.reviews)
            commit('SET_ROW_COUNT', response.data.data.count)
            commit('SET_RATING_DATA', response.data.data.ratingList)
          }
        }
        commit('LOADING', false)
      }).catch(e => {
        commit('LOADING', false)
      })
    },
    updateSkip ({
      commit
    }, payload) {
      commit('SET_SKIP', payload)
    },
    /** This method updates the limit of viewable reviews per page */
    updateLimit ({
      commit
    }, payload) {
      commit('SET_LIMIT', payload)
    },
    updateSelectedCount ({
      commit
    }, payload) {
      commit('SET_ROW_COUNT', payload)
    },
    resetRatingData ({
      commit
    }) {
      commit('RESET_RATING_DATA')
    },
    /** This method updates the sorting criteria for the reviews on the Comments page */
    updateSort ({ commit }, value) {
      commit('UPDATE_SORT', value)
    },
    initializeSortOptions ({ commit }, value) {
      let sort = []
      if (value === 'voc') {
        sort = [{ name: 'Most Recent', alias: 'published_at', value: null }, { name: 'Product', alias: 'product_name', value: 'desc' }]
      } else {
        sort = [{ name: 'Most Recent', alias: 'published_at', value: null }, { name: 'Company', alias: 'company_name', value: 'desc' }]
      }
      commit('INIT_SORT', sort)
    }
  },
  mutations: {
    LOADING (state, payload) {
      state.loading = payload
    },
    SET_SKIP (state, payload) {
      state.skip = payload
    },
    SET_LIMIT (state, payload) {
      state.limit = payload
    },
    SET_ROW_COUNT (state, payload) {
      state.totalCount = payload
    },
    SET_REVIEW_DATA (state, payload) {
      state.reviewData = payload
    },
    SET_RATING_DATA (state, payload) {
      state.ratingData = payload
    },
    RESET_RATING_DATA (state) {
      state.ratingData = {
        Like: 0, Dislike: 0, Neutral: 0, Mixed: 0
      }
    },
    UPDATE_SORT (state, payload) {
      state.sort = payload
      const options = state.sortOptions
      for (const option of options) {
        if (option.alias === payload.type) {
          option.value = payload.orderBy
        }
      }
      state.sortOptions = options
    },
    INIT_SORT (state, payload) {
      state.sortOptions = payload
    }
  }
}
