/* eslint-disable no-useless-catch */
import Vue from 'vue'

const getDefaultState = () => {
  return {
    componentKey: 1,
    basicDetails: {},
    basicDetailsBackup: {},
    bvdBasicDetails: {},
    entityTypeList: [],
    entitySubTypeList: [],
    enrichmentAnswerChoices: [],
    legaStructures: [],
    inactivationReasons: [],
    businessStatusOptions: [
      {
        value: true,
        text: 'Active'
      },
      {
        value: false,
        text: 'Inactive'
      }
    ]
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async getBasicDetails({ commit }, sgaId) {
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/basic?sgaId=${sgaId}`
        )
        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          commit('SET_BASIC_DETAILS', response.data.data)
        } else {
          // Reset store
          commit('SET_BASIC_DETAILS', {})
        }
      } catch (error) {
        throw error
      }
    },

    async updateBasicDetails({ commit, dispatch }, payload) {
      const { sgaId, payloadData } = payload
      try {
        const response = await Vue.prototype.$http.put(
          `/dmp/basic?sgaId=${sgaId}`,
          payloadData
        )
        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          commit('SET_BASIC_DETAILS', response.data.data)
          return response.data.status
        } else {
          throw response
        }
      } catch (error) {
        throw error
      }
    },

    async getEntityTypeList({ commit }) {
      try {
        const response = await Vue.prototype.$http.get('/dmp/basic/entity')
        if (
          response.data.status === 200 &&
          response.data &&
          response.data.data
        ) {
          commit('SET_ENTITY_TYPE_LIST', response.data.data)
        }
      } catch (error) {
        throw error
      }
    },

    async getEntitySubTypeList({ commit }, entityTypeId) {
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/basic/entity-subtype?entityTypeId=${entityTypeId}`
        )
        if (
          response.data.status === 200 &&
          response.data &&
          response.data.data
        ) {
          commit('SET_ENTITY_SUBTYPE_LIST', response.data.data)
        }
      } catch (error) {
        throw error
      }
    },

    updateEntitySubTypeList({ commit }, entitySubTypeList) {
      commit('SET_ENTITY_SUBTYPE_LIST', entitySubTypeList)
    },

    updateInactiveReasonList({ commit }, inactiveReasonList) {
      commit('SET_INACTIVATION_REASONS', inactiveReasonList)
    },

    async getBasicEnrichmentAnswerChoices({ commit }) {
      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/basic/enrichment-answer-choices'
        )
        if (
          response.data.status === 200 &&
          response.data &&
          response.data.data
        ) {
          commit('SET_ENRICHMENT_ANSWER_CHOICES', response.data.data)
        }
      } catch (error) {
        throw error
      }
    },

    async getLegalStructures({ commit }) {
      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/basic/legal-structures'
        )
        if (
          response.data.status === 200 &&
          response.data &&
          response.data.data
        ) {
          commit('SET_LEGAL_STRUCTURES', response.data.data)
        }
      } catch (error) {
        throw error
      }
    },

    async getInactivationReason({ commit }, reasonCode) {
      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/basic/inactivation-reasons',
          {
            params: { reasonCode }
          }
        )
        if (
          response.data.status === 200 &&
          response.data &&
          response.data.data
        ) {
          commit('SET_INACTIVATION_REASONS', response.data.data)
        }
      } catch (error) {
        throw error
      }
    },

    resetBasicDetails({ commit }) {
      commit('SET_BASIC_DETAILS', {})
    },

    updateBasicDetailsFormData({ commit }, updatedData) {
      commit('UPDATE_BASIC_DETAILS_FORM_DATA', updatedData)
    }
  },
  mutations: {
    SET_ENTITY_TYPE_LIST(state, entityTypeList) {
      state.entityTypeList = entityTypeList.map((entityTypeObj) => {
        // Assign new key

        entityTypeObj.value = entityTypeObj.id
        entityTypeObj.text = `${entityTypeObj.id}-${entityTypeObj.name}`

        // Delete old key
        delete entityTypeObj.id
        delete entityTypeObj.name

        return entityTypeObj
      })
    },
    SET_ENTITY_SUBTYPE_LIST(state, entitySubTypeList) {
      state.entitySubTypeList = entitySubTypeList.map((entitySubTypeObj) => {
        // Assign new key
        entitySubTypeObj.value = entitySubTypeObj.id
        entitySubTypeObj.text = `${entitySubTypeObj.id}-${entitySubTypeObj.name}`

        // Delete old key
        delete entitySubTypeObj.id
        delete entitySubTypeObj.name

        return entitySubTypeObj
      })
    },
    SET_BASIC_DETAILS(state, basicDetails) {
      state.basicDetails = { ...basicDetails }
      state.basicDetailsBackup = { ...basicDetails }
    },
    SET_ENRICHMENT_ANSWER_CHOICES(state, enrichmentAnswerChoices) {
      state.enrichmentAnswerChoices = enrichmentAnswerChoices.map(
        (entityTypeObj) => {
          // Assign new key

          entityTypeObj.value = entityTypeObj.id
          entityTypeObj.text = `${entityTypeObj.id}-${entityTypeObj.name}`

          // Delete old key
          delete entityTypeObj.id
          delete entityTypeObj.name

          return entityTypeObj
        }
      )
    },
    SET_LEGAL_STRUCTURES(state, legaStructures) {
      state.legaStructures = legaStructures.map((entityTypeObj) => {
        // Assign new key

        entityTypeObj.value = entityTypeObj.id
        entityTypeObj.text = `${entityTypeObj.id}-${entityTypeObj.name}`

        // Delete old key
        delete entityTypeObj.id
        delete entityTypeObj.name

        return entityTypeObj
      })
    },
    SET_INACTIVATION_REASONS(state, inactivationReasons) {
      // state.inactivationReasons = inactivationReasons
      state.inactivationReasons = inactivationReasons.map((entityTypeObj) => {
        // Assign new key

        entityTypeObj.value = entityTypeObj.id
        entityTypeObj.text = `${entityTypeObj.id}-${entityTypeObj.reason}`

        // Delete old key
        delete entityTypeObj.id
        delete entityTypeObj.reason

        return entityTypeObj
      })
    },
    UPDATE_BASIC_DETAILS_FORM_DATA(state, updatedData) {
      // Check if businessStatus key exists in updatedData
      if (Object.prototype.hasOwnProperty.call(updatedData, 'businessStatus')) {
        // Find the business status option that matches the updated business status
        const selectedBusinessStatus = state.businessStatusOptions.find(
          (status) =>
            status.text.toLowerCase() ===
            updatedData.businessStatus.toLowerCase()
        )
        // Update the business status in the state
        updatedData.businessStatus = [null, undefined].includes(
          selectedBusinessStatus
        )
          ? null
          : selectedBusinessStatus
      }

      // Merge the updated data into the basicDetails state
      state.bvdBasicDetails = { ...updatedData }
    }
  }
}
