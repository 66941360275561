import Vue from 'vue'
import moment from 'moment'
import { ratingList, reactions, voeReactions, sapCompetitors } from '../constant/data'
import store from './index.js'
import isEmpty from 'lodash/isEmpty'
import { exists } from '@/util/util'

const getDefaultFilterState = () => {
  return {
    dataSourceList: [],
    csatVendorsDataSourceList: [],
    selectedSourceList: [],
    categoryList: [],
    selectedCategories: {},
    defaultCategory: {},
    productList: [],
    selectedProducts: [],
    sortedProducts: [],
    companies: [],
    companyList: [],
    selectedCompanies: [],
    selectedCompaniesVoe: [],
    sortedCompaniesVoe: [],
    industries: [],
    selectedIndustries: [],
    features: [],
    selectedFeatures: [],
    selectedRating: [],
    userType: [],
    searchKeyWord: '',
    ratingList: [],
    reactionList: [],
    ActiveVocTimePeriod: null,
    ActiveVendorCsatTimePeriod: null,
    ActiveVoeTimePeriod: null,
    loading: false,
    subscriptionRange: { startDate: '', endDate: '' },
    subscriptionGranularity: false,
    isInitialLoadDone: false,
    subCategories: [],
    previousPeriodRange: [],
    isPreviousPeriodInSubscription: false,
    regionList: [],
    selectedCountries: [],
    selectedRegions: [],
    employeeStatusList: [],
    selectedEmployeeStatuses: [],
    businessFuncList: [],
    selectedBusinessFunc: [],
    seniorityLevelList: [],
    selectedSeniorityLevel: [],
    availableCategories: ['Application Testing Services', 'Business Intelligence', 'Managed Security Services'],
    csatVendorList: [],
    selectedCsatVendors: [],
    sortedCsatVendors: [],
    vendorCsatReferenceArray: [],
    esgCategoriesList: [],
    esgCategoriesListFlat: [],
    selectedEsgCategories: []
  }
}

export default {
  namespaced: true,
  state: getDefaultFilterState(),
  actions: {
    resetFilterState ({
      commit
    }, value) {
      commit('RESET_DEFAULT_FILTER_STATE')
    },
    /** This method fetches the data that is used to populate the global filters. Once it fetches all options for all dropdowns it saves the default one (in the case of categories) or the first available option in the list in both the localStorage as well as the vuex store.  */
    getDataForGlobalFilters ({
      commit, state
    }, values) {
      commit('LOADING', true)

      Vue.prototype.$http.get('/category').then((response) => {
        if (response.data.status === 200) {
          commit('CATEGORY_DATA', response.data.data)
          const localCategory = JSON.parse(window.localStorage.getItem('CATEGORY'))
          let defaultCategory = null
          let valid = false
          try {
            defaultCategory = JSON.parse(window.localStorage.getItem('DEFAULT_CATEGORY'))
            const defCatJSON = localStorage.getItem('DEFAULT_CATEGORY')
            const checkIfDefCategoryExists = () => {
              return state.categoryList.some(category => JSON.stringify(category) === defCatJSON)
            }
            valid = checkIfDefCategoryExists()
          } catch (e) {
            console.log(e)
          }
          if (localCategory != null) {
            localStorage.setItem('SUB_CATEGORY_VERIFY', true)
            if (values && values.currentModule === 'tam') {
              const validTamCat = state.availableCategories.includes(localCategory.name)
              if (validTamCat) {
                commit('SELECTED_CATEGORIES', localCategory)
                commit('UPDATE_SELECTED_NESTED_CATEGORY', localCategory ? localCategory.subCategory : null)
              } else {
                const cat = response.data.data.length ? response.data.data.filter(c => state.availableCategories.includes(c.name))[0] : null
                commit('SELECTED_CATEGORIES', cat)
                commit('UPDATE_SELECTED_NESTED_CATEGORY', cat ? cat.subCategory : null)
              }
            } else {
              commit('SELECTED_CATEGORIES', localCategory)
              commit('UPDATE_SELECTED_NESTED_CATEGORY', localCategory.subCategory)
            }
          } else if (defaultCategory != null && values && values.currentModule !== 'tam') {
            localStorage.setItem('SUB_CATEGORY_VERIFY', true)
            commit('SELECTED_CATEGORIES', defaultCategory)
            commit('UPDATE_SELECTED_NESTED_CATEGORY', defaultCategory.subCategory)
          } else {
            if (values && values.currentModule === 'tam') {
              const cat = response.data.data.length ? response.data.data.filter(c => state.availableCategories.includes(c.name))[0] : null
              commit('SELECTED_CATEGORIES', cat)
              commit('UPDATE_SELECTED_NESTED_CATEGORY', cat ? cat.subCategory : null)
            } else {
              commit('SELECTED_CATEGORIES', response.data.data.length ? response.data.data[0] : null)
            }
          }
        }
      })

      Vue.prototype.$http.get('/industry').then((response) => {
        if (response.data.status === 200) {
          commit('INDUSTRIES', response.data.data)
          const localIndustries = JSON.parse(window.localStorage.getItem('INDUSTRIES'))
          if (localIndustries != null) {
            commit('SELECTED_INDUSTRIES', localIndustries)
          } else {
            commit('SELECTED_INDUSTRIES', response.data.data)
          }
        }
      })

      Vue.prototype.$http.get('/company/size').then((response) => {
        if (response.data.status === 200) {
          commit('COMPANIES', response.data.data)
          const localCompanies = JSON.parse(window.localStorage.getItem('COMPANIES'))
          if (localCompanies != null) {
            commit('SELECTED_COMPANIES', localCompanies)
          } else {
            commit('SELECTED_COMPANIES', response.data.data)
          }
        }
      })

      Vue.prototype.$http.get('/region').then((response) => {
        if (response.data.status === 200) {
          commit('REGIONS', response.data.data)
          const countries = JSON.parse(localStorage.getItem('SELECTED_COUNTRIES'))
          if (countries != null) {
            commit('SELECTED_COUNTRIES', countries)
          } else {
            const countryList = []
            for (const obj of response.data.data) {
              for (const child of obj.children) {
                countryList.push(child)
              }
            }
            commit('SELECTED_COUNTRIES', countryList)
          }
        }
      })

      commit('LOADING', false)
    },

    /** This method fetches all the products belonging to a category. (Used when category selected category is changed in the global filters) */
    getProductByCategoryId ({ commit, state }, ids) {
      commit('LOADING', true)
      Vue.prototype.$http.post('/product', ids).then((response) => {
        if (response.data.status === 200) {
          const localProducts = JSON.parse(window.localStorage.getItem('PRODUCTS'))
          if (localProducts !== null) {
            state.selectedProducts = localProducts
          }
          if (response.data && response.data.data) {
            const newList = []
            if (response.data.data.length && state.selectedProducts.length) {
              for (const product of response.data.data) {
                const check = state.selectedProducts.filter((data) => data.id === product.id)
                const checkProduct = state.productList.filter((data) => data.id === product.id)
                if (check.length || (!checkProduct.length && state.productList.length)) {
                  newList.push(product)
                }
              }
              commit('PRODUCTS', response.data.data)
              commit('SELECTED_PRODUCTS', newList)
            } else {
              commit('PRODUCTS', response.data.data)
              commit('SELECTED_PRODUCTS', response.data.data)
            }
            commit('LOADING', false)
          }
        }
      })
    },

    /** This method fetches all the vendors/companies that have products present in a user's VoC subscription, regardless of the category */
    getCsatVendors ({ commit, state }, ids) {
      commit('LOADING', true)
      Vue.prototype.$http.post('/csat-vendor-list', ids).then((response) => {
        if (response.data.status === 200) {
          const localCompanies = JSON.parse(window.localStorage.getItem('CSAT_VENDORS'))
          const competitorInfoArray = response.data.data.filter(vendor => sapCompetitors.includes(vendor.id))
          commit('UPDATE_REFERENCE_ARRAY', competitorInfoArray)
          competitorInfoArray.sort((a, b) => {
            const idA = a.id
            const idB = b.id
            const indexA = sapCompetitors.indexOf(idA)
            const indexB = sapCompetitors.indexOf(idB)
            return indexA - indexB
          })
          if (localCompanies !== null) {
            state.selectedCsatVendors = localCompanies
          } else {
            state.selectedCsatVendors = competitorInfoArray
          }
          if (response.data && response.data.data) {
            const newList = []
            if (response.data.data.length && state.selectedCsatVendors.length) {
              // Keep sapCompetitors ahead to get the proper order at login.
              const modifiedResponse = [...competitorInfoArray, ...response.data.data]
              // Modify the response to include 'type' info. This is needed to make sure that the sortedCsatVendor and selectedCsatVendor variables will contain the same objects
              modifiedResponse.forEach(obj => { if (!obj.type) { obj.type = 'company' } })
              for (const vendor of modifiedResponse) {
                // Check if vendor exists in selectedCsatVendors
                const isVendorSelected = exists(vendor, state.selectedCsatVendors)
                // Check if vendor exits in the csatVendorList
                const isVendorInList = exists(vendor, state.csatVendorList)
                // If vendor is already selected, or vendor is not selected but does exist in csatVendorList, then add it to newList. If that vendor already exists within newList then don't add it
                if ((isVendorSelected || (!isVendorInList && state.csatVendorList.length)) && !exists(vendor, newList)) {
                  newList.push(vendor)
                }
              }
              commit('CSAT_VENDORS', response.data.data)
              commit('SELECTED_CSAT_VENDORS', newList)
            } else {
              commit('CSAT_VENDORS', response.data.data)
              commit('SELECTED_CSAT_VENDORS', response.data.data)
            }
            commit('LOADING', false)
          }
        }
      })
    },

    /** This method returns all the sources for a particular category. (Used when the selected category is changed in the global filters) */
    getSourceByCategoryId ({ commit, state }, ids) {
      commit('LOADING', true)
      Vue.prototype.$http.post('/source', ids).then((response) => {
        if (response.data.status === 200) {
          commit('DATA_SOURCE', response.data.data)
          const localSources = JSON.parse(window.localStorage.getItem('SOURCES'))
          if (localSources != null && localSources.length) {
            commit('SELECTED_DATA_SOURCES', localSources)
          } else {
            commit('SELECTED_DATA_SOURCES', response.data.data)
          }
          commit('LOADING', false)
        }
      })
    },

    /** This method looks at the data sources being used in different category subscriptions for a user's VoC subscription, and returns a list that contains all of them. */
    getCumulativeSourceByClientId ({ commit, state }, ids) {
      commit('LOADING', true)
      Vue.prototype.$http.post('/cumulative-source').then((response) => {
        if (response.data.status === 200) {
          commit('DATA_SOURCE', response.data.data)
          commit('CSAT_VENDOR_DATA_SOURCE', response.data.data)
          const localSources = JSON.parse(window.localStorage.getItem('CSAT_VENDOR_SOURCES'))
          if (localSources != null && localSources.length) {
            commit('SELECTED_CSAT_VENDOR_DATA_SOURCES', localSources)
          } else {
            commit('SELECTED_CSAT_VENDOR_DATA_SOURCES', response.data.data)
          }
          commit('LOADING', false)
        }
      })
    },

    /** This method returns time range information for a particular category. It returns the date of the first available review for this category, as well the date of the most recent review. It also indicates whether monthly granularity is available for this user. */
    getTimeSubscriptionByCategoryId ({ commit, state }, ids) {
      commit('LOADING', true)
      Vue.prototype.$http.post('/client/time-range', ids).then((response) => {
        if (response.data.status === 200) {
          const granularity = response.data.data.month_granularity
          const range = response.data.data.time_range
          const startDate = moment(range.start_date).format('YYYY-MM-DD')
          const endDate = moment(range.end_date).format('YYYY-MM-DD')
          commit('SUBSCRIPTION_RANGE', { startDate: startDate, endDate: endDate })
          commit('SUBSCRIPTION_GRANULARITY', granularity)
          let eDate = null
          let sDate = null
          eDate = moment(endDate, 'YYYY-MM-DD').isBefore(moment()) ? endDate : moment().endOf('month').format('YYYY-MM-DD')
          if (moment(endDate, 'YYYY-MM-DD').startOf('month').isAfter(moment().startOf('month')) || moment(endDate, 'YYYY-MM-DD').startOf('month').isSame(moment().startOf('month'))) {
            if (moment(startDate, 'YYYY-MM-DD').isBefore(moment().subtract(11, 'month').startOf('month'))) {
              sDate = moment().subtract(11, 'month').startOf('month').format('YYYY-MM-DD')
            } else {
              sDate = startDate
            }
          } else {
            sDate = startDate
          }

          const timeObject = {
            group_by: granularity ? 'month' : 'quarter', // 'quarter',
            range: [sDate, eDate]
          }

          const localStoredTimeRange = JSON.parse(window.localStorage.getItem('TIME_PERIOD'))
          if (localStoredTimeRange != null && localStoredTimeRange.range[0] && state.isInitialLoadDone === false) {
            if ((moment(localStoredTimeRange.range[0], 'YYYY-MM-DD').isAfter(moment(startDate, 'YYYY-MM-DD')) || moment(localStoredTimeRange.range[0], 'YYYY-MM-DD').startOf('month').isSame(moment(startDate, 'YYYY-MM-DD').startOf('month'))) &&
              (moment(localStoredTimeRange.range[1], 'YYYY-MM-DD').isBefore(moment(endDate, 'YYYY-MM-DD')) || moment(localStoredTimeRange.range[1], 'YYYY-MM-DD').startOf('month').isSame(moment(endDate, 'YYYY-MM-DD').startOf('month')))) {
              timeObject.range = localStoredTimeRange.range
              timeObject.group_by = localStoredTimeRange.group_by
              // commit('IS_INITIAL_LOAD_DONE', false)
            }
          }

          commit('UPDATE_ACTIVE_VOC_TIME_PERIOD', timeObject)
        }
      })
    },

    /** This method returns time range information for a particular category. It returns the date of the first available review for this category, as well the date of the most recent review. It also indicates whether monthly granularity is available for this user. */
    getCumulativeTimeSubscriptionVoc ({ commit, state }, ids) {
      commit('LOADING', true)
      Vue.prototype.$http.get('/client/cumulative-time-range/', ids).then((response) => {
        if (response.data.status === 200) {
          const granularity = response.data.data.month_granularity
          const range = response.data.data.time_range
          const startDate = moment(range.start_date).format('YYYY-MM-DD')
          const endDate = moment(range.end_date).format('YYYY-MM-DD')
          commit('SUBSCRIPTION_RANGE', { startDate: startDate, endDate: endDate })
          commit('SUBSCRIPTION_GRANULARITY', granularity)
          let eDate = null
          let sDate = null
          eDate = moment(endDate, 'YYYY-MM-DD').isBefore(moment()) ? endDate : moment().endOf('month').format('YYYY-MM-DD')
          if (moment(endDate, 'YYYY-MM-DD').startOf('month').isAfter(moment().startOf('month')) || moment(endDate, 'YYYY-MM-DD').startOf('month').isSame(moment().startOf('month'))) {
            if (moment(startDate, 'YYYY-MM-DD').isBefore(moment().subtract(11, 'month').startOf('month'))) {
              sDate = moment().subtract(11, 'month').startOf('month').format('YYYY-MM-DD')
            } else {
              sDate = startDate
            }
          } else {
            sDate = startDate
          }

          const timeObject = {
            group_by: granularity ? 'month' : 'quarter', // 'quarter',
            range: [sDate, eDate]
          }

          const localStoredTimeRange = JSON.parse(window.localStorage.getItem('VENDOR_CSAT_TIME_PERIOD'))
          if (localStoredTimeRange != null && localStoredTimeRange.range[0] && state.isInitialLoadDone === false) {
            if ((moment(localStoredTimeRange.range[0], 'YYYY-MM-DD').isAfter(moment(startDate, 'YYYY-MM-DD')) || moment(localStoredTimeRange.range[0], 'YYYY-MM-DD').startOf('month').isSame(moment(startDate, 'YYYY-MM-DD').startOf('month'))) &&
              (moment(localStoredTimeRange.range[1], 'YYYY-MM-DD').isBefore(moment(endDate, 'YYYY-MM-DD')) || moment(localStoredTimeRange.range[1], 'YYYY-MM-DD').startOf('month').isSame(moment(endDate, 'YYYY-MM-DD').startOf('month')))) {
              timeObject.range = localStoredTimeRange.range
              timeObject.group_by = localStoredTimeRange.group_by
            }
          }

          commit('UPDATE_ACTIVE_VENDOR_CSAT_TIME_PERIOD', timeObject)
        }
      })
    },

    /** This method fetches the data used to populate the VOE filters */
    getDataForVoeGlobalFilters ({ commit, state }, ids) {
      commit('LOADING', true)

      Vue.prototype.$http.get('/company').then((response) => {
        if (response.data.status === 200) {
          commit('COMPANIES_VOE', response.data.data)
          const localCompanies = JSON.parse(window.localStorage.getItem('COMPANIES_VOE'))
          if (localCompanies != null) {
            commit('SELECTED_COMPANIES_VOE', localCompanies)
          } else {
            commit('SELECTED_COMPANIES_VOE', response.data.data)
          }
        }
      })

      Vue.prototype.$http.get('/employee-status').then((response) => {
        if (response.data.status === 200) {
          commit('EMPLOYEE_STATUS_LIST', response.data.data)
          const empStatusList = JSON.parse(window.localStorage.getItem('EMPLOYEE_STATUS_LIST'))
          if (empStatusList != null) {
            commit('SELECTED_EMPLOYEE_STATUSES', empStatusList)
          } else {
            commit('SELECTED_EMPLOYEE_STATUSES', response.data.data)
          }
        }
      })

      Vue.prototype.$http.get('/business-function').then((response) => {
        if (response.data.status === 200) {
          commit('BUSINESS_FUNCTION_LIST', response.data.data)
          const businessFuncList = JSON.parse(window.localStorage.getItem('BUSINESS_FUNCTION_LIST'))
          if (businessFuncList != null) {
            commit('SELECTED_BUSINESS_FUNCTIONS', businessFuncList)
          } else {
            commit('SELECTED_BUSINESS_FUNCTIONS', response.data.data)
          }
        }
      })

      Vue.prototype.$http.get('/seniority-level').then((response) => {
        if (response.data.status === 200) {
          commit('SENIORITY_LEVEL_LIST', response.data.data)
          const seniorityLevelList = JSON.parse(window.localStorage.getItem('SENIORITY_LEVEL_LIST'))
          if (seniorityLevelList != null) {
            commit('SELECTED_SENIORITY_LEVELS', seniorityLevelList)
          } else {
            commit('SELECTED_SENIORITY_LEVELS', response.data.data)
          }
        }
      })

      commit('LOADING', false)
    },

    /** This method returns time range information for companies. It returns the date of the first available review for this module, as well the date of the most recent review. It also indicates whether monthly granularity is available for this user. */
    getTimeSubscriptionForVoe ({ commit, state }, ids) {
      commit('LOADING', true)
      Vue.prototype.$http.post('/client/time-range-voe', ids).then((response) => {
        if (response.data.status === 200) {
          const granularity = response.data.data.month_granularity
          const range = response.data.data.time_range
          const startDate = moment(range.start_date).format('YYYY-MM-DD')
          // const endDate = moment(range.end_date).format('YYYY-MM-DD')
          const endDate = moment().endOf('month').format('YYYY-MM-DD')
          commit('SUBSCRIPTION_RANGE', { startDate: startDate, endDate: endDate })
          commit('SUBSCRIPTION_GRANULARITY', granularity)
          let eDate = null
          let sDate = null
          eDate = moment(endDate, 'YYYY-MM-DD').isBefore(moment()) ? endDate : moment().endOf('month').format('YYYY-MM-DD')
          if (moment(endDate, 'YYYY-MM-DD').startOf('month').isAfter(moment().startOf('month')) || moment(endDate, 'YYYY-MM-DD').startOf('month').isSame(moment().startOf('month'))) {
            if (moment(startDate, 'YYYY-MM-DD').isBefore(moment().subtract(11, 'month').startOf('month'))) {
              sDate = moment().subtract(11, 'month').startOf('month').format('YYYY-MM-DD')
            } else {
              sDate = startDate
            }
          } else {
            sDate = startDate
          }

          const timeObject = {
            group_by: granularity ? 'month' : 'quarter', // 'quarter',
            range: [sDate, eDate]
          }

          const localStoredTimeRange = JSON.parse(window.localStorage.getItem('TIME_PERIOD_VOE'))
          if (localStoredTimeRange != null && localStoredTimeRange.range[0] && state.isInitialLoadDone === false) {
            if ((moment(localStoredTimeRange.range[0], 'YYYY-MM-DD').isAfter(moment(startDate, 'YYYY-MM-DD')) || moment(localStoredTimeRange.range[0], 'YYYY-MM-DD').startOf('month').isSame(moment(startDate, 'YYYY-MM-DD').startOf('month'))) &&
              (moment(localStoredTimeRange.range[1], 'YYYY-MM-DD').isBefore(moment(endDate, 'YYYY-MM-DD')) || moment(localStoredTimeRange.range[1], 'YYYY-MM-DD').startOf('month').isSame(moment(endDate, 'YYYY-MM-DD').startOf('month')))) {
              timeObject.range = localStoredTimeRange.range
              timeObject.group_by = localStoredTimeRange.group_by
              // commit('IS_INITIAL_LOAD_DONE', false)
            }
          }

          commit('UPDATE_ACTIVE_VOE_TIME_PERIOD', timeObject)
        }
      })
    },

    /** This method resets/refreshes the selected sources and products when a category is changed */
    resetCategoryDependent ({ commit }) {
      commit('SELECTED_DATA_SOURCES', [])
      commit('SELECTED_PRODUCTS', [])
      // localStorage.setItem('PRODUCTS', null)
      // localStorage.setItem('SOURCES', null)
    },

    /** This method returns the data for the local filters on the Comments page. */
    getAllLocalFiltersData ({ commit, state, rootState }, payload) {
      commit('LOADING', true)
      Vue.prototype.$http.post('/topic', payload).then((response) => {
        if (response.data.status === 200) {
          commit('FEATURES', response.data.data)
          commit('RATINGS', ratingList)
          commit('REACTIONS', reactions)
          if (state.selectedFeatures.length) {
            commit('SELECTED_FEATURES', state.selectedFeatures)
          } else {
            // if (rootState.sunriseSunset.selectedSSTopic && rootState.sunriseSunset.selectedAttribute.length) {
            //   commit('SELECTED_FEATURES', response.data.data.filter(f => f.id === rootState.sunriseSunset.selectedSSTopic))
            // } else {
            //   commit('SELECTED_FEATURES', response.data.data)
            // }
            commit('SELECTED_FEATURES', response.data.data)
          }
          if (state.selectedRating.length) {
            commit('SELECTED_RATING', state.selectedRating)
          } else {
            commit('SELECTED_RATING', ratingList)
          }
          // const localReactions = JSON.parse(window.localStorage.getItem('REACTIONS'))
          if (state.userType.length) {
            commit('SET_USER_TYPE', state.userType)
          } else {
            commit('SET_USER_TYPE', reactions)
          }
          commit('LOADING', false)
        }
      })
    },
    /** This method returns the data for the local filters on the Comments page. */
    getAllVoeLocalFiltersData ({ commit, state }, payload) {
      commit('LOADING', true)
      Vue.prototype.$http.post('/topic', payload).then((response) => {
        if (response.data.status === 200) {
          commit('FEATURES', response.data.data)
          commit('RATINGS', ratingList)
          commit('REACTIONS', voeReactions)
          if (state.selectedFeatures.length) {
            commit('SELECTED_FEATURES', state.selectedFeatures)
          } else {
            commit('SELECTED_FEATURES', response.data.data)
          }
          if (state.selectedRating.length) {
            commit('SELECTED_RATING', state.selectedRating)
          } else {
            commit('SELECTED_RATING', ratingList)
          }
          if (state.userType.length) {
            commit('SET_USER_TYPE', state.userType)
          } else {
            commit('SET_USER_TYPE', voeReactions)
          }
          commit('LOADING', false)
        }
      })
    },
    getNewsCategoryListGlobalFilter ({ commit, state }, payload) {
      commit('LOADING', true)
      Vue.prototype.$http.get('news-categories', { params: payload })
        .then((response) => {
          if (response.data.status === 200 && response.data.data) {
            return response.data
          }
        }).then(async (data) => {
          let allData = data.data
          const length = data.data.length
          const totalRowsCount = data.totalRows / payload.limit
          if (length < data.totalRows) {
            for (let i = 1; i < totalRowsCount; i++) {
              const newPayload = {
                moduleId: payload.moduleId,
                limit: payload.limit,
                offset: payload.limit * (i)
              }
              const res = await Vue.prototype.$http.get('news-categories', { params: newPayload })
              allData = allData.concat(res.data.data)
            }
          }
          return allData
        }).then((data) => {
          commit('ESG_CATEGORY_LIST_FLAT', data.filter(cat => cat.status === 'active'))
          commit('CREATE_ESG_CATEGORY_HIERARCHY', data)
          const localEsgCategories = JSON.parse(localStorage.getItem('SELECTED_ESG_CATEGORIES'))
          if (localEsgCategories != null) {
            commit('SELECTED_ESG_CATEGORIES', localEsgCategories)
          } else {
            const listOfAllCategoryIds = state.esgCategoriesListFlat.filter(category => category.status === 'active').map(category => category.id)
            commit('SELECTED_ESG_CATEGORIES', listOfAllCategoryIds)
          }
        })
    },
    updateActiveVocTimePeriod ({ commit }, payload) {
      commit('UPDATE_ACTIVE_VOC_TIME_PERIOD', payload)
    },
    updateActiveVendorCsatTimePeriod ({ commit }, payload) {
      commit('UPDATE_ACTIVE_VENDOR_CSAT_TIME_PERIOD', payload)
    },
    updateActiveVoeTimePeriod ({ commit }, payload) {
      commit('UPDATE_ACTIVE_VOE_TIME_PERIOD', payload)
    },
    updateSearchKeyWord ({
      commit
    }, payload) {
      commit('UPDATE_SEARCH_KEYWORD', payload)
    },
    updateUserType ({
      commit
    }, payload) {
      commit('SET_USER_TYPE', payload)
    },
    updateSelectedSources ({
      commit
    }, { sources, page }) {
      if (page !== 'vendor-csat') {
        commit('SELECTED_DATA_SOURCES', sources)
      } else {
        commit('SELECTED_CSAT_VENDOR_DATA_SOURCES', sources)
      }
    },
    updateSelectedProducts ({
      commit
    }, payload) {
      commit('SELECTED_PRODUCTS', payload)
    },
    updateSelectedIndustries ({
      commit
    }, payload) {
      commit('SELECTED_INDUSTRIES', payload)
    },
    updateSelectedCompanies ({
      commit
    }, payload) {
      commit('SELECTED_COMPANIES', payload)
    },
    updateSelectedCompaniesVoe ({
      commit
    }, payload) {
      commit('SELECTED_COMPANIES_VOE', payload)
    },
    updateSelectedCsatVendors ({
      commit
    }, payload) {
      commit('SELECTED_CSAT_VENDORS', payload)
    },
    updateSelectedFeatures ({
      commit
    }, payload) {
      commit('SELECTED_FEATURES', payload)
    },
    updateSelectedRating ({
      commit
    }, payload) {
      commit('SELECTED_RATING', payload)
    },
    updateSelectedCategory ({
      commit
    }, payload) {
      commit('SELECTED_CATEGORIES', payload)
    },
    updateSelectedCountries ({
      commit
    }, payload) {
      commit('SELECTED_COUNTRIES', payload)
    },
    updateSelectedRegions ({
      commit
    }, payload) {
      commit('SELECTED_REGIONS', payload)
    },
    updateSelectedEmployeeStatuses ({
      commit
    }, payload) {
      commit('SELECTED_EMPLOYEE_STATUSES', payload)
    },
    updateSelectedBusinessFunctions ({
      commit
    }, payload) {
      commit('SELECTED_BUSINESS_FUNCTIONS', payload)
    },
    updateSelectedSeniorityLevels ({
      commit
    }, payload) {
      commit('SELECTED_SENIORITY_LEVELS', payload)
    },
    updateSortedProducts ({
      commit
    }, payload) {
      commit('UPDATE_SORTED_PRODUCTS', payload)
    },
    updateSortedCompaniesVoe ({
      commit
    }, payload) {
      commit('UPDATE_SORTED_COMPANIES', payload)
    },
    updateSortedCsatVendors ({
      commit
    }, payload) {
      commit('UPDATE_SORTED_CSAT_VENDORS', payload)
    },
    updateSubscriptionRange ({ commit }, payload) {
      commit('SUBSCRIPTION_RANGE', payload)
    },
    updateSubscriptionGranularity ({ commit }, payload) {
      commit('SUBSCRIPTION_GRANULARITY', payload)
    },
    updateIsInitialLoadDone ({ commit }, payload) {
      commit('IS_INITIAL_LOAD_DONE', payload)
    },
    updateSelectedNestedCategories ({ commit }, payload) {
      commit('UPDATE_SELECTED_NESTED_CATEGORY', payload)
    },
    resetSubCategories ({ commit, state }) {
      state.subCategories = []
    },
    updatePreviousPeriodRange ({ commit }, payload) {
      commit('UPDATE_PREVIOUS_PERIOD_RANGE', payload)
    },
    updateIsPreviousPeriodInSubscription ({ commit }, payload) {
      commit('UPDATE_IS_PREVIOUS_PERIOD_IN_SUBSCRIPITON', payload)
    },
    updateLoadingState ({ commit }, payload) {
      commit('LOADING', payload)
    },
    updateSelectedEsgCategories ({ commit }, payload) {
      commit('SELECTED_ESG_CATEGORIES', payload)
    },
    updateVendorCsatReferenceArray ({ commit }, payload) {
      commit('UPDATE_REFERENCE_ARRAY', payload)
    }
  },
  mutations: {
    UPDATE_IS_PREVIOUS_PERIOD_IN_SUBSCRIPITON (state, payload) {
      state.isPreviousPeriodInSubscription = payload
    },
    UPDATE_PREVIOUS_PERIOD_RANGE (state, payload) {
      state.previousPeriodRange = payload
    },
    RESET_DEFAULT_FILTER_STATE (state, payload) {
      state = {}
    },
    LOADING (state, payload) {
      state.loading = payload
    },
    SELECTED_CATEGORIES (state, payload) {
      const currentCategory = JSON.parse(localStorage.getItem('CATEGORY'))
      if (currentCategory != null) {
        if (currentCategory.subscriptionId !== payload.subscriptionId) {
          localStorage.setItem('PRODUCTS', null)
          localStorage.setItem('SOURCES', null)
          state.productList = []
          state.subCategories = []
        }
      }
      state.selectedFeatures = []
      localStorage.setItem('CATEGORY', JSON.stringify(payload))
      state.selectedCategories = payload
    },
    SELECTED_ESG_CATEGORIES (state, payload) {
      if (payload.length) {
        localStorage.setItem('SELECTED_ESG_CATEGORIES', JSON.stringify(payload))
      }
      state.selectedEsgCategories = payload
    },
    SELECTED_COUNTRIES (state, payload) {
      if (payload.length) {
        localStorage.setItem('SELECTED_COUNTRIES', JSON.stringify(payload))
      }
      state.selectedCountries = payload
    },
    SELECTED_REGIONS (state, payload) {
      state.selectedRegions = payload
    },
    REGIONS (state, payload) {
      state.regionList = payload
    },
    EMPLOYEE_STATUS_LIST (state, payload) {
      state.employeeStatusList = payload
    },
    BUSINESS_FUNCTION_LIST (state, payload) {
      state.businessFuncList = payload
    },
    SENIORITY_LEVEL_LIST (state, payload) {
      state.seniorityLevelList = payload
    },
    UPDATE_SELECTED_NESTED_CATEGORY (state, payload) {
      state.subCategories = payload
      localStorage.setItem('SUB_CATEGORY', JSON.stringify(payload))
      localStorage.setItem('SUB_CATEGORY_VERIFY', null)
    },
    CATEGORY_DATA (state, payload) {
      state.categoryList = payload
    },
    UPDATE_ACTIVE_VOC_TIME_PERIOD (state, payload) {
      localStorage.setItem('TIME_PERIOD', JSON.stringify(payload))
      state.ActiveVocTimePeriod = payload
    },
    UPDATE_ACTIVE_VENDOR_CSAT_TIME_PERIOD (state, payload) {
      localStorage.setItem('VENDOR_CSAT_TIME_PERIOD', JSON.stringify(payload))
      state.ActiveVendorCsatTimePeriod = payload
    },
    UPDATE_ACTIVE_VOE_TIME_PERIOD (state, payload) {
      localStorage.setItem('TIME_PERIOD_VOE', JSON.stringify(payload))
      state.ActiveVoeTimePeriod = payload
    },
    UPDATE_SEARCH_KEYWORD (state, payload) {
      state.searchKeyWord = payload
    },
    SET_USER_TYPE (state, payload) {
      // localStorage.setItem('REACTIONS', JSON.stringify(payload))
      state.userType = payload
    },
    SELECTED_RATING (state, payload) {
      // localStorage.setItem('RATINGS', JSON.stringify(payload))
      state.selectedRating = payload
    },
    COMPANIES (state, payload) {
      state.companies = payload
    },
    COMPANIES_VOE (state, payload) {
      state.companyList = payload
    },
    CSAT_VENDORS (state, payload) {
      state.csatVendorList = payload
    },
    FEATURES (state, payload) {
      state.features = payload
    },
    RATINGS (state, payload) {
      state.ratingList = payload
    },
    REACTIONS (state, payload) {
      state.reactionList = payload
    },
    INDUSTRIES (state, payload) {
      state.industries = payload
    },
    DATA_SOURCE (state, payload) {
      state.dataSourceList = payload
    },
    CSAT_VENDOR_DATA_SOURCE (state, payload) {
      if (!state.csatVendorsDataSourceList.length) {
        state.csatVendorsDataSourceList = payload
      }
    },
    PRODUCTS (state, payload) {
      state.productList = payload
    },
    SELECTED_DATA_SOURCES (state, payload) {
      if (payload.length) {
        localStorage.setItem('SOURCES', JSON.stringify(payload))
      }
      state.selectedSourceList = payload
    },
    SELECTED_CSAT_VENDOR_DATA_SOURCES (state, payload) {
      if (payload.length) {
        localStorage.setItem('CSAT_VENDOR_SOURCES', JSON.stringify(payload))
      }
      state.selectedSourceList = payload
    },
    SELECTED_PRODUCTS (state, payload) {
      if (payload.length) {
        localStorage.setItem('PRODUCTS', JSON.stringify(payload))
      }
      if (isEmpty(store.state.winLoss.wlSelectedProductLeft) || isEmpty(store.state.winLoss.wlSelectedProductRight)) {
        store.state.winLoss.wlSelectedProductLeft = state.productList.find(product => product.is_owned) || state.productList[0]
        store.state.winLoss.wlSelectedProductRight = state.productList.find(p => { return (p.is_owned !== true && p.id !== store.state.winLoss.wlSelectedProductLeft.id) }) // state.productList.find(product => !product.is_owned) || {}
      }
      state.selectedProducts = payload
    },
    SELECTED_INDUSTRIES (state, payload) {
      localStorage.setItem('INDUSTRIES', JSON.stringify(payload))
      state.selectedIndustries = payload
    },
    SELECTED_COMPANIES (state, payload) {
      localStorage.setItem('COMPANIES', JSON.stringify(payload))
      state.selectedCompanies = payload
    },
    SELECTED_COMPANIES_VOE (state, payload) {
      localStorage.setItem('COMPANIES_VOE', JSON.stringify(payload))
      state.selectedCompaniesVoe = payload
    },
    SELECTED_CSAT_VENDORS (state, payload) {
      payload.forEach(obj => { if (!obj.type) { obj.type = 'company' } })
      localStorage.setItem('CSAT_VENDORS', JSON.stringify(payload))
      state.selectedCsatVendors = payload
    },
    SELECTED_EMPLOYEE_STATUSES (state, payload) {
      localStorage.setItem('EMPLOYEE_STATUS_LIST', JSON.stringify(payload))
      state.selectedEmployeeStatuses = payload
    },
    SELECTED_BUSINESS_FUNCTIONS (state, payload) {
      localStorage.setItem('BUSINESS_FUNCTION_LIST', JSON.stringify(payload))
      state.selectedBusinessFunc = payload
    },
    SELECTED_SENIORITY_LEVELS (state, payload) {
      localStorage.setItem('SENIORITY_LEVEL_LIST', JSON.stringify(payload))
      state.selectedSeniorityLevel = payload
    },
    SELECTED_FEATURES (state, payload) {
      // localStorage.setItem('FEATURES', JSON.stringify(payload))
      state.selectedFeatures = payload
    },
    UPDATE_SORTED_PRODUCTS (state, payload) {
      const data = JSON.parse(JSON.stringify(payload))
      if (data.length) {
        data.sort(function (a, b) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        }).sort((a, b) => {
          if (a.is_owned > b.is_owned) {
            return -1
          }
          if (a.is_owned < b.is_owned) {
            return 1
          }
          return 0
        })
        state.sortedProducts = data
      }
    },
    UPDATE_SORTED_COMPANIES (state, payload) {
      const data = JSON.parse(JSON.stringify(payload))
      if (data.length) {
        data.sort(function (a, b) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        }).sort((a, b) => {
          if (a.is_owned > b.is_owned) {
            return -1
          }
          if (a.is_owned < b.is_owned) {
            return 1
          }
          return 0
        })
        state.sortedCompaniesVoe = data
      }
    },
    UPDATE_SORTED_CSAT_VENDORS (state, payload) {
      payload.forEach(obj => { if (!obj.type) { obj.type = 'company' } })
      const data = JSON.parse(JSON.stringify(payload))
      if (data.length) {
        // This is the list of sapCompetitors that are selected. This array keeps them ordered according to the reference array
        const presentCompetitors = state.vendorCsatReferenceArray.filter(vendor => data.find(obj => vendor.id === obj.id))
        // This is the list of other vendors that are present. They will be displayed after the sap competitors, in alphabetical order
        const presentNonCompetitors = data.filter(item => !state.vendorCsatReferenceArray.find(vendor => item.id === vendor.id))
        presentNonCompetitors.sort(function (a, b) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
        const finalArray = [...presentCompetitors, ...presentNonCompetitors]
        state.sortedCsatVendors = finalArray
      }
    },
    UPDATE_REFERENCE_ARRAY (state, payload) {
      state.vendorCsatReferenceArray = payload
    },
    SUBSCRIPTION_RANGE (state, payload) {
      state.subscriptionRange = payload
    },
    SUBSCRIPTION_GRANULARITY (state, payload) {
      state.subscriptionGranularity = payload
    },
    IS_INITIAL_LOAD_DONE (state, payload) {
      state.isInitialLoadDone = payload
    },
    CREATE_ESG_CATEGORY_HIERARCHY (state, payload) {
      // Get rid of unnecessary info from the payload.
      const totalCategoryList = payload.map(i => {
        return {
          id: i.id,
          label: i.label,
          children: i.children,
          parent: i.parentid,
          status: i.status
        }
      })
      // Go through the list of all categories (active and inactive).
      for (const category of totalCategoryList) {
        if (category.parent) {
          // Find the category in the totalCategoryList
          const currentNewsCategory = totalCategoryList.find(i => i.id === category.id)
          const parentId = category.parent
          // Find the parent of the currentCategory
          const parentNewsCategory = totalCategoryList.find(i => i.id === parentId)
          // If the parentCategory's status is inactive, set the currentCategory's parent property to that of the parentCategory's.
          if (parentNewsCategory.status === 'inactive') {
            currentNewsCategory.parent = parentNewsCategory.parent
          }
        }
      }
      // Filter out the inactive categories
      let activeCategoryList = totalCategoryList.filter(category => category.status === 'active')
      // Iterate through the list and use the active categories to create the hierarchy
      for (const info of activeCategoryList) {
        if (info.parent) {
          const currentNewsCategory = activeCategoryList.find(i => i.id === info.id)
          const parentId = info.parent
          const parentNewsCategory = activeCategoryList.find(i => i.id === parentId)
          if (parentNewsCategory && parentNewsCategory.children) {
            parentNewsCategory.children.push(currentNewsCategory)
          } else if (parentNewsCategory && !parentNewsCategory.children) {
            parentNewsCategory.children = [currentNewsCategory]
          }
        }
      }
      activeCategoryList = activeCategoryList.filter(i => !i.parent)
      state.esgCategoriesList = activeCategoryList
    },
    ESG_CATEGORY_LIST_FLAT (state, payload) {
      state.esgCategoriesListFlat = payload
    }
  }
}
