import Vue from 'vue'
import Snackbar from '../../components/Snackbar'

export default {
  namespaced: true,
  state: {
    jobList: [],
    totalCount: null,
    jobLog: '',
    loadingLogs: false
  },
  actions: {
    /** This method gets a list of all jobs and their statuses from the database */
    getJobList ({ commit }, value) {
      Vue.prototype.$http.post('/admin/job', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('JOB_LIST', response.data.data.data)
          if (value.type) {
            Snackbar({
              message: 'Refreshed data successfully',
              type: 'is-success'
            })
          }
          commit('COUNT', response.data.data.count)
        }
      })
    },
    /** This method updates the priority of a job in the database */
    updateJobPriority ({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put('/admin/job/priority', params)
          .then((response) => {
            if (response.data.status === 200 && response.data.data) {
              Snackbar({
                message: 'Priority updated successfully',
                type: 'is-success'
              })
              resolve(200)
            } else {
              resolve(500)
            }
          })
      })
    },
    /** This method updates the status of a job in the database */
    updateJob ({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.put('/admin/job', params).then((response) => {
          if (response.data.status === 200 && response.data.data) {
            for (const job of state.jobList) {
              if (job.job_id === response.data.data.data[0].job_id) {
                job.statusAlias = response.data.data.data[0].statusAlias
              }
            }
            Snackbar({
              message: `Job ${
                params.type === 'Restart' ? 'Restarted' : 'Cancelled'
              } successfully`,
              type: 'is-success'
            })
            resolve(response.data.status)
          } else {
            Snackbar({ message: 'Something went wrong', type: 'is-danger' })
            resolve(500)
          }
        })
      })
    },
    /** This method starts the scripts used to create new jobs */
    createAutomateJobs () {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.get('/admin/job/automate').then((response) => {
          if (response.data.status === 200) {
            Snackbar({
              message: 'All Jobs created successfully.',
              type: 'is-success'
            })
            resolve(response.data.status)
          } else {
            Snackbar({ message: 'Something went wrong', type: 'is-danger' })
            resolve(500)
          }
        })
      })
    },
    resetJobList ({ commit, state }) {
      state.jobList = []
    },
    /** This method fetches the logs for a particular job */
    getLogDetails ({ commit, state }, params) {
      commit('LOADING_LOGS', true)
      Vue.prototype.$http
        .post('/admin/job/getLogs', params)
        .then((response) => {
          if (response.data.status === 200 && response.data.data) {
            commit('JOB_LOG', response.data.data)
          } else {
            commit('JOB_LOG', 'No data')
          }
          commit('LOADING_LOGS', false)
        })
    },
    /** This method updates the job logs */
    updateJobLog ({ commit, state }, value) {
      commit('JOB_LOG', value)
    }
  },
  mutations: {
    JOB_LIST (state, payload) {
      state.jobList = payload
    },
    JOB_LOG (state, payload) {
      state.jobLog = payload
    },
    COUNT (state, payload) {
      state.totalCount = payload
    },
    LOADING_LOGS (state, payload) {
      state.loadingLogs = payload
    }
  }
}
