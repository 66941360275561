import Vue from 'vue'
import Snackbar from '../../components/Snackbar'

export default {
  namespaced: true,
  state: {
    topicList: [],
    parentTopicList: [],
    categoryCustomTopics: [],
    topicDetails: null,
    totalCount: null,
    parentTopics: [],
    childTopics: []
  },
  actions: {
    /** This method returns a list of topics present in the database */
    getTopicList ({ commit }, value) {
      Vue.prototype.$http.post('/admin/topic', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('TOPIC_LIST', response.data.data.data)
          commit('COUNT', response.data.data.count)
        }
      })
    },
    /** This  */
    getFilteredTopicList ({ commit }, categoryId) {
      Vue.prototype.$http.get(`/admin/topic/category/${categoryId}`).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('PARENT_TOPIC_LIST', response.data.data)
        }
      })
    },
    /** This method returns the details of a single topic */
    getSingleTopic ({ commit }, payload) {
      Vue.prototype.$http.get(`/admin/topic/${payload.topicId}` + `?module=${payload.module}`).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('TOPIC_DETAIL', response.data.data)
        }
      })
    },
    /** This method adds a topic to the database */
    addTopic ({ commit }, payload) {
      Vue.prototype.$http.post('/admin/topic/add', payload).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully created', type: 'is-success' })
        } else if (response.data.status === 201) {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        }
      })
    },
    /** This method updates an existing topic in the database */
    updateTopic ({ commit }, payload) {
      Vue.prototype.$http.put(`/admin/topic/${payload.topicId}`, payload).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully updated', type: 'is-success' })
          commit('TOPIC_DETAIL', response.data.data)
        } else if (response.data.status === 201) {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        }
      })
    },
    /** This method returns a list of topics belonging to a category */
    getTopicListByCategoryId ({ commit }, categoryId) {
      Vue.prototype.$http.post('/admin/topic/categoryId', { categoryId }).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('CATEGORY_CUSTOM_TOPIC_LIST', response.data.data)
        }
      })
    },
    /** This method deletes a topic from the database */
    deleteTopic ({ commit }, topicId) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.delete(`/admin/topic/${topicId}`).then((response) => {
          if (response.data.status === 200 && response.data.data) {
            Snackbar({ message: 'Successfully Deleted', type: 'is-success' })
            resolve(response.data.status)
          } else if (response.data.status === 201) {
            Snackbar({ message: response.data.info, type: 'is-danger' })
          } else {
            Snackbar({ message: 'Some Error Occured.', type: 'is-danger' })
          }
        })
      })
    },
    /** This method deletes a topic from the database */
    deleteVoeTopic ({ commit }, topicId) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.delete(`/admin/topic/voe/${topicId}`).then((response) => {
          if (response.data.status === 200 && response.data.data) {
            Snackbar({ message: 'Successfully Deleted', type: 'is-success' })
            resolve(response.data.status)
          } else if (response.data.status === 201) {
            Snackbar({ message: response.data.info, type: 'is-danger' })
          } else {
            Snackbar({ message: 'Some Error Occured.', type: 'is-danger' })
          }
        })
      })
    },
    resetFilteredParentTopics ({ commit }) {
      commit('RESET_PARENT_TOPICS')
    },
    resetTopics ({ commit }) {
      commit('TOPIC_LIST', [])
      commit('COUNT', '0')
    },
    resetParentTopicList ({ commit }) {
      commit('RESET_PARENT_TOPIC')
    },
    resetChildTopicList ({ commit }) {
      commit('RESET_CHILD_TOPIC')
    },
    getFilteredVoeTopicList ({ commit }, industryId) {
      Vue.prototype.$http.get(`/admin/topic/industry/${industryId}`).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('PARENT_TOPIC_LIST', response.data.data)
        }
      })
    }
  },
  mutations: {
    TOPIC_LIST (state, payload) {
      state.topicList = payload
    },
    PARENT_TOPIC_LIST (state, payload) {
      state.parentTopicList = payload
    },
    CATEGORY_CUSTOM_TOPIC_LIST (state, payload) {
      state.parentTopics = payload.parentId
      state.childTopics = payload.childId
    },
    TOPIC_DETAIL (state, payload) {
      state.topicDetails = payload.length ? payload[0] : null
    },
    COUNT (state, payload) {
      state.totalCount = payload
    },
    RESET_PARENT_TOPICS (state) {
      state.parentTopicList = []
    },
    RESET_PARENT_TOPIC (state) {
      state.parentTopics = []
    },
    RESET_CHILD_TOPIC (state) {
      state.childTopics = []
    }
  }
}
