import { SnackbarProgrammatic as Snackbar } from 'buefy'

export default function (object) {
  Snackbar.open({
    duration: 3000,
    message: object.message,
    type: object.type,
    position: 'is-bottom-right',
    actionText: 'Ok',
    queue: false,
    indefinite: object?.indefinite || false
  })
}
