import Vue from 'vue'
import { checkPathPermission } from '@/util/permissions.utils'

const getDefaultState = () => {
  return {}
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async updateEntityStatus({ commit }, entityStatusData) {
      const { assignmentId, userRole, currentStatus } = entityStatusData
      const user = JSON.parse(localStorage.getItem('user'))

      const REVIEW = 'Review' // review status name
      const SUBMITTED = 'Submitted / Ready for Delivery' // submit status name
      const STAGING = 'Staging'

      let statusName = currentStatus
      if (checkPathPermission('submit-for-staging', userRole)) {
        statusName = STAGING
      } else if (checkPathPermission('submit-for-delivery', userRole)) {
        statusName = SUBMITTED
      } else if (checkPathPermission('submit-for-review', userRole)) {
        statusName = REVIEW
      }

      const reqData = {
        userId: user.userId,
        assignmentId: assignmentId,
        statusName: statusName
      }

      try {
        const response = await Vue.prototype.$http.post(
          '/dmp/entity-review',
          reqData
        )

        if (response?.data && response.data?.status === 200) {
          // return true if updated successfully
          return true
        }
      } catch (error) {
        throw new Error(error)
      }
    },

    async updateEntityToSubmit({ commit }, entityStatusData) {
      try {
        const response = await Vue.prototype.$http.post(
          '/dmp/review/update-entities-status',
          entityStatusData
        )

        if (response?.data && response.data?.status === 200) {
          // return true if updated successfully
          return true
        }
      } catch (error) {
        throw new Error(error)
      }
    },

    async updateEntityFlag({ commit }, entityFlagObj) {
      const { sgaId, flag } = entityFlagObj
      const reqData = {
        sgaId: sgaId,
        flag: flag
      }

      try {
        const response = await Vue.prototype.$http.post(
          '/dmp/update-entity-flag',
          reqData
        )

        if (response?.data && response.data?.status === 200) {
          // return true if updated successfully
          return true
        }
      } catch (error) {
        throw new Error(error)
      }
    }
  },
  mutations: {}
}
