import Vue from 'vue'
import {
  ANA_C_IDENTIFIERS_NA,
  ANA_C_IDENTIFIERS_NOT_APP
} from '@/constant/data.js'

const getDefaultState = () => {
  return {
    componentKey: 1,
    nationalITypeOpts: [],
    instSectorOpts: [],
    instSubSectOpts: [],
    legalFormOpts: [],
    legalProceedStatusOpts: [],
    enterpriseSizeOpts: [],
    anaCreditDetails: {},
    bvdAnaCreditDetails: {}
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async getNationalIdentifierTypes({ commit }, countryId) {
      try {
        commit('SET_NATIONAL_IDENTIFIER_TYPES', [])
        const response = await Vue.prototype.$http.get(
          `/dmp/ana-credit/national-identifier-types?countryId=${countryId}`
        )
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_NATIONAL_IDENTIFIER_TYPES', response.data.data)
          }
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    async getInstitutionalSectors({ commit }) {
      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/ana-credit/institutional-sectors'
        )

        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_INSTITUTIONAL_SECTORS', response.data.data)
          }
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    async getInstitutionalSubSectors({ commit }, institutionalSectorId) {
      try {
        commit('SET_INSTITUTIONAL_SUB_SECTORS', [])
        const response = await Vue.prototype.$http.get(
          `/dmp/ana-credit/institutional-sub-sectors?sectorId=${institutionalSectorId}`
        )

        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_INSTITUTIONAL_SUB_SECTORS', response.data.data)
          }
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    async getLegalForms({ commit }) {
      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/ana-credit/legal-form'
        )

        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_LEGAL_FORMS', response.data.data)
          }
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    async getLegalProceedingsStatus({ commit }) {
      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/ana-credit/legal-proceedings-status'
        )

        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_LEGAL_PROCEEDING_STATUS', response.data.data)
          }
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    async getAnaCreditDetails({ commit }, sgaId) {
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/ana-credit?sgaId=${sgaId}`
        )
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_ANA_CREDIT_DETAILS', response.data.data)
          }
        } else {
          // Reset store
          commit('SET_ANA_CREDIT_DETAILS', {})
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    async updateAnaCreditDetails({ commit }, updateAnaCreditDetails) {
      try {
        const { sgaId, updatedAnaCreditFields } = updateAnaCreditDetails

        const response = await Vue.prototype.$http.put(
          `/dmp/ana-credit?sgaId=${sgaId}`,
          updatedAnaCreditFields
        )

        if (response?.data && response.data?.status === 200) {
          if (response.data?.data) {
            commit('SET_ANA_CREDIT_DETAILS', response.data.data)
          }
        }
        // Returned response for status of submission
        return response?.data?.status
      } catch (error) {
        throw new Error(error)
      }
    },
    async getEnterpriseSize({ commit }) {
      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/ana-credit/enterprise-size'
        )

        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_ENTERPRISE_SIZE', response.data.data)
          }
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    updateInstitutionalSubSectors({ commit }, instSubSectOpts) {
      commit('SET_INSTITUTIONAL_SUB_SECTORS', instSubSectOpts)
    },
    resetAnaCreditDetails({ commit }) {
      commit('SET_ANA_CREDIT_DETAILS', {})
    },

    patchAnaCreditDetails({ commit }, bvdAnaCreditDetails) {
      commit('SET_BVD_ANA_CREDIT', bvdAnaCreditDetails)
    }
  },
  mutations: {
    SET_NATIONAL_IDENTIFIER_TYPES(state, nationalITypeOpts) {
      state.nationalITypeOpts = nationalITypeOpts.map((option) => {
        const nationalITypeObj = {}
        nationalITypeObj.value = option.nationalIdentifierId

        // Check if option.rank is not equal to Not Available or Not Applicable
        if (
          option.rank !== ANA_C_IDENTIFIERS_NA.RANK &&
          option.rank !== ANA_C_IDENTIFIERS_NOT_APP.RANK
        ) {
          nationalITypeObj.text = `${option.rank} - ${option.nationalIdentifierTypeCode} - ${option.name}`
        } else {
          nationalITypeObj.text = `${option.nationalIdentifierTypeCode} - ${option.name}`
        }

        nationalITypeObj.rank = option.rank
        return nationalITypeObj
      })
    },
    SET_INSTITUTIONAL_SECTORS(state, instSectorOpts) {
      state.instSectorOpts = instSectorOpts.map((option) => {
        const instSectorObj = {}
        instSectorObj.value = option.sectorId
        instSectorObj.text = `${option.sectorCode} - ${option.institutionalSector}`
        return instSectorObj
      })
    },
    SET_INSTITUTIONAL_SUB_SECTORS(state, instSubSectOpts) {
      state.instSubSectOpts = instSubSectOpts.map((option) => {
        const instSubSectObj = {}
        instSubSectObj.value = option.subSectorId
        instSubSectObj.text = `${option.subSectorCode} - ${option.institutionalSubSector}`
        return instSubSectObj
      })
    },
    SET_LEGAL_FORMS(state, legalFormOpts) {
      state.legalFormOpts = legalFormOpts.map((option) => {
        const legalFormOptsObj = {}
        legalFormOptsObj.value = option.legalFormCode
        legalFormOptsObj.text = `${option.legalFormCode} - ${option.englishName}`
        return legalFormOptsObj
      })
    },
    SET_LEGAL_PROCEEDING_STATUS(state, legalProceedStatusOpts) {
      state.legalProceedStatusOpts = legalProceedStatusOpts.map((option) => {
        const legalProceedStatusOptsObj = {}
        legalProceedStatusOptsObj.value = option.statusId
        legalProceedStatusOptsObj.text = `${option.statusCode} - ${option.name}`
        return legalProceedStatusOptsObj
      })
    },
    SET_ENTERPRISE_SIZE(state, enterpriseSizeOpts) {
      state.enterpriseSizeOpts = enterpriseSizeOpts.map((option) => {
        const enterpriseSizeOptsObj = {}
        enterpriseSizeOptsObj.value = option.businessSizeId
        enterpriseSizeOptsObj.text = `${option.businessSizeName}`
        return enterpriseSizeOptsObj
      })
    },
    SET_ANA_CREDIT_DETAILS(state, anaCreditDetails) {
      state.anaCreditDetails = { ...anaCreditDetails }
    },
    SET_BVD_ANA_CREDIT(state, bvdAnaCreditDetails) {
      state.bvdAnaCreditDetails = { ...bvdAnaCreditDetails }
    }
  }
}
