// import groupBy from 'lodash/groupBy'
import Vue from 'vue'
import store from '../store/index'

const getDefaultState = () => {
  return {
    voeStatistics: {},
    reviewVolume: 0,
    maxReviewVolume: 0,
    sortedCompanies: [],
    sort: {
      type: 'Default',
      orderBy: null
    },
    sortOptions: [{ name: 'Default', alias: 'Default', value: null },
      { name: 'Review volume', alias: 'review_id', value: 'desc' },
      { name: 'Rating', alias: 'c_sat_rating', value: 'desc' }],
    activeVoEView: 'grid',
    showText: true
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    /** This method fetches the data required for the Voe summary pages from the database */
    getVoeStatistics ({ commit, state }, value) {
      commit('LOADING', true)
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post(`/company/${value.company.id}/voeStatistic`, value).then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              const payload = response.data.data
              payload.company = value.company
              // commit('VOE_STATISTICS', payload)
              resolve(payload)
            }
          }
          commit('LOADING', false)
        }).catch(e => {
          commit('LOADING', false)
        })
      })
    },

    /** This method returns the review volume information as calculated from the database. */
    getVoeVolume ({ commit, state }, value) {
      commit('LOADING', true)
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post('/review/getVoeReviewVolume', value).then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              const payload1 = response.data.data.totalReviewVolume
              const payload2 = response.data.data.maxReviewVolume
              commit('REVIEW_VOLUME', payload1)
              commit('MAX_REVIEW_VOLUME', payload2)
              resolve(payload1)
            }
          }
        }).catch(e => {
          commit('LOADING', false)
        })
      })
    },

    getSortedCompanies ({ commit }, value) {
      Vue.prototype.$http.post('/company/sorted', value).then((response) => {
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SORTED_COMPANIES', response.data.data)
          }
        }
      })
    },
    /** This method updates the criteria required for sorting on the VoE page */
    updateSort ({ commit }, value) {
      commit('UPDATE_SORT', value)
    },
    /** This method changes the voe view and saves the new value in the vuex store. */
    updateActiveVoEView ({ commit }, value) {
      commit('ACTIVE_VOE_VIEW', value)
    },
    /** This method controls whether or not values should be rendered over the voe summary barchart. */
    updateShowText ({ commit }, value) {
      commit('SHOW_BARCHART_TEXT', value)
    }
  },
  mutations: {
    ACTIVE_VOE_VIEW (state, payload) {
      state.activeVoEView = payload
      localStorage.setItem('VOE_VIEW_TYPE', payload)
    },
    SHOW_BARCHART_TEXT (state, payload) {
      state.showText = payload
    },
    LOADING (state, payload) {
      store.state.filters.loading = payload
    },
    REVIEW_VOLUME (state, payload) {
      state.reviewVolume = payload
    },
    MAX_REVIEW_VOLUME (state, payload) {
      state.maxReviewVolume = payload
    },
    VOE_STATISTICS (state, payload) {
      state.voeStatistics[payload.company.id] = payload
      state.loading = false
    },
    SORTED_COMPANIES (state, payload) {
      state.sortedCompanies = payload
    },
    UPDATE_SORT (state, payload) {
      state.sort = payload
      const options = state.sortOptions
      for (const option of options) {
        if (option.alias === payload.type) {
          option.value = payload.orderBy
        }
      }
      state.sortOptions = options
    }
  }
}
