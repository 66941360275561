import Vue from 'vue'

export default {
  namespaced: true,
  actions: {
    /** Adds a certain event to the database. It helps us for internal analytics of how the site is being used */
    updateEvent ({ commit }, data) {
      Vue.prototype.$http.post('/analytics/add', data)
    }
  }
}
