import Vue from 'vue'
import moment from 'moment'
import Snackbar from '@/components/Snackbar'

const getDefaultState = () => {
  return {
    componentKey: 1,
    caDetails: {},
    isLoading: false
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    getCorporateAction({ commit }, sgaId) {
      commit('SET_LOADING', true) // Set loading state to true
      commit('CA_DETAILS', {})

      Vue.prototype.$http
        .get(`/dmp/corporate-action?sgaId=${sgaId}`)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('CA_DETAILS', response.data.data)
            }
          } else if (response.data.status === 404) {
          } else {
            Snackbar({
              message: 'Something went wrong',
              type: 'is-danger'
            })
          }
        })
        .finally(() => {
          commit('SET_LOADING', false) // Set loading state to false when API call completes
        })
    },

    async updateCorporateAction({ commit }, payload) {
      commit('SET_LOADING', true)
      const { sgaId, updateData } = payload
      try {
        const response = await Vue.prototype.$http.put(
          `/dmp/corporate-action?sgaId=${sgaId}`,
          updateData
        )
        if (response.data.status === 200) {
          Snackbar({
            message: response.data.info,
            type: 'is-success',
            duration: 3000
          })
        } else {
          Snackbar({
            message: response.data.info,
            type: 'is-danger',
            duration: 3000
          })
        }
        return response.data.status
      } catch (error) {
      } finally {
        commit('SET_LOADING', false)
      }
    }
  },
  mutations: {
    CA_DETAILS(state, caDetails) {
      // Check if caDetails exists and has corporateActionDate before formatting
      if (caDetails && caDetails.corporateActionDate) {
        state.caDetails = {
          ...caDetails,
          corporateActionDate: moment(caDetails.corporateActionDate).format(
            'DD/MM/YYYY'
          )
        }
      } else {
        state.caDetails = { ...caDetails }
      }
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading
    }
  }
}
