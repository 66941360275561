import Vue from 'vue'

const getDefaultCommonState = () => {
  return {
    user_preference_mode: 'light',
    defaultCategory: {},
    subCategories: [],
    selectedCategories: {},
    categoryList: [],
    active_page: '',
    currentModule: '',
    gartnerId: 0,
    isCollapsed: false,
    reportFullscreen: {
      numReports: null,
      id: null,
      status: false
    },
    fullPageLoader: false
  }
}

export default {
  namespaced: true,
  state: getDefaultCommonState(),
  actions: {
    /** This method gets the gartner id of a certain product(?) */
    getGartnerId ({ commit }) {
      Vue.prototype.$http.get('/source/getGartnerId').then((response) => {
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('GARTNER_ID', response.data.data.id)
          }
        }
      })
    },
    /** This method updates the user preferences for the site and stores the information in both localStorage and the main database */
    update_user_preference_mode ({ commit, state }, value) {
      if (state.user_preference_mode !== value) {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user != null) {
          Vue.prototype.$http.put('/user/preference', { preference: { preference_mode: value }, userId: user.userId })
        }
      }
      commit('UPDATE_USER_PREFERENCE_MODE', value)
    },
    /** This method sets the default category that should be loaded when a user signs into the site. */
    updateDefaultCategory ({ commit, state }, value) {
      if (state.defaultCategory !== value) {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user != null) {
          Vue.prototype.$http.put('/user/preference', { preference: { defaultCategory: value }, userId: user.userId })
        }
      }
      commit('UPDATE_DEFAULT_CATEGORY', value)
    },
    /** This method returns the list of categories and subcategories available to a certain subscription */
    getCategoryData ({ commit, state }) {
      Vue.prototype.$http.get('/category').then((response) => {
        if (response.data.status === 200) {
          commit('CATEGORY_DATA', response.data.data)
          let defaultCat = null
          try {
            defaultCat = JSON.parse(window.localStorage.getItem('DEFAULT_CATEGORY'))
          } catch (e) {
            // console.log(e)
            defaultCat = null
          }
          if (defaultCat != null) {
            localStorage.setItem('SUB_CATEGORY_VERIFY', true)
            commit('SELECTED_CATEGORIES', defaultCat)
          } else {
            commit('SELECTED_CATEGORIES', null)
          }
        }
      })
    },
    /** This method updates the list of selected nested categories */
    updateSelectedNestedCategories ({ commit }, payload) {
      commit('UPDATE_SELECTED_NESTED_CATEGORY', payload)
    },
    /** This method sends a refresh token */
    sendRefreshToken ({ commit }) {
      Vue.prototype.$http.post('/token/add', { token: window.localStorage.getItem('refresh-token') })
    },
    resetCommonState ({ commit, dispatch }, value) {
      commit('RESET_COMMON_STATE')
    },
    updateActivePage ({ commit }, payload) {
      commit('UPDATE_ACTIVE_PAGE', payload)
    },
    updateCurrentModule ({ commit }, payload) {
      commit('UPDATE_CURRENT_MODULE', payload)
    },
    /** This method toggles the sidebar's expansion/contraction */
    toggleSidebarCollapse ({ commit }) {
      commit('TOGGLE_COLLAPSE')
    },
    /** This method toggles fullscreen mode for a selected report.
     * @values id {Number} - It takes in the id of the report
     */
    toggleReportFullscreen ({ commit }, value) {
      commit('TOGGLE_REPORT_FULLSCREEN', value)
    },
    /** This method forces fullscreen mode to close */
    resetReportFullscreen ({ commit }) {
      commit('RESET_REPORTFULLSCREEN')
    },
    nextReport ({ commit }, value) {
      commit('NEXT_REPORT', value)
    },
    toggleFullPageLoader ({ commit }, value) {
      commit('TOGGLE_FULLPAGE_LOADER', value)
    }
  },
  mutations: {
    UPDATE_USER_PREFERENCE_MODE (state, payload) {
      state.user_preference_mode = payload
    },
    UPDATE_DEFAULT_CATEGORY (state, payload) {
      localStorage.setItem('DEFAULT_CATEGORY', JSON.stringify(payload))
      state.defaultCategory = payload
    },
    SELECTED_CATEGORIES (state, payload) {
      state.defaultCategory = payload
    },
    UPDATE_SELECTED_NESTED_CATEGORY (state, payload) {
      state.subCategories = payload
      localStorage.setItem('SUB_CATEGORY', JSON.stringify(payload))
      localStorage.setItem('SUB_CATEGORY_VERIFY', null)
    },
    CATEGORY_DATA (state, payload) {
      state.categoryList = payload
    },
    RESET_COMMON_STATE (state, payload) {
      state = {} // getDefaultCommonState()
    },
    UPDATE_ACTIVE_PAGE (state, payload) {
      state.active_page = payload
    },
    UPDATE_CURRENT_MODULE (state, payload) {
      state.currentModule = payload
    },
    GARTNER_ID (state, payload) {
      state.gartnerId = payload
    },
    TOGGLE_COLLAPSE (state) {
      state.isCollapsed = !state.isCollapsed
    },
    TOGGLE_REPORT_FULLSCREEN (state, payload) {
      state.reportFullscreen.id = payload
      state.reportFullscreen.status = !state.reportFullscreen.status
    },
    RESET_REPORTFULLSCREEN (state) {
      document.querySelector('.main-view').classList.remove('change-main-height')
      document.querySelector('#common-tooltip').style.display = 'none'
      document.querySelector('.mltooltip').style.display = 'none'
      state.reportFullscreen.id = null
      state.reportFullscreen.status = false
    },
    NEXT_REPORT (state, payload) {
      state.reportFullscreen.id = payload
    },
    TOGGLE_FULLPAGE_LOADER (state, payload) {
      state.fullPageLoader = payload
    }
  }
}
