import Vue from 'vue'
import Axios from 'axios'
import store from '../store/index'
import moment from 'moment'

const getDefaultReportRepoFilterState = () => {
  return {
    selectedReportsRepoCompanies: [],
    selectedReportsRepoCompaniesList: [],
    reportsRepoCompanyList: [],
    searchText: null,
    dualListLoader: true,
    companyOffset: 0,
    infiniteScroll: false,
    searchCompanyOffset: 0,
    companyLoading: false,
    reportTypesList: [],
    selectedReportTypes: [],
    recommendationTypesList: [],
    selectedRecommendationTypes: [],
    activeReportsRepoTimePeriod: null,
    limit: 10,
    currentPage: 1
  }
}

// defined outside to keep the global scope
let cancelToken

export default {
  namespaced: true,
  state: getDefaultReportRepoFilterState(),
  actions: {
    getReportsRepoCompanyList ({ commit, state }, searchText) {
      // Check if there are any previous pending requests
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel('Request canceled due to new request.')
      }
      // Save the cancel token for the current request
      cancelToken = Axios.CancelToken.source()
      let url = ''
      const limit = 25 // default limit
      if (searchText) {
        searchText = encodeURIComponent(searchText)
        url =
          '/reports-repository/companies?searchQuery=' +
          searchText +
          '&offset=' +
          state.searchCompanyOffset +
          '&limit=' +
          limit +
          '&orderBy=name&order=asc'
      } else {
        url =
          '/reports-repository/companies?offset=' +
          state.companyOffset +
          '&limit=' +
          limit +
          '&orderBy=name&order=asc'
      }
      Vue.prototype.$http
        .get(
          url,
          { cancelToken: cancelToken.token } // Pass the cancel token to the current request
        )
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('REPORTS_REPO_COMPANY_LIST', response.data.data)
              commit('SET_INFINITE_SCROLL', true)
              if (searchText) {
                commit(
                  'SET_SEARCH_COMPANY_OFFSET',
                  state.searchCompanyOffset + limit
                )
              } else {
                commit('SET_COMPANY_OFFSET', state.companyOffset + limit)
              }
            }
          }
          if (response.data.data.length < 25 || response.data.status === 204) {
            commit('DUAL_LIST_LOADER', false)
          }
        })
        .catch((e) => {
          if (e.message === 'Request canceled due to new request.') {
            commit('DUAL_LIST_LOADER', true)
          } else {
            commit('DUAL_LIST_LOADER', false)
          }
        })
    },
    async getReportTypesList ({ commit, state }, parentReportId) {
      try {
        commit('LOADING', true)
        const reportTypesObj = JSON.parse(
          localStorage.getItem('REPORT_TYPES_LIST')
        )
        if (reportTypesObj && reportTypesObj[parentReportId]) {
          const selectedReportTypes = JSON.parse(
            localStorage.getItem('SELECTED_REPORT_TYPES')
          )
          commit('REPORT_TYPES_LIST', {
            id: parentReportId,
            list: reportTypesObj[parentReportId]
          })
          commit('SELECTED_REPORT_TYPES', {
            id: parentReportId,
            list: selectedReportTypes[parentReportId]
          })
        } else {
          const response = await Vue.prototype.$http.get(
            '/reports-repository/filters/report-types/' + parentReportId
          )
          if (response.status === 200 && response.data && response.data.data) {
            commit('REPORT_TYPES_LIST', {
              id: parentReportId,
              list: response.data.data
            })
            commit('SELECTED_REPORT_TYPES', {
              id: parentReportId,
              list: response.data.data
            })
          }
        }
        commit('LOADING', false)
      } catch (error) {
        commit('LOADING', false)
      }
    },
    async getRecommendationTypeList ({ commit, state }, parentReportId) {
      try {
        commit('LOADING', true)
          const recommendationTypesObj = JSON.parse(
            localStorage.getItem('RECOMMENDATION_TYPE_LIST')
          )
          if (recommendationTypesObj && recommendationTypesObj[parentReportId]) {
            const selectedRecommendationTypes = JSON.parse(
              localStorage.getItem('SELECTED_RECOMMENDATION_TYPES')
            )
            commit('RECOMMENDATION_TYPE_LIST', {
              id: parentReportId,
              list: recommendationTypesObj[parentReportId]
            })
            commit('SELECTED_RECOMMENDATION_TYPES', {
              id: parentReportId,
              list: selectedRecommendationTypes[parentReportId]
            })
          } else {
            const response = await Vue.prototype.$http.get(
              '/reports-repository/filters/recommendation-types/' + parentReportId
            )
            if (response.status === 200 && response.data && response.data.data) {
              commit('RECOMMENDATION_TYPE_LIST', {
                id: parentReportId,
                list: response.data.data
              })
              commit('SELECTED_RECOMMENDATION_TYPES', {
                id: parentReportId,
                list: response.data.data
              })
            }
          }

        commit('LOADING', false)
      } catch (error) {
        commit('LOADING', false)
      }
    },
    resetReportsRepoFilters ({ commit, dispatch, state }, payload) {
      const {
        parentReportId
      } = payload
      const defaultTimeRange = {
        range: [
          moment().subtract(11, 'month').startOf('month').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ]
      }
      dispatch('updateActiveReportsRepoTimePeriod', defaultTimeRange)
      dispatch('updateSelectedReportTypes', { id: parentReportId, list: state.reportTypesList })
      dispatch('updateSelectedReportsRepoCompanies', [])
      commit('SELECTED_RECOMMENDATION_TYPES', { id: parentReportId, list: state.recommendationTypesList })
    },
    updateSearchText ({ commit }, keyword) {
      if (this.keyword != null && this.keyword.trim() === '') {
        keyword = null
      }
      sessionStorage.setItem(
        'REPORTS_REPO_SEARCH_TEXT',
        JSON.stringify(keyword)
      )
      commit('SET_SEARCH_TEXT', keyword)
    },
    updateSelectedReportsRepoCompanies ({ commit }, payload) {
      localStorage.setItem(
        'SELECTED_REPORTS_REPO_COMPANIES',
        JSON.stringify(payload)
      )
      commit('SET_REPORTS_REPO_COMPANIES', payload)
      commit('SET_REPORTS_REPO_COMPANIES_LIST', payload)
    },
    updateReportsRepoCompaniesList ({ commit }, payload) {
      commit('REPORTS_REPO_COMPANY_LIST', payload)
    },
    updateReportsRepoCompayOffset ({ commit }, offset) {
      commit('SET_COMPANY_OFFSET', offset)
    },
    updateSearchCompanyOffset ({ commit }, offset) {
      commit('SET_SEARCH_COMPANY_OFFSET', offset)
    },
    updateDualListLoader ({ commit }, isLoading) {
      commit('DUAL_LIST_LOADER', isLoading)
    },
    updateSelectedReportTypes ({ commit }, payload) {
      commit('SELECTED_REPORT_TYPES', payload)
    },
    updateSelectedRecommendationTypes ({ commit }, payload) {
      commit('SELECTED_RECOMMENDATION_TYPES', payload)
    },
    updateActiveReportsRepoTimePeriod ({ commit }, timePeriod) {
      localStorage.setItem(
        'REPORTS_REPO_TIME_PERIOD',
        JSON.stringify(timePeriod)
      )
      commit('SET_ACTIVE_REPORTS_REPO_TIME_PERIOD', timePeriod)
    },
    updateCurrentPage ({ commit }, pageNo) {
      commit('SET_CURRENT_PAGE', pageNo)
    },
    updateLimit ({ commit }, limit) {
      commit('SET_LIMIT', limit)
    }
  },
  mutations: {
    LOADING (state, payload) {
      store.state.filters.loading = payload
    },
    SET_CURRENT_PAGE (state, pageNo) {
      state.currentPage = pageNo
    },
    SET_LIMIT (state, payload) {
      state.limit = payload
    },
    SET_REPORTS_REPO_COMPANIES (state, payload) {
      state.selectedReportsRepoCompanies = payload
    },
    SET_REPORTS_REPO_COMPANIES_LIST (state, payload) {
      state.selectedReportsRepoCompaniesList = payload
    },
    REPORTS_REPO_COMPANY_LIST (state, payload) {
      const newCompanyList = [...state.reportsRepoCompanyList, ...payload]
      localStorage.setItem(
        'REPORTS_REPO_COMPANY_LIST',
        JSON.stringify(newCompanyList)
      )
      const uniqueCompaniesList = [
        ...new Map(
          newCompanyList.map((publisher) => [publisher.id, publisher])
        ).values()
      ]
      state.reportsRepoCompanyList = uniqueCompaniesList
    },
    SET_INFINITE_SCROLL (state, payload) {
      state.infiniteScroll = payload
    },
    DUAL_LIST_LOADER (state, payload) {
      state.dualListLoader = payload
    },
    SET_SEARCH_COMPANY_OFFSET (state, offset) {
      state.searchCompanyOffset = offset
    },
    SET_SEARCH_TEXT (state, keyword) {
      state.searchText = keyword
    },
    SET_COMPANY_OFFSET (state, offset) {
      state.companyOffset = offset
    },
    REPORT_TYPES_LIST (state, payload) {
      const { id, list } = payload
      const reportTypesObj = JSON.parse(
        localStorage.getItem('REPORT_TYPES_LIST')
      )
      localStorage.setItem(
        'REPORT_TYPES_LIST',
        JSON.stringify({ ...reportTypesObj, [id]: list })
      )
      state.reportTypesList = list
    },
    SELECTED_REPORT_TYPES (state, payload) {
      const { id, list } = payload
      const reportTypesObj = JSON.parse(
        localStorage.getItem('SELECTED_REPORT_TYPES')
      )
      localStorage.setItem(
        'SELECTED_REPORT_TYPES',
        JSON.stringify({ ...reportTypesObj, [id]: list })
      )
      state.selectedReportTypes = list
    },
    SELECTED_RECOMMENDATION_TYPES (state, payload) {
      const { id, list } = payload
      const selectedRecommendationTypes = JSON.parse(
        localStorage.getItem('SELECTED_RECOMMENDATION_TYPES')
      )
      localStorage.setItem(
        'SELECTED_RECOMMENDATION_TYPES',
        JSON.stringify({ ...selectedRecommendationTypes, [id]: list })
      )
      state.selectedRecommendationTypes = list
    },
    RECOMMENDATION_TYPE_LIST (state, payload) {
      const { id, list } = payload
      const recommendationTypesObj = JSON.parse(
        localStorage.getItem('RECOMMENDATION_TYPE_LIST')
      )
      localStorage.setItem(
        'RECOMMENDATION_TYPE_LIST',
        JSON.stringify({ ...recommendationTypesObj, [id]: list })
      )
      state.recommendationTypesList = list
    },
    SET_ACTIVE_REPORTS_REPO_TIME_PERIOD (state, timePeriod) {
      state.activeReportsRepoTimePeriod = timePeriod
    }
  }
}
