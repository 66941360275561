import Vue from 'vue'
import store from '..'
import Snackbar from '../../components/Snackbar'
import { vm } from '../../main'

export default {
  namespaced: true,
  state: {
    availableReport: [],
    clientList: [],
    clientUserList: [],
    clients: [],
    clientUserDetails: null,
    clientDetails: null,
    totalCount: null,
    userTotalCount: null,
    clientReportTypes: []
  },
  actions: {
    /** This method returns a list of all clients in the database */
    getClientList ({ commit }) {
      Vue.prototype.$http.get('/admin/client').then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('CLIENT_LIST', response.data.data)
        }
      })
    },
    /** This method returns a paginated list of clients from the database */
    getClientListWithPagination ({ commit }, value) {
      Vue.prototype.$http.post('/admin/client', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('CLIENTS', response.data.data.data)
          commit('COUNT', response.data.data.count)
        }
      })
    },
    /** This method adds a new client to the database */
    addClient ({ commit }, value) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post('/admin/client/add', value).then((response) => {
          if (response.data.status === 200 && response.data.data) {
            Snackbar({ message: 'Successfully created', type: 'is-success' })
            if (response.data.data && response.data.data.length && value.editable) {
              vm.$router.replace(`client/update/${response.data.data[0].client_id}`)
            } else {
              store.dispatch('clients/getClientListWithPagination', { limit: 10, skip: 0, orderBy: { by: 'asc', field: 'client_name' }, search: '' })
            }
            resolve(true)
          } else if (response.data.status === 201) {
            Snackbar({ message: response.data.info, type: 'is-danger' })
          }
        })
      })
    },
    /** This method returns a list of all users for a client */
    getClientUser ({ commit }, object) {
      Vue.prototype.$http.post(`/admin/client/all/user/${object.clientId}`, object).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('CLIENT_USER_LIST', response.data.data.data)
          commit('USER_COUNT', response.data.data.count)
        }
      })
    },
    /** This method adds a new user for a client */
    addClientUser ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post('/admin/client/user/add', payload).then((response) => {
          if (response.data.status === 200 && response.data.data) {
            resolve()
            Snackbar({ message: 'Successfully created', type: 'is-success' })
          } else if (response.data.status === 201) {
            Snackbar({ message: response.data.info, type: 'is-danger' })
          } else if (response.data.status === 202) {
            Snackbar({ message: response.data.info, type: 'is-danger' })
          }
        })
      })
    },
    /** This method updates the details of a user belonging to a client */
    updateClientUser ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.put('/admin/client/user/update', payload).then((response) => {
          if (response.data.status === 200 && response.data.data) {
            resolve()
            Snackbar({ message: 'Successfully updated', type: 'is-success' })
            commit('CLIENT_SINGLE_USER', response.data.data)
          } else if (response.data.status === 500) {
            Snackbar({ message: response.data.info, type: 'is-danger' })
          }
        })
      })
    },
    /** This method updates the details of a client */
    updateClient ({ commit, state }, payload) {
      Vue.prototype.$http.put('/admin/client', payload).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully updated', type: 'is-success' })
          commit('CLIENT_DETAILS', response.data.data)
          // this.dispatch('user/getClientLogos')
        } else if (response.data.status === 201) {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        }
      })
    },
    /** This method deletes a client from the database. It also deletes all users belonging to that client(?) */
    deleteClient ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.delete(`/admin/client/${payload}`).then((response) => {
          if (response.data.status === 200 && response.data.data) {
            resolve(200)
            Snackbar({ message: 'Successfully deleted', type: 'is-success' })
          } else {
            Snackbar({ message: 'Some Error Occured.', type: 'is-danger' })
          }
        })
      })
    },
    /** This method returns the details of a single user of a client.
     * @param id {Number} - The id of the user whose information needs to be extracted
     */
    getUserDetailsByClient ({ commit }, userId) {
      Vue.prototype.$http.get(`/admin/client/user/${userId}`).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('CLIENT_SINGLE_USER', response.data.data)
        }
      })
    },
    /** This method returns the details of a single client
     * @param id {Number} - The id of the client whose details need to be extracted
     */
    getSingleClientDetails ({ commit }, clientId) {
      Vue.prototype.$http.get(`/admin/client/${clientId}`).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('CLIENT_DETAILS', response.data.data)
        }
      })
    },

    getClientReportTypes ({ commit }, clientId) {
      Vue.prototype.$http.get('/admin/reportTypes').then((response) => {
        if (response.data.status === 200 && response.data.data) {
          const clientReport = response.data.data
          commit('CLIENT_REPORTS_TYPES', clientReport)
        }
      })
    },

    updateClientAccessToReportTypes ({ commit }, reports) {
      Vue.prototype.$http.put(`/admin/reports/${reports.clientId}`, reports.data).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully updated', type: 'is-success' })
        } else {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        }
      })
    },
    /** This method update the news configuration value of a client
     * @param id {Number} - The id of the client whose details need to be updated
     */
    updateClientDetails ({ commit }, client) {
      Vue.prototype.$http.put(`/admin/client/${client.clientId}`, client.data).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully updated', type: 'is-success' })
        }
      })
    }
  },
  mutations: {
    CLIENTS (state, clients) {
      state.clients = clients
    },
    CLIENT_LIST (state, clientList) {
      state.clientList = clientList
    },
    COUNT (state, totalCount) {
      state.totalCount = totalCount
    },
    CLIENT_USER_LIST (state, clientUserList) {
      state.clientUserList = clientUserList
    },
    CLIENT_SINGLE_USER (state, clientUserDetails) {
      state.clientUserDetails = clientUserDetails.length ? clientUserDetails[0] : null
    },
    CLIENT_DETAILS (state, clientDetails) {
      state.clientDetails = clientDetails.length ? clientDetails[0] : null
    },
    AVAILABLE_REPORT (state, availableReport) {
      state.availableReport = availableReport
    },
    CLIENT_REPORTS_TYPES (state, clientReportTypes) {
      state.clientReportTypes = clientReportTypes
    },
    USER_COUNT (state, userTotalCount) {
      state.userTotalCount = userTotalCount
    }
  }
}
