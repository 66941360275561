const { default: Axios } = require('axios')

const baseUrl =
  process.env.NODE_ENV === 'development'
    ? process.env.VUE_APP_URL
    : window.location.origin
const pmDashboardUrl =
  process.env.NODE_ENV === 'development'
    ? process.env.VUE_APP_PM_DASHBOARD_URL
    : process.env.VUE_APP_PM_DASHBOARD_URL
const s3BaseUrl = process.env.VUE_APP_S3_URL
const PORT =
  process.env.NODE_ENV === 'development' ? ':' + process.env.VUE_APP_PORT : ''
const http = Axios.create({ baseURL: `${baseUrl}${PORT}/api/v1` })
const http2 = Axios.create({ baseURL: `${pmDashboardUrl}/api/v1` })
const axiosS3Client = Axios.create({ baseURL: s3BaseUrl })

module.exports = { http, http2, axiosS3Client }
