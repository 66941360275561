import Vue from 'vue'
import Snackbar from '../../components/Snackbar'

export default {
  namespaced: true,
  state: {
    categoryList: [],
    categories: [],
    categoryDetails: null,
    totalCount: null,
    categoriesOfProduct: null
  },
  actions: {
    /** This method returns a list of all categories */
    getCategoryList ({ commit }, value) {
      Vue.prototype.$http.get('/admin/category', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('CATEGORY_LIST', response.data.data)
        }
      })
    },
    /** This method returns a paginated list of categories */
    getCategoryListWithPagination ({ commit }, value) {
      Vue.prototype.$http.post('/admin/category', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('CATEGORIES', response.data.data.data)
          commit('COUNT', response.data.data.count)
        }
      })
    },
    /** This method adds a category to the database */
    addCategory ({ commit }, value) {
      Vue.prototype.$http.post('/admin/category/add', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully created', type: 'is-success' })
        } else if (response.data.status === 201) {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        }
      })
    },
    /** This method updates/edits a category value in the database */
    updateCategory ({ commit }, payload) {
      Vue.prototype.$http.put(`/admin/category/${payload.categoryId}`, payload).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully updated', type: 'is-success' })
          commit('SINGLE_CATEGORY', response.data.data)
        } else if (response.data.status === 201) {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        }
      })
    },
    /** This method gets a category by its id */
    getSingleCategory ({ commit }, categoryId) {
      Vue.prototype.$http.get(`/admin/category/${categoryId}`).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('SINGLE_CATEGORY', response.data.data)
        }
      })
    },
    /** This method gets all the categories that a product falls under */
    getCategoriesOfProduct ({ commit }, productId) {
      Vue.prototype.$http.get(`/admin/category/getCategoriesOfProduct/${productId}`).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('CATEGORIES_OF_PRODUCT', response.data.data)
        }
      })
    },
    /** This method deletes a category */
    deleteCategory ({ commit }, categoryId) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.delete(`/admin/category/${categoryId}`).then((response) => {
          if (response.data.status === 200 && response.data.data) {
            Snackbar({ message: 'Successfully Deleted', type: 'is-success' })
            resolve(response.data.status)
          } else if (response.data.status === 201) {
            Snackbar({ message: response.data.info, type: 'is-danger' })
          } else {
            Snackbar({ message: 'Some Error Occured.', type: 'is-danger' })
          }
        })
      })
    },
    /** Unsure */
    deleteCategoryVerify ({ commit }, categoryId) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.get(`/admin/category/${categoryId}/verify`).then((response) => {
          resolve(response.data.status)
        })
      })
    },
    resetCategoryList ({ commit }) {
      commit('RESET_CATEGORY')
    },
    updateCategoryList ({ commit }, payload) {
      commit('CATEGORY_LIST', payload)
    },
    resetCategories ({ commit }, payload) {
      commit('CATEGORIES', [])
      commit('COUNT', '0')
    }
  },
  mutations: {
    CATEGORIES (state, payload) {
      state.categories = payload
    },
    CATEGORY_LIST (state, payload) {
      state.categoryList = payload
      localStorage.setItem('table-category', JSON.stringify(payload))
    },
    COUNT (state, payload) {
      state.totalCount = payload
    },
    SINGLE_CATEGORY (state, payload) {
      state.categoryDetails = payload.length ? payload[0] : null
    },
    RESET_CATEGORY (state, payload) {
      state.categoryList = []
    },
    CATEGORIES_OF_PRODUCT (state, payload) {
      state.categoriesOfProduct = payload
    }
  }
}
