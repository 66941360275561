import Vue from 'vue'

const getDefaultState = () => {
  return {
    details2052a: null,
    enrichmentAnswerChoices: [],
    isLoading: false
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async get2052aDetails({ commit }, sgaId) {
      commit('SET_LOADING', true)
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/2052a?sgaId=${sgaId}`
        )
        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          commit('SET_2052A_DETAILS', response.data.data)
        } else {
          commit('RESET_2052A_DETAILS')
        }
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async update2052aDetails({ commit, dispatch }, payload) {
      commit('SET_LOADING', true)
      const { sgaId, payloadData } = payload
      try {
        const response = await Vue.prototype.$http.put(
          `/dmp/2052a?sgaId=${sgaId}`,
          payloadData
        )
        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          commit('SET_2052A_DETAILS', response.data.data)
          return response.data.status
        }
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async getEnrichmentAnswerChoices({ commit }) {
      commit('SET_LOADING', true)
      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/common/enrichment-answer-choices'
        )
        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          commit('SET_ENRICHMENT_ANSWER_CHOICES', response.data.data)
        }
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADING', false)
      }
    },
    reset2052Details({ commit }) {
      commit('RESET_2052A_DETAILS')
    }
  },
  mutations: {
    SET_2052A_DETAILS(state, details2052a) {
      state.details2052a = { ...details2052a }
    },
    RESET_2052A_DETAILS(state) {
      state.details2052a = null
    },
    SET_ENRICHMENT_ANSWER_CHOICES(state, enrichmentAnswerChoices) {
      state.enrichmentAnswerChoices = enrichmentAnswerChoices.map(
        (entityTypeObj) => {
          // Assign new key

          entityTypeObj.value = entityTypeObj.id
          entityTypeObj.text = `${entityTypeObj.id}-${entityTypeObj.name}`

          // Delete old key
          delete entityTypeObj.id
          delete entityTypeObj.name

          return entityTypeObj
        }
      )
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading
    }
  }
}
