import Vue from 'vue'
import store from '../index'
import { dateFormat } from '../../util/util'
import moment from 'moment'

const getDefaultState = () => {
  return {
    componentKey: 1,
    auditDetails: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    getAuditDetails ({ commit }, sgaId) {
      Vue.prototype.$http.get(`/dmp/audit?sgaId=${sgaId}`).then((response) => {
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_AUDIT', response.data.data)
          }
        }
      })
    }
  },
  mutations: {
    SET_AUDIT (state, audit) {
      state.auditDetails = []

      audit.forEach((auditItem) => {
        const auditTime = moment.utc(auditItem.createdAt).local()

        const attributeName = auditItem.attributeName
        let oldValue = auditItem.oldValue
        let newValue = auditItem.newValue
        const changedBy = `${auditItem.firstName || ''} ${
          auditItem.lastName || ''
        }`
        const timeStamp = auditTime.format('ddd MMM DD YYYY HH:mm:ss')
        // Check and remove prefix if attributeName is "fullAddress"
        if (attributeName === 'DOM-Full Address' || attributeName === 'REG-Full Address') {
          if (oldValue.startsWith('##CLEAR##')) {
            oldValue = oldValue.slice('##CLEAR##'.length)
          }
          if (newValue.startsWith('##CLEAR##')) {
            newValue = newValue.slice('##CLEAR##'.length)
          }
        }

        const auditData = {
          attributeName,
          oldValue,
          newValue,
          changedBy,
          timeStamp
        }

        state.auditDetails.push(auditData)
      })
    }
  }
}
