import * as DOMPurify from 'dompurify'
const moment = require('moment')
const { isEqual } = require('lodash')

export function getCleanedContent (content) {
  if (content) {
    if (DOMPurify.isSupported) {
      return DOMPurify.sanitize(content, {
        ALLOWED_TAGS: ['p'],
        FORBID_ATTR: ['style', 'align']
      })
    } else {
      return content
    }
  } else {
    return content
  }
}

export function copyToClipboard (textToCopy) {
  navigator.clipboard.writeText(textToCopy)
}

export function getModuleToNavigate (userDetails) {
  const module = [
    'voc',
    'voe',
    'reports-repository',
    'settings',
    'investment-insight',
    'tam',
    'esg-controversies',
    'esg-daas',
    'data-management-platform',
    'playbook'
  ]
  const filteredArr = userDetails.modules.filter((item) =>
    module.includes(item.module_name)
  )
  if (filteredArr[0].module_name === 'reports-repository') {
    if (
      filteredArr[0].moduleProperties &&
      filteredArr[0].moduleProperties.subTypes &&
      filteredArr[0].moduleProperties.subTypes.length > 0
    ) {
      return { name: filteredArr[0].moduleProperties.subTypes[0].routeName }
    } else {
      return '/forbidden'
    }
  } else if (
    filteredArr[0].module_name === 'settings' &&
    filteredArr[1].module_name === 'reports-repository'
  ) {
    if (
      filteredArr[1].moduleProperties &&
      filteredArr[1].moduleProperties.subTypes &&
      filteredArr[1].moduleProperties.subTypes.length > 0
    ) {
      return { name: filteredArr[1].moduleProperties.subTypes[0].routeName }
    } else {
      return '/forbidden'
    }
  } else {
    return {
      name: userDetails.modules
        .map((d) => d.module_name)
        .find((m) => m !== 'settings')
    }
  }
}

export const allowedNamePattern = /^[a-zA-Z0-9 _-]+$/

export function itemColors (index) {
  const colors = [
    '#00297A',
    '#50E6FF',
    '#00A9E0',
    '#212C72',
    '#B3282D',
    '#E8CE48',
    '#A2D56A',
    '#478B6E',
    '#BF732D'
  ]
  if (colors[index] === undefined) {
    return colors[index - colors.length] + '99'
  }
  return colors[index]
}

export function regionColors (index) {
  const colors = [
    '#018CA8',
    '#F5BE07',
    '#12b051',
    '#B55440',
    '#FE8B76',
    '#994696'
  ]
  return colors[index]
}

export function fullScreenMode (elem, type) {
  switch (type) {
    case 'on':
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen()
      }
      break
    case 'off':
      if (elem.exitFullscreen) {
        elem.exitFullscreen()
      } else if (elem.mozCancelFullScreen) {
        elem.mozCancelFullScreen()
      } else if (elem.webkitExitFullscreen) {
        elem.webkitExitFullscreen()
      } else if (elem.msExitFullscreen) {
        elem.msExitFullscreen()
      }
      break
  }
}

export function getCustomDateRange (type, months = 5, range) {
  switch (type) {
    case 'lastNMonths':
      return [
        moment()
          .subtract(months, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD')
      ]
    case 'currentMonth':
      return [
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD')
      ]
    case 'previousMonth':
      return [
        moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
      ]
    case 'currentYear':
      return [
        moment().startOf('year').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ]
    case 'previousYear':
      return [
        moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
        moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')
      ]
    case 'allTime':
      return [
        range[0],
        minDate(moment().endOf('month').format('YYYY-MM-DD'), range[1])
      ]
    case 'Today':
      return [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    case 'currentWeek':
      return [
        moment().startOf('isoWeek').format('YYYY-MM-DD'),
        moment().endOf('isoWeek').format('YYYY-MM-DD')
      ]
    case 'previousWeek':
      return [
        moment().subtract(1, 'isoWeek').startOf('isoWeek').format('YYYY-MM-DD'),
        moment().subtract(1, 'isoWeek').endOf('isoWeek').format('YYYY-MM-DD')
      ]
  }
}
export function getEsgCustomDateRange (type, months = 5, range) {
  switch (type) {
    case 'lastNMonths':
      return [
        moment()
          .subtract(months, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ]
    case 'currentMonth':
      return [
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ]
    case 'previousMonth':
      return [
        moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
      ]
    case 'currentYear':
      return [
        moment().startOf('year').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ]
    case 'previousYear':
      return [
        moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
        moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')
      ]
    case 'allTime':
      return [
        range[0],
        minDate(moment().endOf('month').format('YYYY-MM-DD'), range[1])
      ]
    case 'Today':
      return [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    case 'currentWeek':
      return [
        moment().startOf('isoWeek').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ]
    case 'previousWeek':
      return [
        moment().subtract(1, 'isoWeek').startOf('isoWeek').format('YYYY-MM-DD'),
        moment().subtract(1, 'isoWeek').endOf('isoWeek').format('YYYY-MM-DD')
      ]
  }
}

function minDate (d1, d2) {
  if (moment(d1).isBefore(moment(d2))) {
    return d1
  } else {
    return d2
  }
}

// converting date into format DDMMYYYY | hh:mm A
export function dateFormat (date) {
  const inputData = moment.utc(date) // Use moment.utc to avoid time zone conversion
  const formattedDate = inputData.format('DDMMYYYY')
  const formattedTime = inputData.format('hh:mm A')
  return `${formattedDate} | ${formattedTime}`
}

export const workTypePriority = {
  'Corporate Action': 1,
  'New Onboarding': 2,
  'Data Enrichment': 3,
  'Periodic Review': 4
}

export const adminPanelList = [
  {
    path: 'dashboard',
    name: '/admin/dashboard',
    alias: 'Dashboard',
    subMenu: false
  },
  {
    path: 'client',
    name: '/admin/client',
    alias: 'Organizations',
    subMenu: false
  },
  {
    path: 'company',
    name: '/admin/company',
    alias: 'Companies',
    subMenu: false
  },
  {
    path: 'category',
    name: '/admin/category',
    alias: 'Categories',
    subMenu: false
  },
  {
    path: 'product',
    name: '/admin/product',
    alias: 'Products',
    subMenu: false
  },
  {
    path: 'source',
    name: '/admin/source',
    alias: 'Data Sources',
    subMenu: false
  },
  { path: 'topic', name: '/admin/topic', alias: 'Topics', subMenu: false },
  {
    path: 'company_size',
    name: '/admin/company_size',
    alias: 'Company Size',
    subMenu: false
  },
  {
    path: 'industry',
    name: '/admin/industry',
    alias: 'Industries',
    subMenu: false
  },
  { path: 'job', name: '/admin/job', alias: 'Jobs', subMenu: false },
  {
    path: 'feature',
    name: '/admin/feature',
    alias: 'Features',
    subMenu: false
  },
  {
    path: 'roles-and-permissions',
    name: '/admin/roles-and-permissions',
    alias: 'Roles & Permissions',
    subMenu: false
  },
  {
    path: 'news-rss-feeds',
    name: '/admin/news-rss-feeds',
    alias: 'News Rss Feeds',
    subMenu: true
  },
  {
    path: 'news-categories',
    name: '/admin/news-categories',
    alias: 'News Categories',
    subMenu: true
  }
]

export const entityPoolPriority = {
  HIGH: 'high',
  LOW: 'low',
  MEDIUM: 'medium'
}
export const userPanelList = [
  { path: 'people', alias: 'People' },
  { path: 'security-and-permissions', alias: 'Security & Permissions' },
  { path: 'profile', alias: 'My Profile' }
]

export function debounce (func, wait, immediate) {
  let timeout, args, context, timestamp, result
  if (wait == null) wait = 100

  function later () {
    const last = Date.now() - timestamp

    if (last < wait && last >= 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      if (!immediate) {
        result = func.apply(context, args)
        context = args = null
      }
    }
  }

  const debounced = function () {
    context = this
    args = arguments
    timestamp = Date.now()
    const callNow = immediate && !timeout
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }

  debounced.clear = function () {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
  }

  debounced.flush = function () {
    if (timeout) {
      result = func.apply(context, args)
      context = args = null

      clearTimeout(timeout)
      timeout = null
    }
  }

  return debounced
}

export function clearStorage (reset) {
  const cookie = JSON.parse(window.localStorage.getItem('cookies'))
  const defaultCategory = window.localStorage.getItem('DEFAULT_CATEGORY')
  const isModalValue = window.localStorage.getItem('isModalActive')

  localStorage.clear()
  sessionStorage.clear()
  window.localStorage.setItem('DEFAULT_CATEGORY', defaultCategory)
  window.localStorage.setItem('cookies', cookie)
  window.localStorage.setItem('isModalActive', isModalValue)
  if (reset) {
    window.history.go(0)
  }
}

export const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,100}$/

export const validateDomain =
  /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/

export function downloadCSV (csv, filename) {
  // CSV file
  const csvFile = new Blob([csv], { type: 'text/csv' })

  // Download link
  const downloadLink = document.createElement('a')

  // File name
  downloadLink.download = filename

  // Create a link to the file
  downloadLink.href = window.URL.createObjectURL(csvFile)

  // Hide download link
  downloadLink.style.display = 'none'

  // Add the link to DOM
  document.body.appendChild(downloadLink)

  // Click download link
  downloadLink.click()
}

export function exportTableToCSV (table, fileName) {
  const csv = []
  const rows = table.querySelectorAll('table tr')
  const titleName = table
    .querySelectorAll('table')[0]
    .getAttribute('title-data')
  fileName =
    table.querySelectorAll('table')[0].getAttribute('title-data') +
    moment().format(' DD-MM-YYYY HH:mm') +
    '.csv'
  // titleName condition added because last column not showing when we download csv for page news rss and new category.
  for (let i = 0; i < rows.length; i++) {
    const row = []
    const cols = rows[i].querySelectorAll('td, th')
    if (titleName === 'newsRssFeed' || titleName === 'newsCategories') {
      for (let j = 0; j < cols.length; j++) {
        row.push(cols[j].innerText)
      }
    } else {
      for (let j = 0; j < cols.length - 1; j++) {
        row.push(cols[j].innerText)
      }
    }

    csv.push(row.join(';'))
  }

  // Download CSV file
  downloadCSV('sep=;\n' + csv.join('\n'), fileName)
}

export function numberFormatter (num, digits = 0) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

/** Helper function to check if an object exists in some target array
 * @param item {Object} - The object that is being searched for in the list
 * @param target {Array} - The list/array of objects in which we search for the object *item*
 * @public
 */
export function exists (item, target) {
  return target.some((obj) => {
    return isEqual(obj, item)
  })
}

/**
 * Checks if a string contains only printable ASCII characters, including English letters, digits, and punctuation.
 * @param {string} input The string to check.
 * @returns {boolean} True if the string contains only printable ASCII characters, false otherwise.
 */
export function isValidEnglishASCII (input) {
  // eslint-disable-next-line no-control-regex
  const regex = /^[\s\x00-\x84\u201A\u201E\u20AC\u0192]+$/ // ascii range 0-132
  return regex.test(input)
}
