import Vue from 'vue'
import Snackbar from '../../components/Snackbar'

export default {
  namespaced: true,
  state: {
    dataSourceList: [],
    dataSources: [],
    totalCount: null,
    dataSourceDetails: null
  },
  actions: {
    /** This method returns a list of all data sources present in the database and available to a product. This method is being called at the 'Edit Product' page. */
    getDataSourceList ({ commit }, moduleId) {
      Vue.prototype.$http.get(`/admin/source/${moduleId}`).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('DATA_SOURCE_LIST', response.data.data)
        }
      })
    },
    /** This method also returns a list of data sources available to a product. This method is being used on the Data sources tab in the admin panel */
    getDataSources ({ commit }, value) {
      Vue.prototype.$http.post('/admin/source', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('DATA_SOURCES', response.data.data.data)
          commit('COUNT', response.data.data.count)
        }
      })
    },
    /** This method adds a new data source to the database */
    addDataSource ({ commit }, value) {
      Vue.prototype.$http.post('/admin/source/add', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully created', type: 'is-success' })
        } else if (response.data.status === 500) {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        }
      })
    },
    /** This method updates an existing data source */
    updateDataSource ({ commit }, payload) {
      Vue.prototype.$http.put(`/admin/source/${payload.dataSourceId}`, payload).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully updated', type: 'is-success' })
          commit('SINGLE_DATA_SOURCE', response.data.data)
        } else if (response.data.status === 500) {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        }
      })
    },
    /** This method fetches the data for a single data source from the database */
    getSingleDataSource ({ commit }, sourceId) {
      Vue.prototype.$http.post(`/admin/source/${sourceId}`).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('SINGLE_DATA_SOURCE', response.data.data)
        }
      })
    }
  },
  mutations: {
    DATA_SOURCES (state, payload) {
      state.dataSources = payload
    },
    DATA_SOURCE_LIST (state, payload) {
      state.dataSourceList = payload
    },
    COUNT (state, payload) {
      state.totalCount = payload
    },
    SINGLE_DATA_SOURCE (state, payload) {
      state.dataSourceDetails = payload.length ? payload[0] : null
    }
  }
}
