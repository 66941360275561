import Vue from 'vue'
import Snackbar from '../../components/Snackbar'

export default {
  namespaced: true,
  state: {
    productList: [],
    productDetail: null,
    productListByCategory: [],
    productListWithCategory: [],
    totalCount: null,
    productLinkMeta: []
  },
  actions: {
    /** This method returns a list of products from the database */
    getProductList ({ commit }) {
      Vue.prototype.$http.get('/admin/product').then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('PRODUCT_LIST', response.data.data)
        }
      })
    },
    /** This method returns a list of objects, which contains the product info as well as a list of all categories (user defined or standard) that the product comes under  */
    getProductListWithCategory ({ commit }, value) {
      Vue.prototype.$http.post('/admin/product/category', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('PRODUCT_LIST_WITH_CATEGORY', response.data.data.data)
          commit('COUNT', response.data.data.count)
        }
      })
    },
    /** This method adds a new product to the database */
    addProduct ({ commit }, value) {
      Vue.prototype.$http.post('/admin/product/add', value).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully created', type: 'is-success' })
        } else if (response.data.status === 201) {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        }
      })
    },
    /** This method fetches the data of a single product (name, id etc) */
    getSingleProduct ({ commit }, productId) {
      Vue.prototype.$http.get(`/admin/product/${productId}`).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('PRODUCT_DETAILS', response.data.data.productDetails)
          commit('PRODUCT_LINK_META', response.data.data.productLinkMeta)
        }
      })
    },
    /** This method updates the data of an existing product */
    updateProduct ({ commit, state }, payload) {
      Vue.prototype.$http.put(`/admin/product/${payload.productId}`, payload).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          Snackbar({ message: 'Successfully updated', type: 'is-success' })
          // commit('PRODUCT_DETAILS', response.data.data)
          this.dispatch('products/getSingleProduct', payload.productId)
        } else if (response.data.status === 201) {
          Snackbar({ message: response.data.info, type: 'is-danger' })
        } else {
          const message = response.data.data.info === 'error: duplicate key value violates unique constraint "uq_url"' ? 'Unique_key constraint on URL violated' : 'Something went wrong'
          Snackbar({ message: message, type: 'is-danger' })
        }
      })
    },
    /** This method returns a list of products belonging to a certain category
     * @param id - The id of the category
     */
    getProductsByCategoryId  ({ commit }, categoryIds) {
      Vue.prototype.$http.post('/admin/product/categoryId', { categoryIds }).then((response) => {
        if (response.data.status === 200 && response.data.data) {
          commit('PRODUCT_BY_CATEGORY_LIST', response.data.data)
        }
      })
    },
    /** This method deletes a product from the database
     * @param id - The id of the product to be deleted
     */
    deleteProduct ({ commit }, productId) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.delete(`/admin/product/${productId}`).then((response) => {
          if (response.data.status === 200 && response.data.data) {
            Snackbar({ message: 'Successfully Deleted', type: 'is-success' })
            resolve(response.data.status)
          } else if (response.data.status === 201) {
            Snackbar({ message: response.data.info, type: 'is-danger' })
          } else {
            Snackbar({ message: 'Some Error Occured.', type: 'is-danger' })
          }
        })
      })
    },
    /** Unsure */
    deleteProductVerify ({ commit }, productId) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.get(`/admin/product/${productId}/verify`).then((response) => {
          resolve(response.data.status)
        })
      })
    },
    resetProducts ({ commit }, payload) {
      commit('PRODUCT_LIST_WITH_CATEGORY', [])
      commit('COUNT', '0')
    },
    resetProductsByCategories ({ commit }, payload) {
      commit('PRODUCT_BY_CATEGORY_LIST', [])
    },
    verifyLinkChangesImpact ({ commit }, productLinkMeta) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post('/admin/product/plmChangeImpact', productLinkMeta).then((response) => {
          resolve(response.data.status)
        })
      })
    }
  },
  mutations: {
    PRODUCT_LIST (state, payload) {
      state.productList = payload
    },
    PRODUCT_DETAILS (state, payload) {
      state.productDetail = payload.length ? payload[0] : null
    },
    PRODUCT_BY_CATEGORY_LIST (state, payload) {
      state.productListByCategory = payload
    },
    PRODUCT_LIST_WITH_CATEGORY (state, payload) {
      state.productListWithCategory = payload
    },
    COUNT (state, payload) {
      state.totalCount = payload
    },
    PRODUCT_LINK_META (state, payload) {
      state.productLinkMeta = payload
      // console.log(state.productLinkMeta)
    }
  }
}
