import { vm } from '../main'
import Vue from 'vue'
import Snackbar from '../components/Snackbar'
import store from '../store/index'
import { clearStorage } from '../util/util'

const getDefaultUserState = () => {
  return {
    userDetails: {},
    client_logos: {},
    verifiedClient: 0,
    validClientSlug: ''
  }
}

export default {
  namespaced: true,
  state: getDefaultUserState(),
  actions: {
    /** This method resets the state for this store module */
    resetUserState({ commit }, value) {
      commit('RESET_USER_STATE')
    },
    /** This method saves details of the logged in user in the store */
    setUserDetails({ commit }, value) {
      commit('USER_DETAILS', value)
    },
    /** This method updates the details of the user if all information entered in the User profile form is correct, otherwise it returns a message (through Snackbar) */
    updateUserDetails({ commit }, value) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.put('/user', value).then((data) => {
          if (data.data.status === 200) {
            if (data.data.token) {
              window.localStorage.setItem('access-token', 'Bearer ' + data.data.token)
            }
            Snackbar({ message: 'Successfully updated.', type: 'is-success' })
            if (data && data.data) {
              commit('UPDATE_PARTIAL_USER', data.data.data)
              resolve(data.data)
            }
          } else if (data.data.status === 306) {
            Snackbar({
              message: 'Current Password is incorrect.',
              type: 'is-danger'
            })
          } else if (data.data.status === 305) {
            Snackbar({
              message: 'New password cannot be same as existing password.',
              type: 'is-danger'
            })
          } else if (data.data.status === 400) {
            Snackbar({
              message: 'Bad input.',
              type: 'is-danger'
            })
          } else if (data.data.status === 500) {
            Snackbar({
              message: 'Something went wrong.',
              type: 'is-danger'
            })
          }
        })
      })
    },
    /** This method fetches user details from the database */
    getUserDetails({ commit }, value) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.get('/user').then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('USER_DETAILS', response.data.data)
              resolve(response.data.data)
            }
          }
        })
      })
    },
    /** This method fetches the links to the client uploaded logos and saves them to store */
    getClientLogos({ commit }, value) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/client/${window.location.host.split('.')[0]}/logo`)
          .then((response) => {
            if (response.status === 200) {
              if (response && response.data && response.data.data) {
                resolve(response.data.data)
                commit('CLIENT_LOGOS', response.data.data.client_logo)
              }
            }
          })
      })
    },
    /** Unsure */
    updateSamlUser({ commit }, value) {
      Vue.prototype.$http.post('/user/saml', value).then((res) => {
        if (res.status === 200) {
          if (res.data.status === 403) {
            clearStorage(false)
            vm.$router.replace('/')
            setTimeout(() => {
              Snackbar({
                message:
                  'Your subscription has expired. Please contact the Administrator.',
                type: 'is-danger'
              })
            }, 1000)
          } else {
            window.localStorage.setItem(
              'access-token',
              'Bearer ' + res.data.data.token
            )
            window.localStorage.setItem(
              'refresh-token',
              res.data.data.refreshToken
            )
            localStorage.setItem(
              'user',
              JSON.stringify(res.data.data.userObject)
            )
            if (
              res.data.data.userObject &&
              res.data.data.userObject.preferences
            ) {
              commit(
                'UPDATE_USER_PREFERENCE',
                res.data.data.userObject.preferences.preference_mode
              )
            } else {
              commit('UPDATE_USER_PREFERENCE', 'light')
            }
            Snackbar({
              message: 'Profile updated successfully.',
              type: 'is-success'
            })
            vm.$router.replace(
              res.data.data.userObject.modules.find(
                (m) => m.module_name !== 'settings'
              ).module_name
            )
          }
        }
      })
    },
    /** Unsure */
    getSSO({ commit }, slug) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.get(`/client/sso/${slug}`).then((response) => {
          if (response.data.status === 200) {
            if (response && response.data && response.data.data) {
              resolve(response.data.data)
            }
          } else {
            resolve({})
          }
        })
      })
    }
  },
  mutations: {
    RESET_USER_STATE(state, payload) {
      state = {}
    },
    UPDATE_USER_PREFERENCE(state, payload) {
      store.state.common.user_preference_mode = payload
    },
    UPDATE_PARTIAL_USER(state, payload) {
      const { firstName, lastName } = payload[0]
        ? payload[0]
        : state.userDetails
      state.userDetails.firstName = firstName
      state.userDetails.lastName = lastName
    },
    USER_DETAILS(state, payload) {
      state.userDetails = payload
    },
    CLIENT_LOGOS(state, payload) {
      state.client_logos = payload
    },

    VERIFIED_CLIENT(state, payload) {
      state.verifiedClient = payload
    },
    VALID_CLIENT_SLUG(state, payload) {
      state.validClientSlug = payload
    }
  }
}
